import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { exportPassportData, getPassportTableData } from "../../../../store/slices/settings/passportDataSlice";
import { LoadingState } from "../../../../types/loadingState";
import { Box } from "@mui/material";
import { SettingsButton } from "../../components/shared/settingsButton/settingsButton";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export const PassportData = () => {
  const dispatch = useDispatch<any>();

  const data = useTypedSelector((state) => state.passportData.data);
  const isLoading = useTypedSelector((state) => state.passportData.isLoading);
  const exportProcessing = useTypedSelector((state) => state.passportData.exportProcessing);

  const tableColumns: BaseTableColumnDef[] = [
    {
      headerName: "Номер счетчика",
      field: "counterNumber",
    },
    {
      headerName: "Наименование",
      field: "name",
    },
    {
      headerName: "Тип",
      field: "type",
    },
    {
      headerName: "Год ввода в эксплуатацию",
      field: "year",
    },
  ];

  const handleExportCsv = () => {
    dispatch(exportPassportData());
  };

  useEffect(() => {
    dispatch(getPassportTableData());
  }, []);

  return (
    <Box sx={styles.contentContainer}>
      <Box sx={styles.toolBox}>
        <SettingsButton
          variant="outlined"
          size="large"
          onClick={handleExportCsv}
          endIcon={<UploadFileIcon />}
          disabled={isLoading === LoadingState.PENDING || exportProcessing === LoadingState.PENDING}
          loading={exportProcessing === LoadingState.PENDING}
        >
          Экспорт CSV
        </SettingsButton>
      </Box>

      <BaseTable
        editable={false}
        columns={tableColumns}
        data={data}
        isLoading={isLoading === LoadingState.PENDING}
        useClientPagination
      />
    </Box>
  );
};

const styles = {
  contentContainer: {
    paddingTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  toolBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
};
