import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { ObjectInfoDeviceEventsTable } from '../../components/objectInfoDeviceEventsTable/objectInfoDeviceEventsTable';
import { ObjectInfoDeviceEventsDetailTable } from '../../components/objectInfoDeviceEventsDetailTable/objectInfoDeviceEventsDetailTable';
import { ObjectInfoDeviceImage } from '../../components/objectInfoDeviceImage/objectInfoDeviceImage';
import { useDispatch } from 'react-redux';
import { getDeviceImage, getObjectImage } from '../../../../store/slices/objects/objectInfoSlice';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';

export const OperativeInformation = () => {
  const dispatch = useDispatch<any>();

  const selectedObjectGuid = useTypedSelector((state) => state.objectInfo.selectedObjectGuid);
  const image = useTypedSelector((state) => state.objectInfo.image);
  const isImageLoading = useTypedSelector((state) => state.objectInfo.isDeviceImageLoading);

  const handleDeviceSelect = (guid: string) => {
    dispatch(getDeviceImage({ guid }));
  };

  useEffect(() => {
    selectedObjectGuid && dispatch(getObjectImage({ guid: selectedObjectGuid }));
  }, [selectedObjectGuid]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.row}>
        <Box sx={[styles.cell, styles.deviceEventsContainer]}>
          <ObjectInfoDeviceEventsTable onDeviceSelect={handleDeviceSelect} />
        </Box>
        <Box sx={[styles.cell, styles.imageCell]}>
          <ObjectInfoDeviceImage image={image} isLoading={isImageLoading} />
        </Box>
      </Box>
      <Box sx={styles.row}>
        <Box sx={styles.cell}>
          <ObjectInfoDeviceEventsDetailTable />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    flex: '0 1 100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  row: {
    flex: '0 1 50%',
    display: 'flex',
    gap: '10px',
  },
  cell: {
    display: 'flex',
    flex: '0 1 100%',
  },
  imageCell: {
    height: '450px',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      height: '100%',
    },
  },
  deviceEventsContainer: {
    flex: '0 1 40%',
  },
};
