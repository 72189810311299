import { Box } from "@mui/material";
import { LoadingState } from "../../../../types/loadingState";
import Loader from "../../../../components/uiComponents/Animation/Animation";

interface Props {
  image: Blob | null;
  isLoading: LoadingState;
  imageName?: string;
}

export const ObjectInfoDeviceImage = ({image, isLoading, imageName} : Props) => {
  const imageUrl = image !== null ? URL.createObjectURL(image) : "";

  if (isLoading === LoadingState.PENDING) {
    return <Loader />;
  }

  if (imageUrl === "") {
    return <Box>Нет изображения</Box>;
  }

  return <img src={imageUrl} alt={imageName} />;
};
