import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { GetLoss, GetZoneLossesTable, zoneLossTable } from "../../BalansApis";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ApexLocales } from "../../../../constants/ApexChartsSettings";

interface LossInterface {
  // zoneNumber: string;
}

const Loss: React.FC<LossInterface> = () => {
  const [textTable, setTextTable] = useState("%");
  const columns: GridColDef[] = [
    {
      field: "bg",
      headerName: "Балансовая группа",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "lossNow",
      headerName: `Потери в 2023, ${textTable}`,
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "lossPrev",
      headerName: `Потери в 2022,  ${textTable}`,
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "predLossNow",
      headerName: `Прогнозные потери в 2023, ${textTable}`,
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "a",
      headerName: "",
      flex: 1,
      minWidth: 150,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link style={{ display: "flex", alignItems: "center", cursor: "pointer" }} to={`${params.id}`}>
            ПОДРОБНО
          </Link>
        );
      },
    },
  ];

  const buttonSensor = ["buttonYear", "buttonQuarter", "buttonMonth"];
  const [period, setPeriod] = useState("year");
  const [isLoadingBar, setIsLoadingBar] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [switchUnit, setSwitchUnit] = useState(true);
  const { zoneNumber } = useTypedSelector((state) => state.zone);
  const [barOpt, setBarOpt] = useState<ApexOptions>({
    series: [
      {
        name: "Факт 2023",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 11, 12],
        color: "#1478BE",
      },
      {
        name: "Факт 2022",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 11, 12],
        color: "#1599D7",
      },
      {
        name: "Прогноз 2023",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 11, 12],
        color: "#A7B6CD",
      },
      {
        name: "План 2023",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 10, 11, 12],
        color: "#4BB8A9",
      },
    ],
    chart: {
      type: "bar",
      width: "100%",
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      colors: ["#1599D7"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 2,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ["Янв", "Фев", "Мар", "Aпр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    },
    yaxis: {
      title: {
        text: `%`,
      },
    },
  });

  const [lossesBGTable, setLossesBGTable] = useState<zoneLossTable[]>([]);

  // Функция смены класса кнопки периода
  function buttonClassChange(id: string) {
    // Находим кнопку по id
    var btn = document.getElementById(id);
    // Была ли кнопка найдена
    if (btn !== null) {
      btn.classList.add("timePeriodButtonActive");
    }
    if (btn !== null || id === "undefined") {
      buttonSensor.forEach((button) => {
        if (button !== id) {
          var btn1 = document.getElementById(button);
          if (btn1 !== null) {
            btn1.classList.remove("timePeriodButtonActive");
          }
        }
      });
    }
  }

  function setTimePeriod(type: string) {
    let per = "";
    setIsLoadingBar(false);
    switch (type) {
      case "year":
        per = "year";
        break;

      case "quarter":
        per = "quarter";
        break;

      case "month":
        per = "month";
        break;

      default:
        per = "year";
    }
    setPeriod(per);
    getLosses(per, switchUnit);
    getLossesTable(per, switchUnit);
  }

  async function getLosses(period: string, percent: boolean) {
    setIsLoadingBar(true);
    try {
      const response = await GetLoss(period, zoneNumber, percent);
      // console.log(response.serias)
      if (percent === true)
        setBarOpt({
          ...barOpt,
          series: response.series,
          xaxis: { categories: response.categories },
          yaxis: { title: { text: "%" } },
        });
      else
        setBarOpt({
          ...barOpt,
          series: response.series,
          xaxis: { categories: response.categories },
          yaxis: { title: { text: "тыс. м. куб" } },
        });
      setIsLoadingBar(false);

      // console.log(response);
    } catch (e) {
      console.log(e);
    }
  }

  async function getLossesTable(period: string, percent: boolean) {
    setIsLoadingTable(true);
    if (percent === true) setTextTable("%");
    else setTextTable("тыс. м. куб");
    try {
      const response = await GetZoneLossesTable(period, zoneNumber, percent);
      setLossesBGTable(response);
      setIsLoadingTable(false);
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    buttonClassChange("buttonYear");
  }, []);

  useEffect(() => {
    getLosses(period, switchUnit);
    getLossesTable(period, switchUnit);
  }, [zoneNumber]);

  return (
    <div className="balanceTabDefault">
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          Потери внутри зоны
        </div>
        <FormControlLabel
          control={
            <Switch
              defaultValue={"false"}
              onClick={() => {
                if (switchUnit === false) {
                  setSwitchUnit(true);
                  getLosses(period, true);
                  getLossesTable(period, true);
                } else {
                  setSwitchUnit(false);
                  getLosses(period, false);
                  getLossesTable(period, false);
                }
              }}
            />
          }
          label={
            <div className="usualText" style={{ whiteSpace: "nowrap" }}>
              Абсолютная величина
            </div>
          }
        />
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonYear"
          onClick={() => {
            buttonClassChange("buttonYear");
            setTimePeriod("year");
          }}
        >
          Годы
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonQuarter"
          onClick={() => {
            buttonClassChange("buttonQuarter");
            setTimePeriod("quarter");
          }}
        >
          Кварталы
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonMonth"
          onClick={() => {
            buttonClassChange("buttonMonth");
            setTimePeriod("month");
          }}
        >
          Месяцы
        </CustomButton>
      </div>
      <div className="lowerChart" style={{ height: "300px", width: "100%" }}>
        {isLoadingBar ? (
          <Animation></Animation>
        ) : (
          <Chart height={"100%"} width={"100%"} options={barOpt} series={barOpt.series} type="bar" />
        )}
      </div>
      {isLoadingTable ? (
        <Animation></Animation>
      ) : (
        <Box sx={{ width: "100%" }} className="lossesTable tableHeight">
          <DataGrid
            rows={lossesBGTable}
            columns={columns}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
      )}
    </div>
  );
};

export default Loss;
