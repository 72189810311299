import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoadingState } from '../../../types/loadingState';
import { RootState } from '../../reducers';
import { objectInfoApi } from '../../../pages/objectInfo/api/objectInfoApi';
import { NormTableItemDto, NormTableItemExtendedDto } from '../../../models/objects/normTableItemDto';
import { UpdateNormItemDto } from '../../../models/objects/norms/updateNormItemDto';

const sliceName = 'norms';

interface InitialState {
  data: NormTableItemExtendedDto[];
  totalItems: number;
  itemToDeleteId?: string;

  currentPage: number;
  pageSize: number;

  showDeleteModal: boolean;

  isLoading: LoadingState;
}

const initialState: InitialState = {
  data: [],
  totalItems: 0,

  currentPage: 0,
  pageSize: 25,

  showDeleteModal: false,

  isLoading: LoadingState.EMPTY,
};

export const getNorms = createAsyncThunk<{ totalItems: number; norms: NormTableItemDto[] }, { guid: string; from: number; size: number }, { state: RootState }>(
  `${sliceName}/getNorms`,
  async (params, thunkAPI) => {
    try {
      const { guid, from, size } = params;
      const response = await objectInfoApi.getNorms(guid, from, size);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue('Ошибка соединения. Повторите попытку');
    }
  },
);

export const updateNormItem = createAsyncThunk<null, UpdateNormItemDto, { state: RootState }>(`${sliceName}/updateNormItem`, async (params, thunkAPI) => {
  try {
    const response = await objectInfoApi.updateNormItem(params.signalEventGuid, params);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Ошибка соединения. Повторите попытку');
  }
});

export const deleteNormItem = createAsyncThunk<null, string, { state: RootState }>(`${sliceName}/deleteNormItem`, async (guid, thunkAPI) => {
  try {
    const response = await objectInfoApi.deleteNormItem(guid);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Ошибка соединения. Повторите попытку');
  }
});

const normsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setShowDeleteModal: (state, action) => {
      state.showDeleteModal = action.payload;
    },
    setItemToDelete: (state, action) => {
      state.itemToDeleteId = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNorms.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getNorms.fulfilled, (state, action) => {
      state.data = action.payload.norms.map((x) => ({ ...x, value: { lowNormValue: x.lowNormValue, highNormValue: x.highNormValue } }));
      state.totalItems = action.payload.totalItems;
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getNorms.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const normsReducer = normsSlice.reducer;
export const { setInitialState, setShowDeleteModal, setItemToDelete, setCurrentPage, setPageSize } = normsSlice.actions;
