export const ApexLocales = {
  defaultLocale: "ru",
  locales: [
    {
      name: "ru",
      options: {
        months: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
        shortMonths: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
        days: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
        shortDays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вскр"],
        toolbar: {
          exportToSVG: "Скачать в формате SVG",
          exportToPNG: "Скачать в формате PNG",
          exportToCSV: "Скачать в формате CSV",
          menu: "Меню",
        //   selection: "Selection",
          selectionZoom: "Масштабирование выделения",
          zoomIn: "Приблизить",
          zoomOut: "Отдалить",
          pan: "Перемещение",
          reset: "Сбросить зум",
        },
      },
    },
  ],
};
