import ErrorIcon from "@mui/icons-material/Error";
import ReportIcon from "@mui/icons-material/Report";
import WarningIcon from "@mui/icons-material/Warning";
import { EventType } from "../../models/events/eventType";

type EventIconsByType = {
  [key in EventType]: React.ReactNode;
};

export const eventCardIcons: EventIconsByType = {
  critical: <ReportIcon sx={{ color: "#CE0725" }} />,
  excess: <WarningIcon sx={{ color: "#F2CF08" }} />,
  service: <ErrorIcon sx={{ color: "#0757CE" }} />,
};
