import { Navigate } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { routeUrls } from "./routeUrls";

export const HomeRoute = () => {
  const userInfo = useTypedSelector((state) => state.account.userInfo);
  const accessList = userInfo?.accessList || [];

  if (!userInfo) return <></>;

  if (accessList.includes(routeUrls.balance)) {
    return <Navigate to={routeUrls.balance} />;
  } else {
    if (accessList.length === 0) {
      return <Navigate to={routeUrls.dataAnalysis} />;
    } else {
      return <Navigate to={accessList[0]} />;
    }
  }
};
