import { Box, Typography } from "@mui/material";
import { UserManagementTabsConfig } from "./userManagementTabsConfig";
import { Outlet } from "react-router-dom";
import { TabsWithRoutes } from "../../components/tabsWithRoutes/tabsWithRoutes";

export const UserManagement = () => {
  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box sx={styles.page}>
        <Box sx={styles.titleContainer}>
          <Typography variant="h6">Управление пользователями</Typography>
        </Box>
        <Box sx={styles.tabWrapper}>
          <TabsWithRoutes config={UserManagementTabsConfig} />

          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  page: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    flex: "1 0 100%",
  },
  titleContainer: {
    padding: "20px 20px 0 20px",
  },
  tabWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
    flex: "1 1 100%",
    gap: "10px",
    ".MuiTab-root": {
      maxWidth: "100%",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "140%",
      textTransform: "uppercase",
    },
  },
};
