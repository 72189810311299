import { Box } from "@mui/material";
import { GridCellParams, GridValueFormatterParams } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";
import { DeviceEventDetailDTO } from "../../../../models/deviceEventsDetail/deviceEventDetailDTO";
import { DeviceEventsDetailTypes } from "../../../../models/deviceEventsDetail/deviceEventsDetailTypes";
import {
  getObjectInfoDeviceEventsDetail,
  setDeviceEventsDetailInitialState,
} from "../../../../store/slices/objects/objectInfoSlice";
import { LoadingState } from "../../../../types/loadingState";
import { eventTypeCellRenderer } from "../../../../widgets/eventsTable/eventTypeCellRenderer/eventTypeCellRenderer";

export const ObjectInfoDeviceEventsDetailTable = () => {
  const dispatch = useDispatch<any>();

  const deviceEventsDetail = useTypedSelector((state) => state.objectInfo.deviceEventsDetails);
  const selectedObjectGuid = useTypedSelector((state) => state.objectInfo.selectedObjectGuid);
  const isLoading = useTypedSelector((state) => state.objectInfo.isDeviceEventsDetailLoading);

  const columns: BaseTableColumnDef[] = [
    {
      headerName: "Инцидент",
      field: "type",
      renderCell: eventTypeCellRenderer,
    },
    {
      headerName: "Наименование датчика",
      field: "deviceName",
    },
    {
      headerName: "Сигнал",
      field: "indicationName",
    },
    {
      headerName: "Дата и время",
      field: "dateTime",
      type: "dateTime",
    },
    {
      headerName: "Показание",
      field: "value",
    },
    {
      headerName: "Норма",
      field: "normValue",
    },
    {
      headerName: "Отклонение",
      field: "variance",
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(setDeviceEventsDetailInitialState());
    };
  }, []);

  useEffect(() => {
    if (selectedObjectGuid) {
      dispatch(getObjectInfoDeviceEventsDetail({ guid: selectedObjectGuid }));
    }
  }, [selectedObjectGuid])

  return (
    <Box sx={styles.tableWrapper}>
      <BaseTable
        columns={columns}
        data={deviceEventsDetail}
        pageSize={5}
        pageSizeOptions={[5]}
        isLoading={isLoading === LoadingState.PENDING}
        useClientPagination
      />
    </Box>
  );
};

const styles = {
  tableWrapper: {
    width: "100%",
    height: "100%",
  },
};
