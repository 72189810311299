import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Loader from '../../../../components/uiComponents/Animation/Animation';
import { DeviceEventDTO } from '../../../../models/deviceEvents/deviceEventDTO';

interface DeviceEventsTableProps {
  data: DeviceEventDTO[];
  isLoading?: boolean;
  onRowSelect?: (deviceGuid: string) => void;
}

export const DeviceEventsTable = ({ data, isLoading = false, onRowSelect }: DeviceEventsTableProps) => {
  const isDataEmpty = !data.length;

  const handleCellClick = (row: DeviceEventDTO) => {
    onRowSelect && onRowSelect(row.deviceId);
  };

  if (isLoading) {
    return (
      <Box sx={{ width: '100%' }}>
        <Loader />
      </Box>
    );
  }

  return (
    <TableContainer sx={[styles.tableContainer, isDataEmpty && { height: '100%' }]}>
      <Table stickyHeader sx={[styles.table, isDataEmpty && { height: '100%' }]}>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={[styles.cell, styles.headerCell, styles.borderRight]}>
              Датчик
            </TableCell>
            <TableCell align="center" sx={[styles.cell, styles.headerCell, styles.borderRight]}>
              Показание
            </TableCell>
            <TableCell align="center" sx={[styles.cell, styles.headerCell, styles.rectangleCell]}>
              Сервисные сигналы
            </TableCell>
            <TableCell align="center" sx={[styles.cell, styles.headerCell, styles.rectangleCell]}>
              Превышения
            </TableCell>
            <TableCell align="center" sx={[styles.cell, styles.headerCell, styles.rectangleCell]}>
              Критические события
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={styles.tableBody}>
          {isDataEmpty ? (
            <TableRow>
              <TableCell colSpan={3} align="center" sx={styles.cell}>
                Нет информации о датчиках
              </TableCell>
            </TableRow>
          ) : (
            data.map((row, indx) => (
              <TableRow key={indx}>
                <TableCell align="center" sx={[styles.cell, styles.borderRight, styles.clickableCell]} onClick={(e) => handleCellClick(row)}>
                  {row.deviceName}
                </TableCell>
                <TableCell align="center" sx={[styles.cell, styles.borderRight, styles.clickableCell]} onClick={(e) => handleCellClick(row)}>
                  {row.indicationName}
                </TableCell>
                <TableCell align="center" sx={styles.cell}>
                  <Box sx={[styles.rectangle, row.eventType === 'service' && { background: '#1478BE' }]} />
                </TableCell>
                <TableCell align="center" sx={styles.cell}>
                  <Box sx={[styles.rectangle, row.eventType === 'excess' && { background: '#EAAF6B' }]} />
                </TableCell>
                <TableCell align="center" sx={[styles.cell]}>
                  <Box sx={[styles.rectangle, row.eventType === 'critical' && { background: '#CA807F' }]} />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const styles = {
  tableContainer: { maxHeight: '450px', overflowX: 'none' },
  table: { width: '100%' },
  tableBody: {
    overflowY: 'auto',
  },
  headerCell: {
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%',
    paddingTop: '10px',
    whiteSpace: 'nowrap',
  },
  cell: {
    padding: '5px',
    borderBottom: 'none',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%',
  },
  clickableCell: {
    cursor: 'pointer',
  },
  borderRight: {
    borderRight: '1px solid #393939',
  },
  rectangleCell: {
    width: '208px',
    height: '100%',
  },
  rectangle: {
    margin: 'auto',
    width: '100px',
    height: '28px',
    background: '#A7B6CD',
  },
};
