import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";
import { AnomaliesEventsData } from "../../../models/dataAnalysis/anomalies/anomaliesEventsData";
import { ElectricityTableItemDto } from "../../../models/electricity/electricityTableItemDto";
import { electricityApi } from "../../../pages/balance/electricity/api/electricityApi";
import { v4 as uuidv4 } from "uuid";
import { ElectricityUpdateItemDto } from "../../../models/electricity/electricityUpdateItemDto";

const sliceName = "electricity";

interface InitialState {
  data: ElectricityTableItemDto[];
  isLoading: LoadingState;
}

const initialState: InitialState = {
  data: [],
  isLoading: LoadingState.EMPTY,
};

export const getElectricityTableData = createAsyncThunk<
  ElectricityTableItemDto[],
  { objects: string[]; startDt: string; endDt: string },
  { state: RootState }
>(`${sliceName}/getElectricityTableData`, async (params, thunkAPI) => {
  try {
    const response = await electricityApi.getBalanceElectricityData(params.objects, params.startDt, params.endDt);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
  }
});

export const updateElectricityNorm = createAsyncThunk<void, ElectricityUpdateItemDto, { state: RootState }>(
  `${sliceName}/updateElectricityNorm`,
  async (params, thunkAPI) => {
    try {
      const response = await electricityApi.updateBalanceElectricityNorm(params);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const electricitySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getElectricityTableData.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getElectricityTableData.fulfilled, (state, action) => {
      state.data = action.payload.map((x) => ({ ...x, id: uuidv4() }));
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getElectricityTableData.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const electricityReducer = electricitySlice.reducer;
export const { setInitialState } = electricitySlice.actions;
