import { Tab } from "@mui/material";
import { TabItem } from "../../components/tabsWithRoutes/tabItem";
import { routeUrls } from "../../routes/routeUrls";
import { Link } from "react-router-dom";

export const BalanceTabConfig: TabItem[] = [
  {
    label: "Водоснабжение",
    route: routeUrls.waterSupply,
  },
  {
    label: "Водоотведение",
    route: routeUrls.waterDischarge,
  },
  {
    label: "Электроэнергия",
    route: routeUrls.electricity,
  },
  {
    label: "Информация по зонам",
    route: routeUrls.zones,
    getCustomTabComponent: (tab: TabItem) => {
      return (
        <Tab
		  key={tab.route}
          label={
            <Link
              style={{ alignSelf: "center", color: "inherit", cursor: "pointer", textTransform: "uppercase" }}
              to="zones/XVI"
            >
              Информация по зонам
            </Link>
          }
          disableFocusRipple
        />
      );
    },
  },
];
