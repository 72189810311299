import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Animation from "../../../components/uiComponents/Animation/Animation";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { getCards } from "../../../store/slices/SummaryDataSlice";
import { LoadingState } from "../../../types/loadingState";
import BlockInfo from "./BlockInfo";

export const BlockInfoContainer = () => {
  const dispatch = useDispatch<any>();

  const cards = useTypedSelector((state) => state.summaryData.cards);
  const isLoadingCard = useTypedSelector((state) => state.summaryData.isLoadingCards);

  const loadData = () => {
    dispatch(getCards());
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="description">
      {isLoadingCard === LoadingState.PENDING ? (
        <Animation />
      ) : (
        cards.map((item) => (
          <BlockInfo
            name={item.name}
            header={item.header}
            blocks={item.blocks}
            withDetailedInfo={true}
            onModalSave={loadData}
            key={item.name}
          />
        ))
      )}
    </div>
  );
};
