import React from 'react';
import './Animation.scss';

interface LoaderProps {
  asOverlay?: boolean;
}

const Loader = (props: LoaderProps) => {
  return (
    <div className={`loader-container ${props.asOverlay && "as-overlay"}`}>
      <div className="loader"></div>
    </div>
  );
};

export default Loader;