import { BaseButton, BaseButtonProps } from "../../../../../components/baseButton/baseButton";

export const SettingsButton = (props: BaseButtonProps) => {
  return (
    <BaseButton {...props} sx={styles.button}>
      {props.children}
    </BaseButton>
  );
};

const styles = {
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    color: "rgba(0, 0, 0, 0.90)",
    fontSize: "12.8px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "160%",
    letterSpacing: "1.92px",
    textTransform: "uppercase",
    padding: "6px 10px",
    borderRadius: "6px",
    border: "1px solid var(--Additional-Blue, #1599D7)",

    ".MuiButton-endIcon": {
      margin: "0 0 0 12px",
    },
    ".MuiSvgIcon-root": {
      color: "#1599D7",
    },
  },
};
