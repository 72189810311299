import { Tab, Tabs } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { customTabProps } from "../../utilities/customTabProps";
import { TabItem } from "./tabItem";

interface Props {
  config: TabItem[];
}

export const TabsWithRoutes = (props: Props) => {
  const path = useLocation().pathname.split("/").reverse()[0];

  const currentTab = props.config.find((x) => x.route === path)?.route || props.config[0].route;

  return (
    <Tabs value={currentTab} TabIndicatorProps={{ style: { background: "#1478BE" } }}>
      {props.config.map((tab, index) => {
        if (!tab.getCustomTabComponent) {
          return (
            <Tab
              label={tab.label}
              value={tab.route}
              to={tab.route}
              component={Link}
              key={`tab-${index}`}
              {...customTabProps(index)}
            />
          );
        }

        return tab.getCustomTabComponent(tab);
      })}
    </Tabs>
  );
};
