import { BaseModalProps } from "../../../components/baseModal/BaseModal";
import { LossesPlanModal } from "../blockInfo/modal/LossesPlanModal";

interface BlockInfoModalConfig {
  name: string;
  modal: React.FC<BaseModalProps>;
}

export const blockInfoModalConfig: BlockInfoModalConfig[] = [
  {
    name: "Losses",
    modal: LossesPlanModal,
  },
];
