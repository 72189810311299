import { setLocale } from 'yup';

export const setYupLocales = () => {
  setLocale({
    mixed: {
      required: 'поле должно быть заполнено',
    },
    number: {
      min: 'значение должно быть больше ${min}',
      max: 'значение должно быть меньше ${max}',
    },
  });
};
