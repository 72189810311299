import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { useField } from "formik";
import { SelectItem } from "../../../../models/common/selectItem";

interface CustomSelectProps {
  name: string;
  options: SelectItem[];
}

export const SelectField = (props: CustomSelectProps & SelectProps) => {
  const [field, meta, helpers] = useField(props.name);
  const isError = meta.touched && Boolean(meta.error);
  const labelId = `select-helper-label-${props.name}`

  return (
    <FormControl fullWidth>
      <InputLabel size="small" id={labelId} error={isError}>
        {props.label}
      </InputLabel>
      <Select
        labelId={labelId}
        fullWidth
        size="small"
        {...props}
        {...field}
        error={isError}
      >
        {props.options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{meta.touched && meta.error && meta.error}</FormHelperText>
    </FormControl>
  );
};
