import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box } from "@mui/material";
import { PayloadAction } from "@reduxjs/toolkit";
import { ChangeEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  downloadSettingsTableDataCsv,
  addNewItem,
  createSettingsTableData,
  deleteSettingsTableData,
  getSettingsTableData,
  removeNewItem,
  setInitialState,
  setSelectedItemId,
  setSettingsCurrentPage,
  uploadSettingsTableDataCsv,
  setSettingsPageSize,
  updateSettingsTableData,
} from "../../../../store/slices/settings/SettingsTableSlice";
import { LoadingState } from "../../../../types/loadingState";
import * as yup from "yup";
import { BaseTableDataItem } from "../../../../models/baseTable/baseTableDataItem";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";
import { isNullOrEmpty } from "../../../../utilities/string/isNullOrEmpty";
import { SettingsButton } from "../shared/settingsButton/settingsButton";

interface SettingsTableProps {
  tableColumns: BaseTableColumnDef[];
  validationSchema?: yup.ObjectSchema<any>;
  crudDataMethods?: {
    getInitialValues?: () => any;
    generateCreateData: (data: BaseTableDataItem) => any;
    generateEditData: (data: BaseTableDataItem) => any;
    generateDeleteData: (data: BaseTableDataItem) => any;
    deleteFunc?: (params: any) => Promise<void>;
  };
}

export const SettingsTable = ({ tableColumns, crudDataMethods, validationSchema }: SettingsTableProps) => {
  const dispatch = useDispatch<any>();

  const tableName = useTypedSelector((state) => state && state.settingsTable.tableName);

  const tableData = useTypedSelector((state) => state.settingsTable.tableData);
  const totalItems = useTypedSelector((state) => state.settingsTable.totalItems);
  const selectedItemId = useTypedSelector((state) => state.settingsTable.selectedItemId);

  const currentPage = useTypedSelector((state) => state.settingsTable.currentPage);
  const pageSize = useTypedSelector((state) => state.settingsTable.pageSize);

  const isLoading = useTypedSelector((state) => state.settingsTable.isLoading);

  const loadData = () => {
    dispatch(getSettingsTableData());
  };

  const handleAddNewRow = () => {
    const initialValue = crudDataMethods?.getInitialValues ? crudDataMethods?.getInitialValues() : {};
    dispatch(addNewItem(initialValue));
  };

  const handleDelete = async () => {
    if (!selectedItemId) {
      return;
    }

    const item = tableData.find((i) => i.id === selectedItemId);
    if (item && crudDataMethods) {
      const params = crudDataMethods.generateDeleteData(item);
      if (crudDataMethods.deleteFunc) {
        await crudDataMethods.deleteFunc(params);
      } else {
        await dispatch(deleteSettingsTableData(params));
      }
      dispatch(getSettingsTableData());
    }
  };

  const handleSave = async (values: any) => {
    if (values.isNew) {
      const params = crudDataMethods?.generateCreateData ? crudDataMethods.generateCreateData(values) : values;
      await dispatch(createSettingsTableData(params));
    } else {
      const params = crudDataMethods?.generateEditData ? crudDataMethods.generateEditData(values) : values;
      await dispatch(updateSettingsTableData(params));
    }

    loadData();
  };

  const handleCancel = (id: string) => {
    const newItem = tableData.find((x) => x.isNew === true);
    if (newItem) {
      dispatch(removeNewItem(id));
    }
  };

  const handleChangePage = (page: number) => {
    dispatch(setSettingsCurrentPage(page));
  };

  const handleChangePageSize = (pageSize: number) => {
    dispatch(setSettingsPageSize(pageSize));
  };

  const handleSelectedIdChange = (id: string | null) => {
    dispatch(setSelectedItemId(id));
  };

  const handleExportCsv = () => {
    dispatch(downloadSettingsTableDataCsv())
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    if (file.type !== "text/csv") {
      return;
    }

    await dispatch(uploadSettingsTableDataCsv({ file }));
    loadData();
  };

  useEffect(() => {
    if (!isNullOrEmpty(tableName)) {
      loadData();
    }
  }, [tableName, currentPage, pageSize]);

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, []);

  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.toolBox}>
        <SettingsButton
          variant="outlined"
          size="large"
          onClick={handleExportCsv}
          endIcon={<UploadFileIcon />}
          disabled={isLoading === LoadingState.PENDING}
        >
          Экспорт CSV
        </SettingsButton>
        <SettingsButton
          variant="outlined"
          component="label"
          size="large"
          endIcon={<UploadFileIcon />}
          disabled={isLoading === LoadingState.PENDING}
        >
          Импорт CSV
          <input type="file" hidden accept=".csv" onChange={handleFileUpload} />
        </SettingsButton>
        <SettingsButton variant="outlined" onClick={handleAddNewRow} disabled={isLoading === LoadingState.PENDING}>
          Добавить строку
        </SettingsButton>
        <SettingsButton variant="outlined" onClick={handleDelete} disabled={isLoading === LoadingState.PENDING}>
          Удалить строку
        </SettingsButton>
      </Box>
      <BaseTable
        editable
        columns={tableColumns}
        data={tableData}
        onSave={handleSave}
        onCancel={handleCancel}
        pageSize={pageSize}
        totalItems={totalItems}
        currentPage={currentPage}
        onChangePage={handleChangePage}
        onChangePageSize={handleChangePageSize}
        selectedId={selectedItemId}
        onSelectedIdChange={handleSelectedIdChange}
        isLoading={isLoading === LoadingState.PENDING}
        validationSchema={validationSchema}
      />
    </Box>
  );
};
const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    paddingTop: "20px",
  },
  toolBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
};
