export const routeUrls = {
  home: "/",
  login: "/login",

  monitoring: "monitoring",
  signalEventInfo: "monitoring/:guid",

  objectInfoPage: "objectInfo",
  objectInfo: "objectInfo/:guid",

  balance: "balance",
  waterSupply: "waterSupply",
  waterDischarge: "waterDischarge",
  electricity: "electricity",
  zones: "zones",
  zone: "balance/zones/:zone",
  bg: "balance/zones/:zone/:bg",

  dataAnalysis: "dataAnalysis",
  productionVolume: "productionVolume",
  networkSupply: "networkSupply",
  dischangeVolume: "dischangeVolume",
  balanceAndVolume: "balance",
  anomalies: "anomalies",
  crossCheckout: "crossCheckout",
  modelCustomer: "modelCustomer",

  settings: "settings",
  passport: "passport",
  electricityConsumedStandards: "electricityConsumedStandards",
  waterPaidData: "waterPaidData",
  deviceData: "deviceData",
  consumedElectricity: "consumedElectricity",

  users: "users",
  userList: "users",
  roleList: "roles",
  
  reports: "reports",
};
