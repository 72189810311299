import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/uiComponents/Button/CustomButton";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Animation from "../../../components/uiComponents/Animation/Animation";
import { ApexLocales } from "../../../constants/ApexChartsSettings";
import moment, { Moment } from "moment";
import { GetObjectNames, GetObjectTypes, GetObjectsElectricitySupply, SimpleListElem } from "../ElectricityAPI";
import { ElectricityTable } from "./components/electricityTable";
import { CustomDateTimePicker } from "../../../components/formFields/customDateTimePicker.tsx/customDateTimePicker";
import { getFormattedDate } from "../../../utilities/dateHelper";

interface ElectricityInterface {
  // zoneNumber: string;
}

const Electricity: React.FC<ElectricityInterface> = () => {
  const [objectTypes, setObjectTypes] = useState<SimpleListElem[]>([]);
  const [objectType, setObjectType] = useState("");
  const [objects, setObjects] = useState<SimpleListElem[]>([]);
  const [chosenObjects, setChosenObjects] = useState<any[]>([]);

  const [endDate, setEndDate] = useState<Moment>(moment());
  const [startDate, setStartDate] = useState<Moment>(moment());

  const [isLoadingLine, setIsLoadingLine] = useState(false);
  const buttonSensor = ["buttonYear", "buttonHalfYear", "buttonQuarter", "buttonQuarterYear", "buttonMonth"];
  const [lineGraph, setLineGraph] = useState<ApexOptions>({
    series: [],
    chart: {
      id: "mainChart",
      animations: {
        enabled: false,
      },
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
      events: {
        // mounted: (_, options) => {
        //   options?.config?.series?.forEach((data: { name: string }) => {
        //     if (data.name !== "Удельное потребление") {
        //       ApexCharts.exec("mainChart", "hideSeries", data.name);
        //     }
        //   });
        // },
      },
    },
    markers: {
      size: 4,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
    },
    xaxis: {
      type: "datetime",
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value + " кВт*ч/м3";
        },
      },
    },
  });

  const ALL_OBJECTS = "Выбрать все";

  // Функция смены класса кнопки периода
  function buttonClassChange(id: string) {
    // Находим кнопку по id
    var btn = document.getElementById(id);
    // Была ли кнопка найдена
    if (btn !== null) {
      btn.classList.add("timePeriodButtonActive");
    }
    if (btn !== null || id === "undefined") {
      buttonSensor.forEach((button) => {
        if (button !== id) {
          var btn1 = document.getElementById(button);
          if (btn1 !== null) {
            btn1.classList.remove("timePeriodButtonActive");
          }
        }
      });
    }
  }

  // Функция выбора периода
  function setTimePeriod(type: string) {
    let endDateTemp = new Date();
    let startDateTemp = new Date();

    switch (type) {
      case "year":
        startDateTemp = new Date(Date.now() - 1000 * 3600 * 24 * 365);
        break;

      case "halfYear":
        startDateTemp = new Date(Date.now() - 1000 * 3600 * 24 * 183);
        break;

      case "quarter":
        startDateTemp = new Date(Date.now() - 1000 * 3600 * 24 * 91);
        break;

      default:
        startDateTemp = new Date(Date.now() - 1000 * 3600 * 24 * 365);
    }
    // setPeriod(per);

    const startDate = moment(startDateTemp);
    const endDate = moment(endDateTemp);

    setStartDate(startDate);
    setEndDate(endDate);
    getObjectsElectricityChart(startDate, endDate);
  }

  async function getObjectTypes() {
    try {
      const response = await GetObjectTypes();
      setObjectTypes(response);
    } catch (e) {
      console.error(e);
    }
  }

  async function getObjectNames(type: string) {
    try {
      setChosenObjects([]);
      const response = await GetObjectNames(type);
      setObjects(response);
    } catch (e) {
      console.error(e);
    }
  }

  async function getObjectsElectricityChart(startDate: Moment, endDate: Moment) {
    if (chosenObjects.length === 0) return 0;
    setIsLoadingLine(true);
    try {
      const response = await GetObjectsElectricitySupply(
        // [1, 4],
        chosenObjects,
        getFormattedDate(startDate),
        getFormattedDate(endDate)
      );
      setLineGraph({ ...lineGraph, series: response.series, labels: response.dates });
      setIsLoadingLine(false);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    buttonClassChange("buttonYear");
    setTimePeriod("year");
    getObjectTypes();
  }, []);

  useEffect(() => {
    getObjectsElectricityChart(startDate, endDate);
  }, [chosenObjects.length]);

  return (
    <div className="balanceTabDefault" style={{ marginTop: "12px" }}>
      <div style={{ whiteSpace: "nowrap" }} className="headerH2">
        Электроэнергия
      </div>
      <div className="leftLegendaDate">
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Тип объекта</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={objectType}
            label="Тип отчета"
            onChange={(e) => {
              setObjectType(e.target.value);
            }}
          >
            {objectTypes.map((objType) => {
              return (
                <MenuItem value={objType.id} key={objType.id} onClick={() => getObjectNames(objType.name)}>
                  {objType.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Объекты</InputLabel>
          <Select
            multiple
            displayEmpty
            sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={chosenObjects}
            label="Тип отчета"
            disabled={objectType === ""}
            onChange={(e) => {
              if (Array.isArray(e.target.value)) {
                if (e.target.value.includes(ALL_OBJECTS)) {
                  if (chosenObjects.length === objects.length) {
                    setChosenObjects([]);
                  } else {
                    setChosenObjects(objects.map((obj) => obj.name));
                  }
                } else {
                  setChosenObjects(e.target.value);
                }
              }
            }}
            renderValue={(selected) => {
              // if (selected.length === 0) {
              //   return <em>Выберите объекты</em>;
              // }

              // let line: string[] = [];
              // objects.forEach((obj) => {
              //   let foundObj = chosenObjects.find((chObj: number | string) => chObj === obj.id);
              //   if (foundObj !== undefined) {
              //     line.push(obj.name);
              //   }
              // });

              return selected.join(", ");
            }}
          >
            {objects.length !== 0 && <MenuItem value={ALL_OBJECTS}>{ALL_OBJECTS}</MenuItem>}
            {objects.map((object) => {
              return (
                <MenuItem value={object.name} key={object.id}>
                  {object.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <CustomButton
          style={{ width: "100%", height: "40px", maxWidth: "150px" }}
          type="timePeriodButton"
          id="buttonYear"
          onClick={() => {
            buttonClassChange("buttonYear");
            setTimePeriod("year");
          }}
        >
          12 месяцев
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px", maxWidth: "150px" }}
          type="timePeriodButton"
          id="buttonHalfYear"
          onClick={() => {
            buttonClassChange("buttonHalfYear");
            setTimePeriod("halfYear");
          }}
        >
          6 месяцев
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px", maxWidth: "150px" }}
          type="timePeriodButton"
          id="buttonQuarterYear"
          onClick={() => {
            buttonClassChange("buttonQuarterYear");
            setTimePeriod("quarter");
          }}
        >
          3 месяца
        </CustomButton>
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          Период С
        </div>
        <CustomDateTimePicker
          dateValue={startDate}
          onDateValueChange={(value) => {
            if (value) {
              setStartDate(value);
              buttonClassChange("undefined");
              getObjectsElectricityChart(value, endDate);
            }
          }}
        />
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          По
        </div>
        <CustomDateTimePicker
          dateValue={endDate}
          onDateValueChange={(value) => {
            if (value) {
              setEndDate(value);
              buttonClassChange("undefined");
              getObjectsElectricityChart(startDate, value);
            }
          }}
        />
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Годы</InputLabel>
          <Select
            disabled
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            //   value={measure2}
            label="Годы"
            //   onChange={(e) => {
            //     setMeasure2(e.target.value);
            //   }}
          >
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
            <MenuItem value={2020}>2020</MenuItem>
          </Select>
        </FormControl>
      </div>
      {chosenObjects.length !== 0 && objectType !== "" ? (
        <>
          <div style={{ height: "357px", width: "100%" }}>
            {isLoadingLine ? (
              <Animation></Animation>
            ) : (
              <Chart height={"100%"} width={"100%"} options={lineGraph} series={lineGraph.series} type="line" />
            )}
          </div>
          <Box sx={{ height: "calc(100vh - 675px)", width: "100%" }} className="tableHeight">
            <ElectricityTable
              objects={chosenObjects}
              startDt={getFormattedDate(startDate)}
              endDt={getFormattedDate(endDate)}
            />
          </Box>
        </>
      ) : (
        <div className="wrongRequest">
          <p className="headerH1" style={{ color: "#ca807fcc" }}>
            Выберите объект(ы)
          </p>
        </div>
      )}
    </div>
  );
};

export default Electricity;
