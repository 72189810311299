import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BaseModalProps } from "../../../../components/baseModal/BaseModal";
import Loader from "../../../../components/uiComponents/Animation/Animation";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { getLossesPlanData, saveLossesPlanData, setInitialState } from "../../../../store/slices/LossesPlanSlice";
import { LoadingState } from "../../../../types/loadingState";

interface LossesPlanModalProps extends BaseModalProps {}

const monthList = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const LossesPlanModal: React.FC<LossesPlanModalProps> = ({ onSave, onCancel }) => {
  const dispatch = useDispatch<any>();

  const lossesPlanData = useTypedSelector((store) => store.lossesPlan.lossesPlanData);
  const isLoading = useTypedSelector((store) => store.lossesPlan.isLoading);

  const getInitialValues = (data: number[]) => {
    return data.reduce((acc: { [key: string]: number }, i, indx) => {
      acc[indx] = i;
      return acc;
    }, {});
  };

  const mappedLossesPlanData = lossesPlanData.map((i, indx) => ({
    name: indx.toString(),
    title: monthList[indx],
    value: i,
  }));

  const handleSave = async (data: any) => {
    await dispatch(saveLossesPlanData(Object.values(data)));
    onSave && onSave();
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const formik = useFormik({
    initialValues: getInitialValues([]),
    onSubmit: handleSave,
  });

  useEffect(() => {
    dispatch(getLossesPlanData());
    return () => {
      dispatch(setInitialState());
    };
  }, []);

  useEffect(() => {
    formik.setValues(getInitialValues(lossesPlanData));
  }, [lossesPlanData]);

  return (
    <Modal open={true} onClose={handleCancel}>
      <Box sx={styles.modal}>
        <IconButton sx={styles.closeBtn} size="small" onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
        <Box sx={styles.header} className="headerH3">
          План потерь
        </Box>

        {isLoading === LoadingState.PENDING ? (
          <Loader />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Box sx={styles.content}>
              {mappedLossesPlanData.map((data) => {
                return (
                  <Box className="LossesPlanTable" key={data.name}>
                    <Box className="LPTableMonth">{data.title}</Box>
                    <Box className="LPTableValue">
                      <TextField
                        variant="standard"
                        type="number"
                        fullWidth
                        name={data.name}
                        value={formik.values[data.name]}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        margin="dense"
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </form>
        )}

        <Box style={styles.footer}>
          <CustomButton onClick={handleCancel} type="cancel">
            Отмена
          </CustomButton>
          <CustomButton
            onClick={() => {
              formik.submitForm();
            }}
            disabled={isLoading === LoadingState.PENDING}
          >
            Сохранить
          </CustomButton>
        </Box>
      </Box>
    </Modal>
  );
};

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    px: 4,
    py: 2,
  },
  header: {
    width: "100%",
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    maxHeight: "75vh",
    overflowY: "auto",
  },
  footer: {
    display: "flex",
    gap: "12px",
    justifyContent: "flex-end",
    width: "100%",
  },
  closeBtn: {
    position: "absolute",
    top: "12px",
    right: "12px",
  },
};
