import { Button } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { EventList } from "../../../../widgets/events/eventList/eventList";
import Loader from "../../../../components/uiComponents/Animation/Animation";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { EventActions } from "../../../../models/events/eventActions";
import {
  getAnomaliesEventsData,
  setEventListPaginationSettings,
  setInitialState,
} from "../../../../store/slices/dataAnalysis/anomaliesSlice";
import { LoadingState } from "../../../../types/loadingState";
import { EventKeyDto } from "../../../../models/events/eventKeyDto";
import { acceptEvent } from "../../../../store/slices/monitoring/MonitoringEventsSlice";
import { DefaultPaginationSettings } from "../../../../models/common/paginationSettings";
import { SignalType } from "../../../../models/events/signalType";

export const AnomaliesEvents = () => {
  const dispatch = useDispatch<any>();

  const eventsData = useTypedSelector((state) => state.anomalies.eventsData);
  const paginationSettings = useTypedSelector((state) => state.anomalies.eventListPaginationSettings);

  const isLoading = useTypedSelector((state) => state.anomalies.isLoading);

  const loadData = () => {
    dispatch(getAnomaliesEventsData());
  };

  const handleTakeNote = async (key: EventKeyDto) => {
    await dispatch(acceptEvent(key));
    loadData();
  };

  const eventActions: EventActions = {
    [SignalType.Null]: (key: EventKeyDto) => (
      <>
        <Button onClick={() => handleTakeNote(key)} size="small" sx={styles.actionButton}>
          Принять к сведению
        </Button>
      </>
    ),
  };

  const handlePageChange = (page: number) => {
    dispatch(setEventListPaginationSettings({ ...paginationSettings, currentPage: page }));
  };

  const handlePageSizeChange = (pageSize: number) => {
    dispatch(
      setEventListPaginationSettings({ ...paginationSettings, ...DefaultPaginationSettings, pageSize: pageSize })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, []);

  useEffect(() => {
    loadData();
  }, [paginationSettings]);

  if (!eventsData && isLoading === LoadingState.PENDING) {
    return <Loader />;
  }

  return (
    <>
      {eventsData && (
        <EventList
          events={eventsData.page?.data || []}
          criticalCount={eventsData.criticalCount}
          excessCount={eventsData.excessCount}
          serviceCount={eventsData.serviceCount}
          lastUpdate={eventsData.lastUpdate}
          eventActions={eventActions}
          isLoading={isLoading}
          isHeaderHidden
          isToolBoxCentered
          paginationSettings={{
            currentPage: paginationSettings.currentPage,
            pageSize: paginationSettings.pageSize,
            totalCount: eventsData.page?.totalCount || 0,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
          }}
        />
      )}
    </>
  );
};
const styles = {
  actionButton: {
    padding: "0 ",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "100%",
  },
};
