export interface zoneState {
  zoneNumber: string;
}

export enum zoneActionTypes {
  CHANGE_ZONE = "CHANGE_ZONE",
}

interface ChangeZoneAction {
  type: zoneActionTypes.CHANGE_ZONE;
  payload: string;
}

export type zoneAction = ChangeZoneAction;
