import { combineReducers } from "redux";
import { lossesPlanReducer } from "../slices/LossesPlanSlice";
import { monitoringEventsReducer } from "../slices/monitoring/MonitoringEventsSlice";
import { summaryDataReducer } from "../slices/SummaryDataSlice";
import { settingsTableReducer } from "../slices/settings/SettingsTableSlice";
import { passportDataReducer } from "../slices/settings/passportDataSlice";
import { dataAnalysisTableViewReducer } from "./../slices/dataAnalysis/dataAnalysisTableViewSlice";
import { zoneReducer } from "./zoneReducer";
import { monitoringEventInfoReducer } from "../slices/monitoring/MonitoringEventInfoSlice";
import { objectInfoReducer } from "../slices/objects/objectInfoSlice";
import { monitoringMapReducer } from "../slices/monitoringMapSlice";
import { anomaliesReducer } from "../slices/dataAnalysis/anomaliesSlice";
import { zoneDistributionReducer } from "../slices/zonesDistribution/zoneDistributionSlice";
import { electricityReducer } from "../slices/electricity/electricitySlice";
import { normsReducer } from "../slices/objects/normsSlice";
import { deviceInfoReducer } from "../slices/objects/deviceInfoSlice";
import { usersReducer } from "../slices/userManagement/usersSlice";
import { rolesReducer } from "../slices/userManagement/rolesSlice";
import { loginReducer } from "../slices/login/loginSlice";
import { userAccessEditorReducer } from "../slices/userManagement/usersAccessEditorSlice";
import { createEditUserReducer } from "../slices/userManagement/createEditUserSlice";
import { accountReducer } from "../slices/account/accountSlice";

export const rootReducer = combineReducers({
  account: accountReducer,
  login: loginReducer,
  zone: zoneReducer,
  lossesPlan: lossesPlanReducer,
  summaryData: summaryDataReducer,
  settingsTable: settingsTableReducer,
  passportData: passportDataReducer,
  dataAnalysisTableView: dataAnalysisTableViewReducer,
  monitoringEvents: monitoringEventsReducer,
  monitoringEventInfo: monitoringEventInfoReducer,
  objectInfo: objectInfoReducer,
  norms: normsReducer,
  deviceInfo: deviceInfoReducer,
  monitoringMap: monitoringMapReducer,
  anomalies: anomaliesReducer,
  zoneDistribution: zoneDistributionReducer,
  electricity: electricityReducer,
  users: usersReducer,
  roles: rolesReducer,
  createEditUser: createEditUserReducer,
  userAccessEditor: userAccessEditorReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
