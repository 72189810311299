import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { usersApi } from "../../../pages/userManagement/api/usersApi";
import {
  PageAccessData,
  PageAccessTableData,
  User,
  UserAccessPages,
  UserAccessPagesAccessibility,
} from "../../../pages/userManagement/types/usersTypes";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";
import { AxiosError } from "axios";

const sliceName = "userAccessEditor";

interface IInitialState {
  initialTableData: PageAccessTableData[];
  tableData: PageAccessTableData[];

  currentUserGuid: string;
  currentUserData: User | null;

  isLoading: LoadingState;
}

const initialState: IInitialState = {
  initialTableData: [],
  tableData: [],

  currentUserGuid: "",
  currentUserData: null,

  isLoading: LoadingState.EMPTY,
};

export const getUserByGuid = createAsyncThunk<User, void, { state: RootState }>(
  `${sliceName}/getUserByGuid`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const response = await usersApi.getUserByGuid(state.currentUserGuid ?? "");

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const getAccessiblePages = createAsyncThunk<PageAccessData[], void, { state: RootState }>(
  `${sliceName}/getAccessiblePages`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const response = await usersApi.getAccessiblePages(state.currentUserData?.id ?? "");

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const saveAccessiblePages = createAsyncThunk<void, void, { state: RootState }>(
  `${sliceName}/saveAccessiblePages`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const accessibility = [] as UserAccessPagesAccessibility[];
      state.tableData.forEach((item) => {
        const initData = state.initialTableData.find((i) => i.id === item.id);
        if (initData && initData.accessible !== item.accessible) {
          accessibility.push({ id: item.id, accessible: item.accessible });
        }
      });
      const data: UserAccessPages = {
        userId: state.currentUserData?.id ?? "",
        accessibility,
      };
      const response = await usersApi.putAccessiblePages(data);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data ?? "Ошибка соединения. Повторите попытку");
    }
  }
);

const userAccessEditorSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    updateTableDataItem: (state, action) => {
      const copyTableData = JSON.parse(JSON.stringify(state.tableData)) as PageAccessTableData[];
      copyTableData.forEach((row) => {
        if (row.childIdList.includes(action.payload.id) && action.payload.value) {
          row.accessible = true;
        }
        if (row.id === action.payload.id) {
          row.accessible = action.payload.value;
          if (!action.payload.value) {
            row.childIdList.forEach((item) => {
              const childItem = copyTableData.find((i) => i.id === item);
              if (childItem) {
                childItem.accessible = false;
              }
            });
          }
        }
      });
      state.tableData = copyTableData;
    },
    setCurrentUserGuid: (state, action) => {
      state.currentUserGuid = action.payload;
    },
    setInitialState: (state) => {
      state.tableData = [];
      state.currentUserData = null;
      state.isLoading = LoadingState.EMPTY;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccessiblePages.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getAccessiblePages.fulfilled, (state, action) => {
      const res: PageAccessTableData[] = [];
      const addRow = (access: PageAccessData, paddingValue = 0) => {
        res.push({
          code: access.code,
          id: access.id,
          name: access.name,
          accessible: access.accessible,
          paddingValue,
          childIdList: access.subpages?.map((item) => item.id) ?? [],
        });
        if (access.subpages) {
          access.subpages.forEach((subPagesAccess) => {
            addRow(subPagesAccess, paddingValue + 1);
          });
        }
      };

      action.payload.forEach((access) => {
        addRow(access);
      });

      state.initialTableData = res;
      state.tableData = res;
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getAccessiblePages.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });

    builder.addCase(getUserByGuid.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getUserByGuid.fulfilled, (state, action) => {
      state.currentUserData = action.payload;
    });

    builder.addCase(saveAccessiblePages.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(saveAccessiblePages.fulfilled, (state, action) => {
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(saveAccessiblePages.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const userAccessEditorReducer = userAccessEditorSlice.reducer;
export const { updateTableDataItem, setCurrentUserGuid, setInitialState } = userAccessEditorSlice.actions;
