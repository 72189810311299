import { Box } from "@mui/material";
import moment from "moment";
import { eventCardIcons } from "../../../constants/events/eventCardConstants";
import { EventActions } from "../../../models/events/eventActions";
import { momentCalendarLocales } from "../../../utilities/moment/momentCalendarLocales";
import { EventItemDto } from "../../../models/events/eventItemDto";
import { isNullOrUndefined } from "../../../utilities/isNullOrUndefined";

interface EventCardProps {
  cardData: EventItemDto;
  isCurrent?: boolean;

  renderTitle?: (eventData: EventItemDto) => React.ReactNode;
  actions?: EventActions;
}

export const EventCard = ({ cardData, renderTitle, actions, isCurrent }: EventCardProps) => {
  const currentIcon = eventCardIcons[cardData.type];
  const getEventAction = actions ? actions[cardData.feature] : undefined;

  return (
    <Box
      sx={[
        styles.container,
        isNullOrUndefined(cardData.feature) ? styles.notProcessed : styles.processed,
        isCurrent ? styles.current : {},
      ]}
    >
      <Box sx={styles.left}>{currentIcon}</Box>
      <Box sx={styles.right}>
        <Box sx={styles.header}>
          <Box sx={styles.title}>{renderTitle ? renderTitle(cardData) : cardData.objectName}</Box>
          <Box sx={styles.date}>{moment(cardData.dateTime).calendar(momentCalendarLocales)}</Box>
        </Box>
        <Box sx={styles.message}>{cardData.message}</Box>
        {getEventAction && <Box sx={styles.actions}>{getEventAction(cardData.key, cardData)}</Box>}
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    padding: "8px 8px 12px",
    display: "flex",
    gap: "8px",
    borderRadius: "12px",
  },
  notProcessed: {
    borderBottom: "1px solid #D2D2CF",
    background: "#F7F8F9",
  },
  processed: {
    border: "none",
    background: "#DBF1EE",
  },
  current: {
    border: "none",
    background: "#fbefe1",
  },
  left: {
    width: "24px",
  },
  right: {
    flex: "1 0 50%",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  header: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  title: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
  },
  date: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "140%",
  },
  message: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "140%",
  },
  actions: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
  },
  currentCardDetail: {
    background: "rgba(234, 175, 107, 0.20)",
    borderBottom: "1px solid rgba(210, 210, 207, 0.20)",
  },
};
