import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";
import { DataAnalysisApi } from "../../../pages/dataAnalysis/api/dataAnalysisApi";
import { BaseTableDataItem } from "../../../models/baseTable/baseTableDataItem";
import { v4 as uuidv4 } from "uuid";

const sliceName = "dataAnalysisTableView";

interface InitialState {
  data: BaseTableDataItem[];
  isLoading: LoadingState;
}

const initialState: InitialState = {
  data: [],
  isLoading: LoadingState.EMPTY,
};

export const getTableViewData = createAsyncThunk<BaseTableDataItem[], string, { state: RootState }>(
  `${sliceName}/getTableViewData`,
  async (urlPart, thunkAPI) => {
    try {
      if (urlPart === "get_cross-checkout") {
        console.log(urlPart);
        return [
          {
            date: "Январь, 2023",
            indication1: "22",
            indication2: "19",
          },
          {
            date: "Февраль, 2023",
            indication1: "16",
            indication2: "30",
          },
          {
            date: "Март, 2023",
            indication1: "17",
            indication2: "58",
          },
          {
            date: "Апрель, 2023",
            indication1: "18",
            indication2: "56",
          },
          {
            date: "Май, 2023",
            indication1: "25",
            indication2: "50",
          },
          {
            date: "Июнь, 2023",
            indication1: "27",
            indication2: "100",
          },
          {
            date: "Июль, 2023",
            indication1: "19",
            indication2: "20",
          },
          {
            date: "Август, 2023",
            indication1: "19",
            indication2: "19",
          },
          {
            date: "Сентябрь, 2023",
            indication1: "19",
            indication2: "19",
          },
          {
            date: "Октябрь, 2023",
            indication1: "19",
            indication2: "19",
          },
          {
            date: "Ноябрь, 2023",
            indication1: "19",
            indication2: "19",
          },
          {
            date: "Декабрь, 2023",
            indication1: "19",
            indication2: "19",
          },
        ];
      }

      const response = await DataAnalysisApi.getList(urlPart);

      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const downloadXlsx = createAsyncThunk<void, string, { state: RootState }>(
  `${sliceName}/downloadXlsx`,
  async (urlPart, thunkAPI) => {
    try {
      const response = await DataAnalysisApi.downloadFile(urlPart);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const fileName = `${urlPart}.xlsx`;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const dataAnalysisTableViewSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTableViewData.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getTableViewData.fulfilled, (state, action) => {
      state.data = action.payload.map((x) => ({ ...x, id: uuidv4() }));
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getTableViewData.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const dataAnalysisTableViewReducer = dataAnalysisTableViewSlice.reducer;
export const dataAnalysisTableViewActions = dataAnalysisTableViewSlice.actions;
