import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../../../hooks/useTypedSelector";
import Chart from "react-apexcharts";
import { ApexLocales } from "../../../../../../constants/ApexChartsSettings";
import { ApexOptions } from "apexcharts";
import { useEffect } from "react";
import { getConsumptionVolumeValues } from "../../../../../../store/slices/zonesDistribution/zoneDistributionSlice";
import { LoadingState } from "../../../../../../types/loadingState";
import Loader from "../../../../../../components/uiComponents/Animation/Animation";

interface ConsumptionVolumeChartProps {
  statisticsSubject: string;
  period: string;
  zone: string;
  isRubles: string;
  isVisible?: boolean;
}

export const ConsumptionVolumeChart = (props: ConsumptionVolumeChartProps) => {
  const dispatch = useDispatch<any>();

  const chartData = useTypedSelector((state) => state.zoneDistribution.chartData);
  const isLoading = useTypedSelector((state) => state.zoneDistribution.isLoading);
  const isVisibleExternal = props.isVisible ?? true;

  const chartLabelFormatter = (_: number, options: any) => {
    const data = chartData?.series[options.seriesIndex].data;
    const value = data ? data[options.dataPointIndex] : 0;
    return value.toFixed(2);
  };

  const chartSettings: ApexOptions = {
    chart: {
      type: "bar",
      width: "100%",

      stacked: true,
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      colors: ["#1599D7"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 2,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "11px",
      },
      formatter: chartLabelFormatter,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: chartData?.categories ?? [],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: chartLabelFormatter,
      },
    },
    series: [],
  };

  const recalculateChartData = (series: ApexAxisChartSeries) => {
    let sum = 0;
    const dataLength = series.length > 0 ? series[0].data.length : 0;
    for (let i = 0; i < dataLength; i++) {
      let s = 0;
      for (let item of series) {
        s += (item.data as number[])[i];
      }
      sum = Math.max(sum, s);
    }

    const chartHeightInPx = 150;
    const minHeigthInPx = 30;
    const minHeight = (minHeigthInPx * sum) / chartHeightInPx;

    for (let item of series) {
      const updatedValues = [];
      for (let seriesDataItem of item.data as number[]) {
        if (seriesDataItem > 0 && seriesDataItem < minHeight) {
          updatedValues.push(minHeight);
        } else {
          updatedValues.push(seriesDataItem);
        }
      }
      item.data = updatedValues;
    }

    return series;
  };

  const recalculatedSeries = chartData && recalculateChartData(JSON.parse(JSON.stringify(chartData?.series)));

  useEffect(() => {
    dispatch(getConsumptionVolumeValues(props));
  }, [props.statisticsSubject, props.period, props.zone, props.isRubles]);

  if (isLoading === LoadingState.PENDING || !isVisibleExternal) {
    return <Loader />;
  }

  return (
    <>
      {chartData && (
        <Chart height={270} width={"100%"} options={chartSettings} series={recalculatedSeries} type="bar" />
      )}
    </>
  );
};
