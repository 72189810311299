import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../reducers";
import { objectInfoApi } from "../../../pages/objectInfo/api/objectInfoApi";
import { FixedPeriod } from "../../../models/common/filterPeriod";
import { DeviceItemDto } from "../../../models/devices/deviceItemDto";
import { DeviceIndicatorDto } from "../../../models/devices/deviceIndicatorDto";
import { DeviceIndicationsDto } from "../../../models/devices/deviceIndicationsDto";
import { DeviceIndicationNormDto } from "../../../models/devices/deviceIndicationNormDto";
import { LoadingState } from "../../../types/loadingState";
import { DatePeriodDto } from "../../../models/common/datePeriodDto";
import moment from "moment";

const sliceName = "deviceInfo";

interface InitialState {
  devices: DeviceItemDto[];
  deviceSignals: DeviceIndicatorDto[];

  filter: {
    deviceGuid?: string;
    signalGuid?: string;
    year: number;
    fixedPeriod: FixedPeriod | null;
    from: Date;
    to: Date;
  };

  norms?: DeviceIndicationNormDto;
  signalValues: { dateTime: Date; value: number }[];
  unit?: string;

  isLoading: LoadingState;
}

const initialState: InitialState = {
  devices: [],
  deviceSignals: [],

  filter: {
    year: 2024,
    from: moment().startOf("month").toDate(),
    to: new Date(),
    fixedPeriod: FixedPeriod.Year,
  },

  signalValues: [],

  isLoading: LoadingState.EMPTY,
};

export const getDevices = createAsyncThunk<DeviceItemDto[], string, { state: RootState }>(
  `${sliceName}/getDevices`,
  async (objectGuid, thunkAPI) => {
    try {
      const response = await objectInfoApi.getDevices(objectGuid);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const getDeviceSignals = createAsyncThunk<
  DeviceIndicatorDto[],
  { objectGuid: string; deviceGuid: string },
  { state: RootState }
>(`${sliceName}/getDeviceSignals`, async (params, thunkAPI) => {
  try {
    const { objectGuid, deviceGuid } = params;
    const response = await objectInfoApi.getDeviceSignals(objectGuid, deviceGuid);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
  }
});

export const getSignalValues = createAsyncThunk<DeviceIndicationsDto, undefined, { state: RootState }>(
  `${sliceName}/getSignalValues`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState().deviceInfo;
      if (state.filter.signalGuid) {
        let datePeriod: DatePeriodDto = { start: state.filter.from, end: state.filter.to };
        const currentYear = new Date().getFullYear();
        if (state.filter.fixedPeriod !== null) {
          datePeriod = getDatesOfFixedPeriod(state.filter.fixedPeriod);
        }
        if (state.filter.year !== currentYear) {
          datePeriod.start = moment().set("year", state.filter.year).startOf("year").toDate();
          datePeriod.end = moment().set("year", state.filter.year).endOf("year").toDate();
        }

        const response = await objectInfoApi.getSignalValues({ indicationId: state.filter.signalGuid, datePeriod });

        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const getDatesOfFixedPeriod = (fixedPeriod: FixedPeriod): DatePeriodDto => {
  switch (fixedPeriod) {
    case FixedPeriod.Year: {
      return {
        start: moment().add(-1, "year").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      };
    }
    case FixedPeriod.Half: {
      return {
        start: moment().add(-6, "month").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      };
    }
    case FixedPeriod.Quarter:
    default: {
      return {
        start: moment().add(-3, "month").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      };
    }
  }
};

const deviceInfoSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setFilterValue: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDevices.fulfilled, (state, action) => {
      state.devices = action.payload;
    });
    builder.addCase(getDeviceSignals.fulfilled, (state, action) => {
      state.deviceSignals = action.payload;
    });

    builder.addCase(getSignalValues.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getSignalValues.fulfilled, (state, action) => {
      if (action.payload) {
        state.norms = action.payload.limit;
        state.unit = action.payload.unit;
        state.signalValues = action.payload.indications.map((x) => x.indication);
        state.isLoading = LoadingState.SUCCESS;
      }
    });
    builder.addCase(getSignalValues.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const deviceInfoReducer = deviceInfoSlice.reducer;
export const { setInitialState, setFilterValue } = deviceInfoSlice.actions;
