import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Checkbox } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { DefaultPageSizeOptions, DefaultPaginationSettings } from "../../../../models/common/paginationSettings";
import {
  getAccessiblePages,
  getUserByGuid,
  saveAccessiblePages,
  setInitialState,
  updateTableDataItem,
} from "../../../../store/slices/userManagement/usersAccessEditorSlice";
import { LoadingState } from "../../../../types/loadingState";
import { enqueueSnackbar } from "notistack";

interface IProps {
  onShowUsersTableClick?: () => void;
}

export const UserAccessEditor = ({ onShowUsersTableClick }: IProps) => {
  const dispatch = useDispatch<any>();

  const currentUserData = useTypedSelector((state) => state.userAccessEditor.currentUserData);

  const tableData = useTypedSelector((state) => state.userAccessEditor.tableData);

  const isLoading = useTypedSelector((state) => state.userAccessEditor.isLoading);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Доступ",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ paddingLeft: `${params.row.paddingValue.toString() * 30}px` }}>{params.value}</Box>
      ),
    },
    {
      field: "accessible",
      headerName: currentUserData?.role.name,
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          dispatch(updateTableDataItem({ id: params.id, value: checked }));
        };
        return <Checkbox checked={params.value} onChange={handleChange} />;
      },
    },
  ];

  const handleSaveClick = async () => {
    const res = await dispatch(saveAccessiblePages());
    if (res.error) {
      enqueueSnackbar(res.payload, { variant: "error" });
    } else {
      onShowUsersTableClick && onShowUsersTableClick();
    }
  };

  const handleCancelClick = () => {
    onShowUsersTableClick && onShowUsersTableClick();
  };

  useEffect(() => {
    if (currentUserData !== null) {
      dispatch(getAccessiblePages());
    }
  }, [currentUserData]);

  useEffect(() => {
    dispatch(getUserByGuid());
    return () => {
      dispatch(setInitialState());
    };
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 100%" }}>
      <Box sx={{ display: "flex", gap: "16px", width: "100%", justifyContent: "flex-end" }}>
        <Button onClick={handleSaveClick} startIcon={<CheckIcon />} disabled={isLoading === LoadingState.PENDING}>
          Сохранить изменения
        </Button>
        <Button onClick={handleCancelClick} startIcon={<CloseIcon />} disabled={isLoading === LoadingState.PENDING}>
          Отмена
        </Button>
      </Box>
      <BaseTable
        data={tableData}
        columns={columns}
        useClientPagination={true}
        pageSize={DefaultPaginationSettings.pageSize}
        pageSizeOptions={DefaultPageSizeOptions}
        isLoading={isLoading === LoadingState.PENDING}
      />
    </Box>
  );
};
