import { GridColDef, GridColType, GridValueFormatterParams } from "@mui/x-data-grid";
import moment from "moment";

export const getStandardColumnParams = (column: GridColDef) => {
  const dateParams = {
    valueFormatter: valueFormatter(column.type),
  };

  const isNumber = column.type === "number";
  const numberParams = {
    align: isNumber ? "left" : undefined,
    headerAlign: isNumber ? "left" : undefined,
  };

  const baseParams = {
    flex: 1,
  };

  return {
    ...baseParams,
    ...dateParams,
    ...numberParams,
  };
};

const valueFormatter = (type?: GridColType) => {
  switch (type) {
    case "date":
      return formatDate;
    case "dateTime":
      return formatDateTime;
    default:
      return;
  }
};

const formatDate = (params: GridValueFormatterParams<any>) => {
  const date = moment(params?.value);
  if (date.isValid()) {
    return date.format("DD.MM.YYYY");
  }

  return "";
};

const formatDateTime = (params: GridValueFormatterParams<any>) => {
  const date = moment(params?.value);
  if (date.isValid()) {
    return date.format("DD.MM.YYYY HH:mm");
  }

  return "";
};
