import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EventInfoTableDTO } from "../../../models/eventInfo/EventInfoTableDTO";
import { monitoringEventApi } from "../../../pages/monitoring/api/monitoringEventApi";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";
import { v4 as uuidv4 } from "uuid";
import { EventItemDto } from "../../../models/events/eventItemDto";
import { EventsDataDto, EventsDataPageDto } from "../../../models/events/eventsData";
import { EventInfoFixedPeriod } from "../../../models/eventInfo/eventInfoFixedPeriod";
import { DatePeriodDto } from "../../../models/common/datePeriodDto";
import moment from "moment";
import { DefaultPaginationSettings, PaginationSettings } from "../../../models/common/paginationSettings";

const sliceName = "monitoringEventInfo";

interface InitialState {
  eventsData?: EventsDataPageDto;
  paginationSettings: PaginationSettings;
  isLoadingEventsData: LoadingState;

  eventInfoTableData: EventInfoTableDTO[];
  isLoadingTableData: LoadingState;

  selectedEventItem?: EventItemDto;

  filter: {
    fixedPeriod: EventInfoFixedPeriod | null;
    from: Date;
    to: Date;
  };
}

const initialState: InitialState = {
  eventInfoTableData: [],
  paginationSettings: DefaultPaginationSettings,

  isLoadingEventsData: LoadingState.EMPTY,
  isLoadingTableData: LoadingState.EMPTY,

  filter: {
    fixedPeriod: EventInfoFixedPeriod.Month,
    from: new Date(),
    to: new Date(),
  },
};

export const getEventsDataBySignalEventGuid = createAsyncThunk<
  EventsDataPageDto,
  { signalEventGuid: string },
  { state: RootState }
>(`${sliceName}/getEventsDataBySignalEventGuid`, async (params, thunkAPI) => {
  try {
    const { signalEventGuid } = params;
    const state = thunkAPI.getState().monitoringEventInfo;
    const response = await monitoringEventApi.getEventsDataBySignalEventGuid(
      signalEventGuid,
      state.paginationSettings.currentPage,
      state.paginationSettings.pageSize
    );
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
  }
});

export const getMonitoringEventInfoTableData = createAsyncThunk<
  EventInfoTableDTO[],
  { signalEventGuid: string },
  { state: RootState }
>(`${sliceName}/getMonitoringEventsData`, async (params, thunkAPI) => {
  try {
    const state = thunkAPI.getState().monitoringEventInfo;
    let datePeriod: DatePeriodDto = { start: state.filter.from, end: state.filter.to };
    if (state.filter.fixedPeriod !== null) {
      datePeriod = getDatesOfFixedPeriod(state.filter.fixedPeriod);
    }

    const response = await monitoringEventApi.getTableEventValues(params.signalEventGuid, datePeriod);

    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
  }
});

const getDatesOfFixedPeriod = (fixedPeriod: EventInfoFixedPeriod): DatePeriodDto => {
  switch (fixedPeriod) {
    case EventInfoFixedPeriod.Hour: {
      return {
        start: moment().add(-1, "hour").toDate(),
        end: new Date(),
      };
    }
    case EventInfoFixedPeriod.Day: {
      return {
        start: moment().add(-1, "day").toDate(),
        end: new Date(),
      };
    }
    case EventInfoFixedPeriod.Month:
    default: {
      return {
        start: moment().add(-1, "month").startOf("day").toDate(),
        end: moment().endOf("day").toDate(),
      };
    }
  }
};

const monitoringEventInfoSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: (state) => {
      state.eventInfoTableData = [];
    },
    setSelectedEventItem: (state, action: PayloadAction<EventItemDto>) => {
      state.selectedEventItem = action.payload;
    },
    setFilterValue: (state, action) => {
      state.filter = action.payload;
    },
    setPaginationSettings: (state, action) => {
      state.paginationSettings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEventsDataBySignalEventGuid.pending, (state) => {
      state.isLoadingEventsData = LoadingState.PENDING;
    });
    builder.addCase(getEventsDataBySignalEventGuid.fulfilled, (state, action) => {
      state.eventsData = action.payload;
      state.isLoadingEventsData = LoadingState.SUCCESS;
    });
    builder.addCase(getEventsDataBySignalEventGuid.rejected, (state, action) => {
      state.isLoadingEventsData = LoadingState.ERROR;
    });
    builder.addCase(getMonitoringEventInfoTableData.pending, (state) => {
      state.isLoadingTableData = LoadingState.PENDING;
    });
    builder.addCase(getMonitoringEventInfoTableData.fulfilled, (state, action: PayloadAction<EventInfoTableDTO[]>) => {
      state.eventInfoTableData = action.payload.map((i) => ({
        ...i,
        id: uuidv4(),
      }));
      state.isLoadingTableData = LoadingState.SUCCESS;
    });
    builder.addCase(getMonitoringEventInfoTableData.rejected, (state) => {
      state.isLoadingTableData = LoadingState.ERROR;
    });
  },
});

export const monitoringEventInfoReducer = monitoringEventInfoSlice.reducer;
export const { setInitialState, setFilterValue, setSelectedEventItem, setPaginationSettings } =
  monitoringEventInfoSlice.actions;
