import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { downloadXlsx } from "../../../../store/slices/dataAnalysis/dataAnalysisTableViewSlice";
import { isNullOrEmpty } from "../../../../utilities/string/isNullOrEmpty";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";

export interface ExportDataProps {
  urlPart?: string;
}

export const ExportData = (props: ExportDataProps) => {
  const dispatch = useDispatch<any>();

  const handleDownload = async () => {
	props.urlPart && dispatch(downloadXlsx(props.urlPart));
  };

  return (
    <Button disabled={isNullOrEmpty(props.urlPart)} onClick={handleDownload}>
      Экспортировать
    </Button>
  );
};
