import { routeUrls } from "../../../routes/routeUrls";

export const navigationMenuConfig = [
  {
    name: "Мониторинг",
    link: routeUrls.monitoring,
  },
  {
    name: "Информация по объекту",
    link: routeUrls.objectInfoPage,
  },
  {
    name: "Баланс ВС и ВО",
    link: routeUrls.balance,
  },
  {
    name: "Анализ данных и прогнозирование",
    link: routeUrls.dataAnalysis,
    fullAccess: true,
  },
  {
    name: "Управление доступом",
    link: routeUrls.users,
  },
];
