import { BaseTable } from '../../../../components/baseTable/BaseTable';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { BaseTableColumnDef } from '../../../../models/baseTable/baseTableColumns';

export const DeviceSignalsTable = () => {
  const unit = useTypedSelector((state) => state.deviceInfo.unit);
  const signals = useTypedSelector((state) => state.deviceInfo.signalValues);

  const tableColumns: BaseTableColumnDef[] = [
    {
      headerName: 'Дата и время',
      field: 'dateTime',
      type: 'dateTime',
    },
    {
      headerName: `Показание${unit ? ', ' + unit : ''}`,
      field: 'value',
    },
  ];

  return <BaseTable data={signals.map((x, index) => ({ ...x, id: index.toString() }))} columns={tableColumns} useClientPagination />;
};
