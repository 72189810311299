import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { getObjectInfoDeviceEvents, setDeviceEventsInitialState } from '../../../../store/slices/objects/objectInfoSlice';
import { LoadingState } from '../../../../types/loadingState';
import { DeviceEventsTable } from '../deviceEventsTable/deviceEventsTable';

interface Props {
  onDeviceSelect: (guid: string) => void;
}

export const ObjectInfoDeviceEventsTable = ({ onDeviceSelect }: Props) => {
  const dispatch = useDispatch<any>();

  const deviceEvents = useTypedSelector((state) => state.objectInfo.deviceEvents);
  const selectedObjectGuid = useTypedSelector((state) => state.objectInfo.selectedObjectGuid);

  const isLoading = useTypedSelector((state) => state.objectInfo.isDeviceEventsLoading);

  const handleRowSelect = (guid: string) => {
    onDeviceSelect(guid);
  };

  useEffect(() => {
    return () => {
      dispatch(setDeviceEventsInitialState());
    };
  }, []);

  useEffect(() => {
    if (selectedObjectGuid) {
      dispatch(getObjectInfoDeviceEvents({ guid: selectedObjectGuid }));
    }
  }, [selectedObjectGuid]);

  return <DeviceEventsTable data={deviceEvents} isLoading={isLoading === LoadingState.PENDING} onRowSelect={handleRowSelect} />;
};
