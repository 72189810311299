import { Typography } from "@mui/material";

export const HeaderText = (props: { children: React.ReactNode }) => {
  return (
    <Typography sx={styles.header} variant="h1">
      {props.children}
    </Typography>
  );
};

const styles = {
  header: {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "34px",
    lineHeight: "46px",
    textAlign: "center",
    letterSpacing: "0.1px",
    color: "#1478BE",
    margin: "15px 0 30px 0",
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
};
