import { Navigate, Outlet } from "react-router-dom";
import "./Balance.scss";
import { TabsWithRoutes } from "../../components/tabsWithRoutes/tabsWithRoutes";
import { BalanceTabConfig } from "./balanceTabConfig";
import { Box } from "@mui/material";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { AccessService } from "../../utilities/access/accessService";
import { routeUrls } from "../../routes/routeUrls";
import { Forbidden } from "../common/forbidden/forbidden";

function Balance() {
  const userInfo = useTypedSelector(state => state.account.userInfo);
  const accessList = userInfo?.accessList || [];

  const filteredTabsConfig = BalanceTabConfig.filter(x => AccessService.hasAccess(x.route, accessList));
  if (filteredTabsConfig.length === 1 && filteredTabsConfig[0].route === routeUrls.zones) {
    return <Navigate to="zones/XVI" />
  }

  if (filteredTabsConfig.length === 0) {
    return <Forbidden />
  }

  return (
    <Box>
      <TabsWithRoutes config={filteredTabsConfig} />
      <Outlet />
    </Box>
  );
}

export default Balance;
