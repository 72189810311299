import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { useState, useEffect } from "react";

type CustomDatePickerProps = Omit<DatePickerProps<Moment>, "value" | "onChange"> & {
  dateValue: Moment | null;
  onDateValueChange: (value: Moment | null) => void;
};

const debounceTime = 1000;

export const CustomDatePicker = (props: CustomDatePickerProps) => {
  const [value, setValue] = useState<Moment | null>(null);

  useEffect(() => {
    if (value) {
      const timeoutId = setTimeout(() => {
        props.onDateValueChange(value);
      }, debounceTime);

      return () => clearTimeout(timeoutId);
    }
  }, [value]);

  return (
    <DatePicker
      sx={{ minWidth: 200 }}
      slotProps={{ textField: { size: "small" } }}
      {...props}
      value={props.dateValue}
      onChange={(value) => setValue(value)}
    />
  );
};
