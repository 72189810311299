import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { GetZoneConsumptionPrediction } from "../../../../BalansApis";
import Animation from "../../../../../../components/uiComponents/Animation/Animation";
import { ApexLocales } from "../../../../../../constants/ApexChartsSettings";
import { ApexOptions } from "apexcharts";

interface MlDataChartProps {
  period: string;
  zoneNumber: string;
  statisticSubject: string;
  isRubles: string;
  isVisibleExternal?: boolean;
}

export const MlDataChart = ({
  period,
  zoneNumber,
  statisticSubject,
  isRubles,
  isVisibleExternal = true,
}: MlDataChartProps) => {
  const [predictionChart, setPredictionChart] = useState<ApexOptions>({
    series: [
      {
        type: "rangeArea",
        name: "Прогноз",
        color: "#ececec",
        data: [
          {
            x: "2024-07-27T00:00:00Z",
            y: [50000, 60000],
          },
          {
            x: "2025-07-27T00:00:00Z",
            y: [60000, 70000],
          },
          {
            x: "2026-07-27T00:00:00Z",
            y: [40000, 50000],
          },
        ],
      },

      {
        type: "line",
        name: "Факт",
        color: "#1478BE",
        data: [
          {
            x: "2019-01-01T00:00:00Z",
            y: 14480,
          },
          {
            x: "2020-01-01T00:00:00Z",
            y: 53543.82,
          },
          {
            x: "2021-01-01T00:00:00Z",
            y: 47801.24,
          },
          {
            x: "2022-01-01T00:00:00Z",
            y: 63002.56,
          },
          {
            x: "2023-01-01T00:00:00Z",
            y: 35880.57,
          },
        ],
      },
    ],
    chart: {
      height: 350,
      type: "rangeArea",
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },
    //colors: ["#d4526e", "#33b2df"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: [1, 1],
    },
    forecastDataPoints: {
      count: 1,
    },
    stroke: {
      curve: "straight",
      width: [2, 2],
    },
    legend: {
      show: false,
      inverseOrder: true,
    },
    // title: {
    //   text: "Прогнозирование потребления",
    // },
    markers: {
      hover: {
        sizeOffset: 5,
      },
      size: 4,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "тыс. м. куб",
      },
    },
  });
  const [isLoading, setIsLoading] = useState(true);

  // Запрос для графика прогнозирования потребления (Сбыт.Прогнозирование_потребления)
  async function getConsumptionPrediction() {
    try {
      const response = await GetZoneConsumptionPrediction(period, zoneNumber, statisticSubject, isRubles);
      setPredictionChart({
        ...predictionChart,
        series: response,
        yaxis: {
          title: {
            text: isRubles === "true" ? "руб." : "тыс. м. куб",
          },
        },
        forecastDataPoints: {
          ...predictionChart.forecastDataPoints,
          count: response.length > 1 ? response[1].data.length - 1 : 0,
        },
      });

      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getConsumptionPrediction();
  }, [period, zoneNumber, statisticSubject, isRubles]);

  return (
    <>
      {/* Второй график с легендой */}
      <div style={{ whiteSpace: "nowrap" }} className="headerH2">
        Прогнозирование потребления
      </div>
      <div style={{ width: "100%", height: "270px" }}>
        {isLoading || !isVisibleExternal ? (
          <Animation></Animation>
        ) : (
          <Chart
            height={270}
            width={"100%"}
            options={predictionChart}
            series={predictionChart.series}
            type="rangeArea"
          />
        )}
      </div>
    </>
  );
};
