import { PassportDataTableItemDto } from './../../../models/passportData/passportDataTableItemDto';
import { AxiosResponse } from "axios";
import axios, { backURLs } from "../../../core/APIs";
import { SettingsTableData, SettingsTableDataItem } from "../../../models/settingsTable/settingsTableData";

export const settingsTableApi = {
  getTableData(tableName: string, from: number, size: number): Promise<AxiosResponse<SettingsTableData>> {
    return axios.get(`${backURLs.getSettingsTableUrl(tableName)}/${from}/${size}`);
  },

  createTableData(tableName: string, params: SettingsTableDataItem): Promise<AxiosResponse<void>> {
    return axios.post(`${backURLs.getSettingsTableUrl(tableName)}`, {}, { params });
  },

  updateTableData(tableName: string, params: SettingsTableDataItem): Promise<AxiosResponse<void>> {
    return axios.put(`${backURLs.getSettingsTableUrl(tableName)}`, {}, { params });
  },

  deleteTableData(tableName: string, params: SettingsTableDataItem): Promise<AxiosResponse<void>> {
    return axios.delete(`${backURLs.getSettingsTableUrl(tableName)}`, { params });
  },

  downloadTableDataCsv(tableName: string): Promise<AxiosResponse<Blob>> {
    return axios.get(`${backURLs.getSettingsTableUrl(tableName)}/export-csv`, {responseType: 'blob'});
  },

  uploadTableDataCsv(tableName: string, data: FormData): Promise<AxiosResponse<void>> {
    return axios.post(`${backURLs.getSettingsTableUrl(tableName)}/import-csv`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  deleteElectricityConsumedValue(guid: string): Promise<AxiosResponse<void>> {
    return axios.delete(`${backURLs.getSettingsTableUrl("electricity-consumed")}/${guid}`);
  },

  deleteElectricityConsumedStandardsValue(guid: string): Promise<AxiosResponse<void>> {
    return axios.delete(`${backURLs.getSettingsTableUrl("electricity-consumed-standards")}/${guid}`);
  },

  deletePaidWaterValue(guid: string): Promise<AxiosResponse<void>> {
    return axios.delete(`${backURLs.getSettingsTableUrl("paid-water-data-input")}/${guid}`);
  },

  getPassportTableData(): Promise<AxiosResponse<PassportDataTableItemDto[]>> {
    return axios.get(`${backURLs.passportTable}/table-data`);
  },

  exportPassportData(): Promise<AxiosResponse<Blob>> {
    return axios.get(`${backURLs.passportTable}/table-data-csv`, { responseType: "blob" });
  },
};
