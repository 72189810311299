import AuthAxios, { backURLs } from "../../core/APIs";
import { AuthenticationDataDto } from "../../models/account/authenticationDataDto";
import { ForgotPasswordDataDto } from "../../models/account/forgotPasswordDataDto";

export const accountApi = {
  autenticate: (form: AuthenticationDataDto) => {
    return AuthAxios.post(`${backURLs.account}/token`, form);
  },
  sendEmailWithcredentials: (form: ForgotPasswordDataDto) => {
    return AuthAxios.post(`${backURLs.account}/restore-password`, null, {params: form});
  },
  getCurrentUserInfo: () => {
    return AuthAxios.get(`${backURLs.account}/user-info`);
  }
};
