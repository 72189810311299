import React from "react";
import { BlockInfoDataDetail } from "../BalansApis";
import { Box } from "@mui/system";

interface IProps {
  details: BlockInfoDataDetail[];
}

export const BlockInfoItemDetails = ({ details }: IProps) => {
  return (
    <Box sx={styles.container}>
      {details.map((detail) => (
        <Box sx={styles.row}>
          {detail.name}: {detail.value}
        </Box>
      ))}
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    paddingLeft: "36px",
  },
  row: {
    fontSize: "12px",
  },
};
