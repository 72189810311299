import { RootState } from "./../../reducers/index";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DeviceEventDTO } from "../../../models/deviceEvents/deviceEventDTO";
import { LoadingState } from "../../../types/loadingState";
import { objectInfoApi } from "../../../pages/objectInfo/api/objectInfoApi";
import { DeviceEventDetailDTO } from "../../../models/deviceEventsDetail/deviceEventDetailDTO";
import { ObjectDto } from "../../../models/objects/objectListItemDto";

const sliceName = "objectInfo";

interface InitialState {
  objects: ObjectDto[];
  isObjectsLoading: LoadingState;
  selectedObjectGuid: string | null;

  selectedObjectDTO?: ObjectDto;
  deviceEvents: DeviceEventDTO[];
  deviceEventsDetails: DeviceEventDetailDTO[];
  image: Blob | null;

  isDeviceEventsLoading: LoadingState;
  isDeviceEventsDetailLoading: LoadingState;
  isDeviceImageLoading: LoadingState;
}

const initialState: InitialState = {
  objects: [],
  isObjectsLoading: LoadingState.EMPTY,
  selectedObjectGuid: null,

  deviceEvents: [],
  deviceEventsDetails: [],

  image: null,

  isDeviceEventsLoading: LoadingState.EMPTY,
  isDeviceEventsDetailLoading: LoadingState.EMPTY,
  isDeviceImageLoading: LoadingState.EMPTY,
};

export const getObjects = createAsyncThunk<ObjectDto[], undefined, { state: RootState }>(
  `${sliceName}/getObjects`,
  async (_, thunkAPI) => {
    try {
      const response = await objectInfoApi.getObjects();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const getObjectInfoDeviceEvents = createAsyncThunk<DeviceEventDTO[], { guid: string }, { state: RootState }>(
  `${sliceName}/getObjectInfoDeviceEvents`,
  async (params, thunkAPI) => {
    try {
      const { guid } = params;
      const response = await objectInfoApi.getDeviceEvents(guid);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const getObjectInfoDeviceEventsDetail = createAsyncThunk<
  DeviceEventDetailDTO[],
  { guid: string },
  { state: RootState }
>(`${sliceName}/getObjectInfoDeviceEventsDetail`, async (params, thunkAPI) => {
  try {
    const { guid } = params;
    const response = await objectInfoApi.getObjectEvents(guid);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
  }
});

export const getObjectImage = createAsyncThunk<Blob, { guid: string }, { state: RootState }>(
  `${sliceName}/getObjectImage`,
  async (params, thunkAPI) => {
    try {
      const { guid } = params;
      const response = await objectInfoApi.getObjectImage(guid);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const getDeviceImage = createAsyncThunk<Blob, { guid: string }, { state: RootState }>(
  `${sliceName}/getDeviceImage`,
  async (params, thunkAPI) => {
    try {
      const { guid } = params;
      const response = await objectInfoApi.getDeviceImage(guid);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const objectInfoSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setSelectedObjectGuid: (state, action) => {
      state.selectedObjectGuid = action.payload;
    },
    setDeviceEventsInitialState: (state) => {
      state.deviceEvents = [];
      state.isDeviceEventsLoading = LoadingState.EMPTY;
    },
    setDeviceEventsDetailInitialState: (state) => {
      state.deviceEventsDetails = [];
      state.isDeviceEventsDetailLoading = LoadingState.EMPTY;
    },
    setDeviceImageInitialState: (state) => {
      state.image = null;
      state.isDeviceImageLoading = LoadingState.EMPTY;
    },
    setSelectedObjectDTO: (state, action: PayloadAction<ObjectDto>) => {
      state.selectedObjectDTO = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getObjects.pending, (state) => {
      state.isObjectsLoading = LoadingState.PENDING;
    });
    builder.addCase(getObjects.fulfilled, (state, action) => {
      state.objects = action.payload;
      state.isObjectsLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getObjects.rejected, (state) => {
      state.isObjectsLoading = LoadingState.ERROR;
    });

    builder.addCase(getObjectInfoDeviceEvents.pending, (state) => {
      state.isDeviceEventsLoading = LoadingState.PENDING;
    });
    builder.addCase(getObjectInfoDeviceEvents.fulfilled, (state, action) => {
      state.deviceEvents = action.payload;
      state.isDeviceEventsLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getObjectInfoDeviceEvents.rejected, (state) => {
      state.isDeviceEventsLoading = LoadingState.ERROR;
    });

    builder.addCase(getObjectInfoDeviceEventsDetail.pending, (state) => {
      state.isDeviceEventsDetailLoading = LoadingState.PENDING;
    });
    builder.addCase(getObjectInfoDeviceEventsDetail.fulfilled, (state, action) => {
      state.deviceEventsDetails = action.payload.map((i) => ({
        ...i,
        id: i.guid as string,
      }));
      state.isDeviceEventsDetailLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getObjectInfoDeviceEventsDetail.rejected, (state) => {
      state.isDeviceEventsDetailLoading = LoadingState.ERROR;
    });

    builder.addCase(getObjectImage.pending, (state) => {
      state.isDeviceImageLoading = LoadingState.PENDING;
    });
    builder.addCase(getObjectImage.fulfilled, (state, action) => {
      state.image = action.payload;
      state.isDeviceImageLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getObjectImage.rejected, (state) => {
      state.isDeviceImageLoading = LoadingState.ERROR;
      state.image = null;
    });

    builder.addCase(getDeviceImage.pending, (state) => {
      state.isDeviceImageLoading = LoadingState.PENDING;
    });
    builder.addCase(getDeviceImage.fulfilled, (state, action) => {
      state.image = action.payload;
      state.isDeviceImageLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getDeviceImage.rejected, (state) => {
      state.isDeviceImageLoading = LoadingState.ERROR;
      state.image = null;
    });
  },
});

export const objectInfoReducer = objectInfoSlice.reducer;
export const {
  setInitialState,
  setSelectedObjectGuid,
  setDeviceEventsInitialState,
  setDeviceEventsDetailInitialState,
  setDeviceImageInitialState,
  setSelectedObjectDTO,
} = objectInfoSlice.actions;
