import { Box } from "@mui/material";
import { HeaderText } from "../../../components/headerText/headerText";
import { TextMessage } from "../../../components/textMessage/textMessage";

export const Forbidden = () => {
  return (
    <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <HeaderText>Доступ ограничен</HeaderText>
        <TextMessage>
          Не хватает прав для получения доступа к ресурсам. Обратитесь к администратору системы.
        </TextMessage>
      </Box>
    </Box>
  );
};
