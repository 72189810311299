import { IconButton, Menu, MenuItem } from "@mui/material";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/common/settings.svg";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../../store/slices/login/loginSlice";
import { useNavigate } from "react-router-dom";

export const UserMenu = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    await dispatch(logout());
    navigate("/login");
  };

  return (
    <div>
      <IconButton onClick={handleOpenMenu} id="user-menu-button">
        <SettingsIcon />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose} disableScrollLock>
        <MenuItem sx={styles.menuItem} onClick={handleLogout}>
          Выйти
        </MenuItem>
      </Menu>
    </div>
  );
};

const styles = {
  menuItem: {
    minWidth: "150px",
    maxWidth: "300px",
    display: "flex",
    justifyContent: "center",

    color: "#1599D7",
    border: "none",
    fontWeight: "bolder",
    padding: "4px",
    cursor: "pointer",

    "&:hover": {
      color: "#1478BE",
    },
  },
};
