import { Typography } from "@mui/material";

export const TextMessage = (props: { children: React.ReactNode }) => {
  return (
    <Typography sx={styles.text} variant="body1">
      {props.children}
    </Typography>
  );
};

const styles = {
  text: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26px" /* or 144% */,
    textAlign: "center",
    letterSpacing: "-0.5px",
    color: "#9DAFBD",
    paddingBottom: "50px",
    margin: "15px",
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
};
