import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../types/loadingState";
import { RoleSelectItemDto } from "../../../models/userManagement/roleSelectItemDto";
import { SelectItem } from "../../../models/common/selectItem";
import { UserEditorDto } from "../../../models/userManagement/userEditorDto";
import { usersApi } from "../../../pages/userManagement/api/usersApi";
import { User } from "../../../pages/userManagement/types/usersTypes";

const sliceName = "userManagement";

interface IInitialState {
  roleSelectItems: SelectItem[];
  userEditorDto: UserEditorDto;

  isLoading: LoadingState;
}

const initialState: IInitialState = {
  roleSelectItems: [],
  userEditorDto: {
    lastName: "",
    firstName: "",
    middleName: "",
    email: "",
    position: "",
    department: "",
    roleGuid: "",
    roleId: "",
    district: "",
  },

  isLoading: LoadingState.EMPTY,
};

export const getRoleSelectItems = createAsyncThunk<RoleSelectItemDto[]>(
  `${sliceName}/getRoleSelectItems`,
  async (_, thunkAPI) => {
    try {
      const response = await usersApi.getRoleSelectItems();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const getUser = createAsyncThunk<User, { id: string }>(`${sliceName}/getUser`, async (params, thunkAPI) => {
  const { id } = params;
  try {
    const response = await usersApi.getUserByGuid(id);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
  }
});

export const createUser = createAsyncThunk<string, { data: UserEditorDto }>(
  `${sliceName}/createUser`,
  async (params, thunkAPI) => {
    const { data } = params;
    try {
      const response = await usersApi.createUser(data);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data || "Ошибка соединения. Повторите попытку");
    }
  }
);

export const editUser = createAsyncThunk<void, { id: string; data: UserEditorDto }>(
  `${sliceName}/editUser`,
  async (params, thunkAPI) => {
    const { id, data } = params;
    try {
      const response = await usersApi.editUser(id, data);
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data || "Ошибка соединения. Повторите попытку");
    }
  }
);

const createEditUserSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRoleSelectItems.fulfilled, (state, action) => {
      state.roleSelectItems = action.payload.map((x) => ({ value: x.id, label: x.name }));
    });

    builder.addCase(getUser.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.userEditorDto = {
        ...initialState.userEditorDto,
        firstName: action.payload.firstName,
        middleName: action.payload.middleName,
        lastName: action.payload.lastName,
        district: action.payload.district || "",
        position: action.payload.position,
        department: action.payload.department,
        roleGuid: action.payload.role.id,
        roleId: action.payload.role.id,
      };
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });

    builder.addCase(createUser.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(createUser.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });

    builder.addCase(editUser.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(editUser.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const createEditUserReducer = createEditUserSlice.reducer;
export const { setInitialState } = createEditUserSlice.actions;
