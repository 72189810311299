import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useDispatch } from "react-redux";
import Animation from "../../../components/uiComponents/Animation/Animation";
import CustomButton from "../../../components/uiComponents/Button/CustomButton";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  getCards,
  getLossesData,
  getWaterSupplyData,
  setEndDate,
  setInitialState,
  setPeriod,
  setStartDate,
  setSwitchUnit,
  setYearToCompare,
} from "../../../store/slices/SummaryDataSlice";
import { LoadingState } from "../../../types/loadingState";
import { BlockInfoContainer } from "../blockInfo/BlockInfoContainer";
import { PeriodType } from "../../../models/summaryData/periodType";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import moment from "moment";
import { CustomDateTimePicker } from "../../../components/formFields/customDateTimePicker.tsx/customDateTimePicker";

const SummaryData: React.FC = () => {
  const dispatch = useDispatch<any>();

  const selectedPeriod = useTypedSelector((state) => state.summaryData.period);
  const yearToCompare = useTypedSelector((state) => state.summaryData.yearToCompare);
  const startDate = useTypedSelector((state) => state.summaryData.startDate);
  const endDate = useTypedSelector((state) => state.summaryData.endDate);

  const waterSupplyData = useTypedSelector((state) => state.summaryData.waterSupplyData);
  const waterSupplyChartData = JSON.parse(JSON.stringify(waterSupplyData));

  const lossesData = useTypedSelector((state) => state.summaryData.lossesData);
  const lossesChartData = JSON.parse(JSON.stringify(lossesData));

  const switchUnit = useTypedSelector((state) => state.summaryData.switchUnit);

  const isSupplyDataLoading = useTypedSelector((state) => state.summaryData.isSupplyDataLoading);
  const isLossesDataLoading = useTypedSelector((state) => state.summaryData.isLossesDataLoading);

  const filterButtons: { value: PeriodType; title: string }[] = [
    {
      value: "year",
      title: "12 месяцев",
    },
    {
      value: "halfYear",
      title: "6 месяцев",
    },
    {
      value: "quarterYear",
      title: "3 месяца",
    },
    {
      value: "month",
      title: "1 месяц",
    },
  ];

  const currentYear = new Date().getFullYear();
  const yearToCompareValues = [currentYear - 3, currentYear - 2, currentYear - 1];

  const handlePeriodChange = (period: PeriodType) => {
    dispatch(setPeriod(period));
  };

  const handleYearToCompareChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    dispatch(setYearToCompare(value !== "" ? value : null));
  };

  const handleStartDateChange = (value: moment.Moment | null) => {
    dispatch(setStartDate(value?.toDate()));
  };

  const handleEndDateChange = (value: moment.Moment | null) => {
    dispatch(setEndDate(value?.toDate()));
  };

  const handleSwitchUnitChange = () => {
    dispatch(setSwitchUnit(switchUnit === "percent" ? "volume" : "percent"));
  };

  useEffect(() => {
    dispatch(getWaterSupplyData());
    dispatch(getCards());
    if (selectedPeriod) {
      dispatch(getLossesData());
    }
  }, [selectedPeriod, yearToCompare, startDate, endDate]);

  useEffect(() => {
    if (selectedPeriod) {
      dispatch(getLossesData());
    }
  }, [switchUnit]);

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, []);

  return (
    <div className="balanceTabDefault" style={{ gap: "8px" }}>
      {/* Верхняя легенда */}
      <div className="legendaDate">
        <div className="leftLegendaDate">
          <div style={{ whiteSpace: "nowrap" }} className="headerH2">
            Баланс за
          </div>

          {filterButtons.map((button) => (
            <CustomButton
              key={button.value}
              style={styles.timePeriodButton}
              className={button.value === selectedPeriod ? "timePeriodButtonActive" : ""}
              id={button.value}
              type="timePeriodButton"
              onClick={() => {
                handlePeriodChange(button.value);
              }}
            >
              {button.title}
            </CustomButton>
          ))}

          <FormControl sx={{ minWidth: 200 }} size="small">
            <InputLabel id="summary-data-select-year">Прошлый год</InputLabel>
            <Select
              labelId="summary-data-select-year"
              value={yearToCompare || ""}
              label="Прошлый год"
              onChange={handleYearToCompareChange}
            >
              <MenuItem value="">
                <em>Не выбрано</em>
              </MenuItem>
              {yearToCompareValues.map((x) => (
                <MenuItem value={x} key={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box display="flex" gap={1} flexDirection="row" alignItems="center">
            <Typography sx={{ flexShrink: 0 }}>период с</Typography>
            <CustomDateTimePicker dateValue={moment(startDate)} onDateValueChange={handleStartDateChange} />
            <Typography>по</Typography>
            <CustomDateTimePicker
              dateValue={moment(endDate)}
              onDateValueChange={handleEndDateChange}
              minDate={moment(startDate)}
            />
          </Box>
        </div>
        <div className="rightLegendaDate">
          {/* <Link style={{ color: "#1599D7", cursor: "pointer" }} to="zone">
            Информация по зонам
          </Link> */}
          <CustomButton type="offBorders">экспорт</CustomButton>
        </div>
      </div>

      <div className="infoBlock">
        <div className="leftInfoBlock">
          {/* Первый график с легендой */}
          <div className="topChart">
            <div style={{ height: "357px", width: "100%" }}>
              {isSupplyDataLoading === LoadingState.PENDING ? (
                <Animation></Animation>
              ) : (
                <Chart
                  height={"100%"}
                  width={"100%"}
                  options={waterSupplyChartData}
                  series={waterSupplyChartData.series}
                  type="line"
                />
              )}
            </div>
          </div>

          {/* Легенда второго графика */}
          <div className="legendLowerChart">
            <div className="headerH2">Потери по зонам</div>
            <FormControlLabel
              control={<Switch defaultValue={"false"} onClick={handleSwitchUnitChange} />}
              label="Абсолютная величина"
            />
          </div>

          {/* График */}
          <div className="lowerChart" style={{ height: "357px", width: "100%" }}>
            {isLossesDataLoading === LoadingState.PENDING ? (
              <Animation></Animation>
            ) : (
              <Chart
                height={"100%"}
                width={"100%"}
                options={lossesChartData}
                series={lossesChartData.series}
                type="bar"
              />
            )}
          </div>
        </div>

        <BlockInfoContainer />
      </div>
    </div>
  );
};

export default SummaryData;

const styles = {
  timePeriodButton: { width: "100%", height: "40px" },
};
