import { Box, Select, FormControl, InputLabel, MenuItem, Typography, SelectChangeEvent } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { DatePicker } from "@mui/x-date-pickers";
import { FixedPeriod } from "../../../../models/common/filterPeriod";
import {
  getDeviceSignals,
  getDevices,
  getSignalValues,
  setFilterValue,
  setInitialState,
} from "../../../../store/slices/objects/deviceInfoSlice";
import { ButtonGroup } from "../../../../components/formFields/buttonGroup/buttonGroup";
import moment from "moment";
import { DeviceSignalsTable } from "../../components/deviceSignalsTable/deviceSignalsTable";
import { DeviceSignalsChart } from "../../components/deviceSignalsChart/deviceSignalsChart";
import { LoadingState } from "../../../../types/loadingState";
import Loader from "../../../../components/uiComponents/Animation/Animation";
import { CustomDatePicker } from "../../../../components/formFields/customDatePicker/customDatePicker";

export const DeviceInfo = () => {
  const dispatch = useDispatch<any>();

  const selectedObjectGuid = useTypedSelector((state) => state.objectInfo.selectedObjectGuid);
  const filterValue = useTypedSelector((state) => state.deviceInfo.filter);
  const devices = useTypedSelector((state) => state.deviceInfo.devices);
  const deviceSignals = useTypedSelector((state) => state.deviceInfo.deviceSignals);
  const isLoading = useTypedSelector((state) => state.deviceInfo.isLoading);

  const currentYear = new Date().getFullYear();
  const yearOptions = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3];

  const buttonGroupOptions = [
    { id: FixedPeriod.Year, name: "12 месяцев" },
    { id: FixedPeriod.Half, name: "6 месяцев" },
    { id: FixedPeriod.Quarter, name: "3 месяца" },
  ];

  const handleDeviceChange = (event: SelectChangeEvent) => {
    dispatch(getDeviceSignals({ objectGuid: selectedObjectGuid ?? "", deviceGuid: event.target.value }));
    dispatch(setFilterValue({ ...filterValue, deviceGuid: event.target.value }));
  };

  const handleDeviceSignalChange = (event: SelectChangeEvent) => {
    dispatch(setFilterValue({ ...filterValue, signalGuid: event.target.value }));
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    dispatch(setFilterValue({ ...filterValue, year: event.target.value, fixedPeriod: null }));
  };

  const handleFixedPerionChange = (period: number | string) => {
    dispatch(setFilterValue({ ...filterValue, fixedPeriod: period, year: currentYear }));
  };

  const handlePeriodFromChange = (value: moment.Moment | null) => {
    dispatch(setFilterValue({ ...filterValue, from: value?.toDate(), fixedPeriod: null, year: currentYear }));
  };

  const handlePeriodToChange = (value: moment.Moment | null) => {
    dispatch(setFilterValue({ ...filterValue, to: value?.toDate(), fixedPeriod: null, year: currentYear }));
  };

  useEffect(() => {
    selectedObjectGuid && dispatch(getDevices(selectedObjectGuid));
  }, [selectedObjectGuid]);

  useEffect(() => {
    filterValue.signalGuid && dispatch(getSignalValues());
  }, [filterValue]);

  useEffect(() => {
    return () => dispatch(setInitialState());
  }, []);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.filter}>
        <FormControl sx={styles.field} size="small">
          <InputLabel id="object-select">Датчик</InputLabel>
          <Select
            labelId="object-select"
            value={filterValue.deviceGuid || ""}
            onChange={handleDeviceChange}
            label="Датчик"
          >
            {devices.map((x) => (
              <MenuItem value={x.id} key={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={styles.field} size="small">
          <InputLabel id="object-select">Показатель</InputLabel>
          <Select
            labelId="object-select"
            value={filterValue.signalGuid || ""}
            onChange={handleDeviceSignalChange}
            label="Показатель"
          >
            {deviceSignals.map((x) => (
              <MenuItem value={x.id} key={x.id}>
                {x.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ButtonGroup value={filterValue.fixedPeriod} onChange={handleFixedPerionChange} options={buttonGroupOptions} />

        <Typography>период с</Typography>
        <FormControl sx={styles.field} size="small">
          <CustomDatePicker dateValue={moment(filterValue.from)} onDateValueChange={handlePeriodFromChange} />
        </FormControl>
        <Typography>по</Typography>
        <FormControl sx={styles.field}>
          <CustomDatePicker
            dateValue={moment(filterValue.to)}
            onDateValueChange={handlePeriodToChange}
            minDate={moment(filterValue.from)}
          />
        </FormControl>

        <FormControl sx={styles.field} size="small">
          <InputLabel id="object-select">Год</InputLabel>
          <Select labelId="object-select" value={filterValue.year.toString()} onChange={handleYearChange} label="Год">
            {yearOptions.map((x) => (
              <MenuItem value={x} key={x}>
                {x}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={styles.chartContainer}>{isLoading === LoadingState.PENDING ? <Loader /> : <DeviceSignalsChart />}</Box>
      <Box sx={styles.tableContainer}>{isLoading === LoadingState.PENDING ? <Loader /> : <DeviceSignalsTable />}</Box>
    </Box>
  );
};

const styles = {
  container: {
    flex: "0 1 100%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  filter: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    flexWrap: "wrap",
  },
  field: {
    width: "200px",
  },
  chartContainer: {
    flex: "1",
    minHeight: "300px",
  },
  tableContainer: {
    flex: "1 1 100%",
  },
};
