import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";
import { ApexLocales } from "../../../../constants/ApexChartsSettings";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  BlocksInfoCard,
  GetConsumption,
  GetCounterContracts,
  GetDistributionCard,
  GetZoneConsumptionTable,
  GetZoneSalesSeries,
  ZoneConsumptionTable,
} from "../../BalansApis";
import BlockInfo from "../../blockInfo/BlockInfo";
import { ConsumptionVolumeChart } from "./components/consumptionVolumeChart/consumptionVolumeChart";
import { MlDataChart } from "./components/mlDataChart/mlDataChart";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface distributionInterface {
  // zoneNumber: string;
}

const Distribution: React.FC<distributionInterface> = () => {
  const columns: GridColDef[] = [
    {
      field: "bG",
      headerName: "Балансовая группа",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "pU",
      headerName: "Начисление по ПУ, тыс. м3",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "payment",
      headerName: "Оплата, тыс. руб.",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "a",
      headerName: "",
      flex: 1,
      minWidth: 150,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link style={{ display: "flex", alignItems: "center", cursor: "pointer" }} to={`${params.id}`}>
            ПОДРОБНО
          </Link>
        );
      },
    },
  ];

  // const [contracts, setContracts] = useState<counterContractsProps>();
  const [donut2Opt, setDonut2Opt] = useState<ApexOptions>({
    tooltip: {
      enabled: true,
    },
    colors: ["#1478BE", "#1599D7", "#777777", "#E1F3FF"],
    series: [22, 28, 10, 20],
    dataLabels: {
      enabled: false,
    },
    chart: {
      animations: {
        enabled: false,
      },
    },
    legend: {
      show: true,
      position: "bottom",
      offsetY: 0,
      offsetX: -10,
      floating: false,
      horizontalAlign: "left",
    },
    labels: ["Действующий", "Недействующий", "Переведен", "Временно недействующий"],
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              offsetY: 7,
              show: true,
              fontWeight: "600",
            },
            total: {
              show: true,
            },
          },
        },
        dataLabels: {
          offset: -50,
        },
      },
    },
  });

  const [donutOpt, setDonutOpt] = useState<ApexOptions>({
    tooltip: { enabled: true },
    colors: ["#1478BE", "#1599D7", "#4BB8A9", "#777777", "#E1F3FF"],
    series: [22, 28, 10, 20, 20],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "left",
      floating: false, // Отключаем плавающее положение легенды
      offsetY: 0, // Устанавливаем вертикальное смещение легенды (может быть отрицательным для отступа вверх)
      offsetX: -10,
    },
    labels: ["Холодная вода", "Кан. холодная вода", "Кан. гор. вода", "Хол. вода. п.к.", "Транспорт"],
    chart: {
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              offsetY: 7,
              show: true,
              fontWeight: "600",
            },
            total: {
              show: true,
              formatter: function (w) {
                return w.globals.seriesTotals
                  .reduce((a: number, b: number) => {
                    return a + b;
                  }, 0)
                  .toFixed(2);
              },
            },
          },
        },
        // dataLabels: {
        //   offset: -50,
        // },
      },
    },
  });

  const [bar2Opt, setBar2Opt] = useState<ApexOptions>({
    series: [
      {
        name: "По ПУ, млн. руб.",
        data: [0.489, 0.461, 0.509, 0.493, 0.263, 0.0032],
        color: "#1478BE",
      },
      {
        name: "Начислено, млн. руб.",
        data: [0.839, 0.965, 0.812, 0.238, 0.353, 0.273],
        color: "#A7B6CD",
      },
    ],
    chart: {
      type: "bar",
      width: "100%",
      stacked: true,
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      colors: ["#1599D7"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 2,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      // enabled: false,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ["Янв", "Фев", "Мар", "Aпр", "Май", "Июн"],
    },
  });

  const [zoneConsumptionTable, setZoneConsumptionTable] = useState<ZoneConsumptionTable[]>([]);
  const [destCards, setDestCards] = useState<BlocksInfoCard[]>([]);
  const [period, setPeriod] = useState("year");
  const [userType, setUserType] = useState("total");
  const [valueType, setValueType] = useState("false");
  const buttonSensor = ["buttonYear", "buttonHalfYear", "buttonQuarter", "buttonQuarterYear", "buttonMonth"];

  const [isLoadingDon1, setIsLoadingDon1] = useState(false);
  const [isLoadingDon2, setIsLoadingDon2] = useState(false);
  const [isLoadingSalesSeries, setIsLoadingSalesSeries] = useState(true);
  const { zoneNumber } = useTypedSelector((state) => state.zone);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);

  const [mainChartsIsVisible, setMainChartsIsVisible] = useState(true);
  const [donutsIsVisible, setDonutIsVisible] = useState(false);

  // Функция смены класса кнопки периода
  function buttonClassChange(id: string) {
    // Находим кнопку по id
    var btn = document.getElementById(id);
    // Была ли кнопка найдена
    if (btn !== null) {
      btn.classList.add("timePeriodButtonActive");
    }
    if (btn !== null || id === "undefined") {
      buttonSensor.forEach((button) => {
        if (button !== id) {
          var btn1 = document.getElementById(button);
          if (btn1 !== null) {
            btn1.classList.remove("timePeriodButtonActive");
          }
        }
      });
    }
  }

  // Функция выбора периода
  function setTimePeriod(type: string) {
    let per = "";

    switch (type) {
      case "year":
        per = "year";
        break;
      case "quarter":
        per = "quarter";
        break;

      case "month":
        per = "month";
        break;

      default:
        per = "year";
    }
    setPeriod(per);
    getSalesSeries(per);
    getZoneConsumptionTable(per);
  }

  const handleDonutsVisibleStateChange = () => {
    setMainChartsIsVisible(false);
    setDonutIsVisible((prev) => !prev);
    setTimeout(() => {
      setMainChartsIsVisible(true);
    }, 0);
  };

  // ***ЗАПРОСЫ***

  //Запрос для таблицы балансовых групп (Сбыт.Зона.Таблица)
  async function getZoneConsumptionTable(period: string) {
    setIsLoadingTable(false);
    try {
      const response = await GetZoneConsumptionTable(period, zoneNumber, userType);
      setZoneConsumptionTable(response);
      setIsLoadingTable(true);
    } catch (e) {
      console.log(e);
    }
  }

  // Запрос для графика прогнозирования потребления (Сбыт.Прогнозирование_потребления) находиться в файле mlDataChart.js

  // Запрос для второй пончиковой диаграммы (Сбыт.Поданная_вода)
  async function getConsumption() {
    setIsLoadingDon1(true);
    try {
      const response = await GetConsumption(zoneNumber);
      setDonutOpt({ ...donutOpt, series: response.series, colors: response.colors, labels: response.labels });
      setIsLoadingDon1(false);
      // console.log(response);
    } catch (e) {
      console.log(e);
    }
  }

  // Запрос для первой пончиковой диаграммы (Сбыт.Котнракты)
  async function getCounterContracts() {
    setIsLoadingDon2(true);
    try {
      const response = await GetCounterContracts(zoneNumber);
      setDonut2Opt({ ...donut2Opt, series: response.data, colors: response.colors, labels: response.labels });
      // console.log(response);
      setIsLoadingDon2(false);
    } catch (e) {
      console.log(e);
    }
  }

  // Запрос данных для карточек с информацией (Сбыт)
  async function getDistributionCard() {
    setIsLoadingCard(false);
    try {
      const response = await GetDistributionCard(zoneNumber);
      setDestCards(response);
      // console.log(response);
      setIsLoadingCard(true);
    } catch (e) {
      console.log(e);
    }
  }

  // Функция запроса данных для графика начислений
  async function getSalesSeries(period: string) {
    setIsLoadingSalesSeries(true);
    try {
      const response = await GetZoneSalesSeries(period, zoneNumber, userType, valueType);
      setBar2Opt({ ...bar2Opt, series: response.series, xaxis: { categories: response.categories } });
      setIsLoadingSalesSeries(false);
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    buttonClassChange("buttonYear");
  }, []);

  useEffect(() => {
    // TODO:
    // При рабочем API раскомментить
    // getCounterContracts();
    getDistributionCard();
    getConsumption();
  }, [zoneNumber]);

  useEffect(() => {
    getSalesSeries(period);
    getZoneConsumptionTable(period);
  }, [zoneNumber, period, userType, valueType]);

  return (
    <div className="balanceTabDefault">
      {/* Верхняя легенда */}
      <div className="leftLegendaDate">
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          Объём потребления
        </div>

        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Тип</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userType}
            label="Тип отчета"
            onChange={(e) => {
              setUserType(e.target.value);
            }}
          >
            <MenuItem value={"total"}>Все</MenuItem>
            <MenuItem value={"account"}>ФЛ</MenuItem>
            <MenuItem value={"client"}>ЮЛ</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Эквивалент</InputLabel>
          <Select
            sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={valueType}
            label="Тип отчета"
            onChange={(e) => {
              setValueType(e.target.value);
            }}
          >
            <MenuItem value={"false"}>м.куб</MenuItem>
            <MenuItem value={"true"}>Рубли</MenuItem>
          </Select>
        </FormControl>
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          по
        </div>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonYear"
          onClick={() => {
            buttonClassChange("buttonYear");
            setTimePeriod("year");
          }}
        >
          Годам
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonQuarterYear"
          onClick={() => {
            buttonClassChange("buttonQuarterYear");
            setTimePeriod("quarter");
          }}
        >
          Кварталам
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonMonth"
          onClick={() => {
            buttonClassChange("buttonMonth");
            setTimePeriod("month");
          }}
        >
          Месяцам
        </CustomButton>
      </div>
      <Box sx={styles.mainChartsWrapper}>
        <Box sx={styles.mainChartsLeft}>
          <Box sx={styles.mainChartsTooltip}>
            <Button
              sx={styles.donutVisionBtn}
              onClick={handleDonutsVisibleStateChange}
              startIcon={donutsIsVisible ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            >
              {donutsIsVisible ? "Скрыть детали" : "Показать детали"}
              <Box />
            </Button>
          </Box>
          <Box sx={styles.mainChartsContentBox}>
            <Box sx={styles.mainChartsContentBoxLeft}>
              {/* Первый график с легендой */}
              <ConsumptionVolumeChart
                statisticsSubject={userType}
                period={period}
                zone={zoneNumber}
                isRubles={valueType}
                isVisible={mainChartsIsVisible}
              />

              {/* Второй график с легендой */}
              <MlDataChart
                period={period}
                zoneNumber={zoneNumber}
                statisticSubject={userType}
                isRubles={valueType}
                isVisibleExternal={mainChartsIsVisible}
              />
            </Box>
            {/* Блок графиков */}
            {donutsIsVisible ? (
              <Box sx={styles.donutsBox}>
                <p
                  className="additionalText"
                  style={{
                    minWidth: `calc(${styles.donutsBox.minWidth} - 12px)`,
                    maxWidth: `calc(${styles.donutsBox.maxWidth} - 20px)`,
                    textWrap: "pretty",
                    textAlign: "center",
                  }}
                >
                  * Данные с 1-го января текущего года
                </p>
                {isLoadingDon1 ? (
                  <Animation></Animation>
                ) : (
                  <Chart options={donutOpt} series={donutOpt.series} type="donut" width={240} height={300} />
                )}

                {
                  //TO DO:
                  // При рабочем API раскоментить
                  // isLoadingDon2 ? (
                  //   <Animation></Animation>
                  // ) : (
                  //   <Chart options={donut2Opt} series={donut2Opt.series} type="donut" width={240} height={300} />
                  // )
                }
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
        {/* Блок описания */}
        <Box className="description">
          {!isLoadingCard ? (
            <Animation></Animation>
          ) : (
            destCards.map((item) => <BlockInfo header={item.header} blocks={item.blocks} key={item.header} />)
          )}
        </Box>
      </Box>

      <div style={{ height: "380px", width: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          Начисления
        </div>
        {isLoadingSalesSeries ? (
          <Animation />
        ) : (
          <Chart height={"357px"} width={"100%"} options={bar2Opt} series={bar2Opt.series} type="bar" />
        )}
      </div>
      <Box sx={{ height: "calc(100vh - 500px)", width: "100%" }} className="tableHeight">
        {!isLoadingTable ? (
          <Animation></Animation>
        ) : (
          <DataGrid
            rows={zoneConsumptionTable}
            columns={columns}
            //pageSize={20}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </Box>
    </div>
  );
};

const styles = {
  mainChartsWrapper: {
    display: "flex",
    gap: "12px",
  },
  mainChartsLeft: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  mainChartsTooltip: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  mainChartsContentBox: {
    display: "flex",
  },
  mainChartsContentBoxLeft: {
    flex: "1 1 100%",
  },
  donutVisionBtn: {
    width: "160px",
    justifyContent: "space-between",
  },
  donutsBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    minWidth: "240px",
    maxWidth: "300px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default Distribution;
