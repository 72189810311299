import { Box } from "@mui/material";
import { useEffect } from "react";
import Loader from "../../../../components/uiComponents/Animation/Animation";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { LoadingState } from "../../../../types/loadingState";
import { MonitoringEventsStatisticCard } from "../monitoringEventsStatisticCard/MonitoringEventsStatisticCard";
import { useDispatch } from "react-redux";
import { getEventsStatistics } from "../../../../store/slices/monitoring/MonitoringEventsSlice";
import { EventStatisticCardDto } from "../../../../models/eventStatisticCard/eventStatisticCardDto";

const EmptyCard: EventStatisticCardDto = { header: "", totalCount: 0, type: "Service" };

export const MonitoringEventsStatistic = () => {
  const dispatch = useDispatch<any>();

  const eventStatistics = useTypedSelector((state) => state.monitoringEvents.eventStatistics);
  const isStatisticsLoading = useTypedSelector((state) => state.monitoringEvents.isStatisticsLoading);

  const criticalStatisticsCard: EventStatisticCardDto = eventStatistics.find((x) => x.type === "Critical") ?? {
    ...EmptyCard,
    header: "Критические события",
    type: "Critical",
  };
  const excessStatisticsCard: EventStatisticCardDto = eventStatistics.find((x) => x.type === "Excess") ?? {
    ...EmptyCard,
    header: "Превышения",
    type: "Excess",
  };
  const serviceStatisticsCard: EventStatisticCardDto = eventStatistics.find((x) => x.type === "Service") ?? {
    ...EmptyCard,
    header: "Сервисные сигналы",
    type: "Service",
  };
  const fixedStatisticsCard: EventStatisticCardDto = eventStatistics.find((x) => x.type === "Fixed") ?? {
    ...EmptyCard,
    header: "Устранено",
    type: "Fixed",
  };
  const notProcessedStatisticsCard: EventStatisticCardDto = eventStatistics.find((x) => x.type === "NotProcessed") ?? {
    ...EmptyCard,
    header: "Не обработано",
    type: "NotProcessed",
  };

  const totalCount =
    criticalStatisticsCard?.totalCount + excessStatisticsCard?.totalCount + serviceStatisticsCard?.totalCount;

  useEffect(() => {
    dispatch(getEventsStatistics());
  }, []);

  if (isStatisticsLoading === LoadingState.PENDING) {
    return <Loader />;
  }

  return (
    <>
      <Box sx={styles.statisticsRow}>
        <Box sx={styles.statisticsCell}>
          {criticalStatisticsCard && (
            <MonitoringEventsStatisticCard card={criticalStatisticsCard} totalCount={totalCount} color="#CA807F" />
          )}
        </Box>
        <Box sx={styles.statisticsCell}>
          {excessStatisticsCard && (
            <MonitoringEventsStatisticCard card={excessStatisticsCard} totalCount={totalCount} color="#EAAF6B" />
          )}
        </Box>
      </Box>
      <Box sx={styles.statisticsRow}>
        <Box sx={styles.statisticsCell}>
          {serviceStatisticsCard && (
            <MonitoringEventsStatisticCard card={serviceStatisticsCard} totalCount={totalCount} color="#1478BE" />
          )}
        </Box>
        <Box sx={[styles.statisticsCell, styles.statisticsRow]}>
          <Box sx={styles.statisticsCell}>
            {notProcessedStatisticsCard && (
              <MonitoringEventsStatisticCard
                card={notProcessedStatisticsCard}
                totalCount={totalCount}
                color="#D2D2CF"
              />
            )}
          </Box>
          <Box sx={styles.statisticsCell}>
            {fixedStatisticsCard && (
              <MonitoringEventsStatisticCard
                card={fixedStatisticsCard}
                totalCount={totalCount}
                color="#4BB8A9"
                hasChartReversed
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  statisticsRow: {
    display: "flex",
    gap: "16px",
    flex: "1 1 50%",
  },
  statisticsCell: {
    flex: "1 1 50%",
  },
};
