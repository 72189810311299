import { GridActionsCellItem, GridDeleteIcon, GridRenderCellParams } from '@mui/x-data-grid';
import { BaseTable } from '../../../../components/baseTable/BaseTable';
import { BaseTableColumnDef } from '../../../../models/baseTable/baseTableColumns';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { useEffect } from 'react';
import {
  deleteNormItem,
  getNorms,
  setCurrentPage,
  setItemToDelete,
  setPageSize,
  setShowDeleteModal,
  updateNormItem,
} from '../../../../store/slices/objects/normsSlice';
import { useParams } from 'react-router-dom';
import { NormTableItemExtendedDto } from '../../../../models/objects/normTableItemDto';
import { NormEditorRenderer } from './normEditorRenderer';
import { LoadingState } from '../../../../types/loadingState';
import { setYupLocales } from '../../../../utilities/validation/setYupLocales';
import { ObjectSchema, number, object } from 'yup';
import { EditNormItemDto } from '../../../../models/objects/norms/editNormItemDto';
import { ConfirmationModal } from '../../../../components/confirmationModal/confirmationModal';

setYupLocales();
const validationSchema: ObjectSchema<EditNormItemDto> = object().shape({
  value: object().shape({
    lowNormValue: number().typeError('значение должно быть числом').label('Нижнее значение').min(0),
    highNormValue: number()
      .typeError('значение должно быть числом')
      .label('Верхнее значение')
      .when('lowNormValue', (values) => {
        const value = values[0] ?? 0;
        return number().label('Верхнее значение').min(value);
      }),
  }),
});

export const Norms = () => {
  const dispatch = useDispatch<any>();

  const selectedObjectGuid = useTypedSelector((state) => state.objectInfo.selectedObjectGuid);

  const data = useTypedSelector((state) => state.norms.data);
  const showDeleteModal = useTypedSelector((state) => state.norms.showDeleteModal);
  const isLoading = useTypedSelector((state) => state.norms.isLoading);
  const itemToDeleteId = useTypedSelector((state) => state.norms.itemToDeleteId);

  const currentPage = useTypedSelector((state) => state.norms.currentPage);
  const pageSize = useTypedSelector((state) => state.norms.pageSize);
  const totalItems = useTypedSelector((state) => state.norms.totalItems);

  const columns: BaseTableColumnDef[] = [
    {
      field: 'deviceName',
      headerName: 'Наименование датчика',
    },
    {
      field: 'signalName',
      headerName: 'Наименование сигнала',
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.normMeasurementUnit) {
          return `${params.row.signalName} (${params.row.normMeasurementUnit})`;
        }

        return params.row.signalName;
      },
    },
    {
      field: 'value',
      headerName: 'Норма',
      renderCell: (params: GridRenderCellParams) => {
        if (params.value.lowNormValue >= 0 && params.value.highNormValue > 0) {
          return `${params.value.lowNormValue} - ${params.value.highNormValue} ${params.row.normMeasurementUnit ?? ''}`;
        }

        return '-';
      },
      renderEditCell: NormEditorRenderer,
      editable: true,
    },
  ];

  const handleOnDeleteClick = (id: string) => {
    dispatch(setShowDeleteModal(true));
    dispatch(setItemToDelete(id));
  };

  const loadData = () => {
    if (selectedObjectGuid) {
      dispatch(getNorms({ guid: selectedObjectGuid, from: currentPage, size: pageSize }));
    }
  };

  const handleSave = async (row: NormTableItemExtendedDto) => {
    const result = await dispatch(
      updateNormItem({
        signalEventGuid: row.signalEventGuid,
        newLowNormValue: row.value.lowNormValue,
        newHighNormValue: row.value.highNormValue,
      }),
    );

    if (!result.error) {
      loadData();
    }
  };

  const handleDelete = async () => {
    if (!itemToDeleteId) {
      return;
    }

    const result = await dispatch(deleteNormItem(itemToDeleteId));
    if (!result.error) {
      dispatch(setShowDeleteModal(false));
      loadData();
    }
  };

  const handleCloseConfirmation = () => {
    dispatch(setShowDeleteModal(false));
  };

  const handleChangePage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleChangePageSize = (pageSize: number) => {
    dispatch(setPageSize(pageSize));
  };

  const getTableActions = (id: string) => [<GridActionsCellItem icon={<GridDeleteIcon />} label="Удалить" onClick={() => handleOnDeleteClick(id)} />];

  useEffect(() => {
    loadData();
  }, [selectedObjectGuid, currentPage, pageSize]);

  return (
    <>
      <BaseTable
        columns={columns}
        data={data}
        pageSize={pageSize}
        totalItems={totalItems}
        currentPage={currentPage}
        onChangePage={handleChangePage}
        onChangePageSize={handleChangePageSize}
        idGetter={(item: NormTableItemExtendedDto) => item.signalEventGuid}
        editable
        isLoading={isLoading === LoadingState.PENDING}
        onSave={handleSave}
        validationSchema={validationSchema}
        actionsColumnName="Ред/Уд"
        getTableActions={getTableActions}
      />
      {showDeleteModal && (
        <ConfirmationModal
          title="Подтвердите действие"
          message="Вы действительно хотите удалить значения?"
          okText="Удалить"
          okClick={handleDelete}
          cancelClick={handleCloseConfirmation}
        />
      )}
    </>
  );
};
