import { TextField, Typography } from '@mui/material';
import { GridRenderEditCellParams } from '@mui/x-data-grid';

export const NormEditorRenderer: React.FC<GridRenderEditCellParams> = (params: GridRenderEditCellParams) => {
  const handleLowValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    params.api.setEditCellValue({ id: params.id, field: params.field, value: { ...params.value, lowNormValue: newValue } });
  };

  const handleHighValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    params.api.setEditCellValue({ id: params.id, field: params.field, value: { ...params.value, highNormValue: newValue } });
  };

  return (
    <>
      <TextField type="text" label="нижнее значение" value={params.value.lowNormValue} onChange={handleLowValueChange} />
      <TextField type="text" label="верхнее значение" value={params.value.highNormValue} onChange={handleHighValueChange} />
      <Typography>{params.row.normMeasurementUnit}</Typography>
    </>
  );
};
