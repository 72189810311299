import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TabPanel } from '../../components/tabPanel/tabPanel';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { customTabProps } from '../../utilities/customTabProps';
import { OperativeInformation } from './tabs/operativeInformation/operativeInformation';
import { Norms } from './tabs/norms/norms';
import { useDispatch } from 'react-redux';
import { getObjects, setSelectedObjectGuid } from '../../store/slices/objects/objectInfoSlice';
import { LoadingState } from '../../types/loadingState';
import Loader from '../../components/uiComponents/Animation/Animation';
import { DeviceInfo } from './tabs/deviceInfo/deviceInfo';

export const ObjectInfo = () => {
  const navigate = useNavigate();
  const { guid: objectGuid } = useParams();
  const dispatch = useDispatch<any>();

  const [selectedTab, setSelectedTab] = useState(0);

  const objects = useTypedSelector((state) => state.objectInfo.objects);
  const isObjectsLoading = useTypedSelector((state) => state.objectInfo.isObjectsLoading);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleSelectedObjectChange = (event: SelectChangeEvent) => {
    dispatch(setSelectedObjectGuid(event.target.value));
    navigate(`/objectInfo/${event.target.value}`);
  };

  useEffect(() => {
    dispatch(getObjects());
    dispatch(setSelectedObjectGuid(objectGuid));
  }, []);

  return (
    <Box sx={styles.container} className="defaultPageStyle">
      <>
        {isObjectsLoading === LoadingState.PENDING && (
          <Box sx={styles.loader}>
            <Loader />
          </Box>
        )}
        {isObjectsLoading === LoadingState.SUCCESS && (
          <Box sx={styles.left}>
            <Box sx={styles.titleContainer}>
              <FormControl sx={styles.objectSelect}>
                <InputLabel id="object-select">Объект</InputLabel>
                <Select labelId="object-select" value={objectGuid || ''} label="Объект" onChange={handleSelectedObjectChange}>
                  {objects.map((x) => (
                    <MenuItem value={x.geoObjectId} key={x.geoObjectId}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {objectGuid && (
              <Box sx={styles.contentContainer}>
                <Tabs value={selectedTab} onChange={handleTabChange} TabIndicatorProps={{ style: { background: '#1478BE' } }} style={{ width: '100%' }}>
                  <Tab label="Оперативная информация" {...customTabProps(0)} />
                  <Tab label="Показания" {...customTabProps(1)} />
                  <Tab label="Нормы" {...customTabProps(2)} />
                </Tabs>

                <TabPanel value={selectedTab} index={0}>
                  <OperativeInformation />
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                  <DeviceInfo />
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                  <Norms />
                </TabPanel>
              </Box>
            )}
          </Box>
        )}
      </>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    minHeight: 'calc(100% - 20px)',
    gap: '40px',
    padding: '20px 32px 0',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  left: {
    flex: '0 1 100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  objectSelect: {
    minWidth: '300px',
  },
  contentContainer: {
    flex: '0 1 100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    '& [id^=simple-tabpanel]': {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 1 100%',
      '&[hidden]': {
        display: 'none',
      },
      '&>div': {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 1 100%',
      },
    },
  },
  backBtn: {
    padding: 0,
    color: '#000',
  },
  title: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%',
  },
  right: {
    flex: '0 1 25%',
  },
};
