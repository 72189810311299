import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ConsumptionVolumeChartData } from "../../../models/zoneDistribution/consumptionVolumeChartData";
import { zoneDistributionApi } from "../../../pages/balance/zones/api/zoneDistributionApi";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";

const sliceName = "zoneDistribution";

interface InitialState {
  chartData?: ConsumptionVolumeChartData;
  isLoading: LoadingState;
}

const initialState: InitialState = {
  isLoading: LoadingState.EMPTY,
};

export const getConsumptionVolumeValues = createAsyncThunk<
  ConsumptionVolumeChartData,
  { statisticsSubject: string; period: string; zone: string; isRubles: string },
  { state: RootState }
>(`${sliceName}/getConsumptionVolumeValues`, async (params, thunkAPI) => {
  try {
    const { statisticsSubject, period, zone, isRubles } = params;
    const response = await zoneDistributionApi.getConsumptionVolumeValues(statisticsSubject, period, zone, isRubles);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
  }
});

const zoneDistributionSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConsumptionVolumeValues.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getConsumptionVolumeValues.fulfilled, (state, action) => {
      state.chartData = action.payload;
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getConsumptionVolumeValues.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const zoneDistributionReducer = zoneDistributionSlice.reducer;
