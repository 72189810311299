import { Box } from "@mui/material";
import { DataAnalysisTableView } from "../../components/dataAnalysisTableView/dataAnalysisTableView";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";

export const Balance = () => {
  const columns: BaseTableColumnDef[] = [
    {
      headerName: "Дата",
      field: "date",
    },
    {
      headerName: "Показания",
      field: "indication",
    },
  ];

  return (
    <Box sx={styles.contentContainer}>
      <DataAnalysisTableView columns={columns} urlPart="get_balance" />
    </Box>
  );
};

const styles = {
  contentContainer: {
    paddingTop: "20px",
  },
};
