import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, IconButton, Typography, FormControl } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { MonitoringEventInfoEvents } from "./components/monitoringEventInfoEvents/MonitoringEventInfoEvents";
import { MonitoringEventInfoMetersTable } from "./components/monitoringEventInfoMetersTable/monitoringEventInfoMetersTable";
import { MonitoringEventInfoMetersChart } from "./components/monitoringEventInfoMetersCart/monitoringEventInfoMetersChart";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getMonitoringEventInfoTableData,
  setFilterValue,
  setInitialState,
} from "../../store/slices/monitoring/MonitoringEventInfoSlice";
import { ButtonGroup } from "../../components/formFields/buttonGroup/buttonGroup";
import { EventInfoFixedPeriod } from "../../models/eventInfo/eventInfoFixedPeriod";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

export const MonitoringEventInfo = () => {
  const { guid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const selectedEventItem = useTypedSelector((state) => state.monitoringEventInfo.selectedEventItem);
  const filter = useTypedSelector((state) => state.monitoringEventInfo.filter);

  const buttonGroupOptions = [
    { id: EventInfoFixedPeriod.Hour, name: "Час" },
    { id: EventInfoFixedPeriod.Day, name: "День" },
    { id: EventInfoFixedPeriod.Month, name: "Месяц" },
  ];

  const handleFixedPerionChange = (period: number | string) => {
    dispatch(setFilterValue({ ...filter, fixedPeriod: period }));
  };

  const handlePeriodFromChange = (value: moment.Moment | null) => {
    dispatch(setFilterValue({ ...filter, from: value?.toDate(), fixedPeriod: null }));
  };

  const handlePeriodToChange = (value: moment.Moment | null) => {
    dispatch(setFilterValue({ ...filter, to: value?.toDate(), fixedPeriod: null }));
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (guid) {
      dispatch(getMonitoringEventInfoTableData({ signalEventGuid: guid }));
    }
  }, [filter]);

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, []);

  return (
    <Box sx={styles.container} className="defaultPageStyle">
      <Box sx={styles.left}>
        <Box sx={styles.titleRow}>
          <IconButton sx={styles.backBtn} onClick={handleNavigateBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
          {selectedEventItem && (
            <Box sx={styles.title}>{`${selectedEventItem.objectName}. ${selectedEventItem.message}`}</Box>
          )}
        </Box>
        <Box sx={styles.contentContainer}>
          <Box sx={styles.filter}>
            <ButtonGroup value={filter.fixedPeriod} onChange={handleFixedPerionChange} options={buttonGroupOptions} />

            <Typography>период с</Typography>
            <FormControl>
              <DatePicker
                value={moment(filter.from)}
                onChange={handlePeriodFromChange}
                slotProps={{ textField: { size: "small" } }}
              />
            </FormControl>
            <Typography>по</Typography>
            <FormControl>
              <DatePicker
                value={moment(filter.to)}
                onChange={handlePeriodToChange}
                minDate={moment(filter.from)}
                slotProps={{ textField: { size: "small" } }}
              />
            </FormControl>
          </Box>
          <Box sx={styles.contentRow}>
            <MonitoringEventInfoMetersChart />
          </Box>
          <Box sx={[styles.contentRow, styles.table]}>
            <MonitoringEventInfoMetersTable />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.right}>
        <MonitoringEventInfoEvents />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    height: "calc(100% - 20px)",
    gap: "40px",
    padding: "20px 32px 0",
  },
  filter: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
  left: {
    flex: "0 1 75%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  contentContainer: {
    flex: "0 1 100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  contentRow: {
    flex: "0 1 50%",
  },
  table: {
    maxHeight: "350px", 
  },
  backBtn: {
    padding: 0,
    color: "#000",
  },
  title: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
  },
  right: { flex: "0 1 25%" },
};
