import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../reducers';
import { MapZoneDto } from '../../models/mapZones/mapZone';
import { MapObjectDto } from '../../models/map/mapObjectDto';
import { monitoringMapApi } from '../../pages/monitoring/monitoringMapApi';
import { CimModel } from '../../models/map/cimModel/cimModel';

const sliceName = 'monitoringMap';

interface InitialState {
  zones: MapZoneDto[];
  mapObjects: MapObjectDto[];

  cimModels: { objectGuid: string; cimModel: CimModel }[];
  showCimModel: boolean;
  isCimModelLoading: boolean;
}

const initialState: InitialState = {
  zones: [],
  mapObjects: [],
  cimModels: [],
  showCimModel: false,
  isCimModelLoading: false,
};

export const getZones = createAsyncThunk<MapZoneDto[], void, { state: RootState }>(`${sliceName}/getZones`, async (_, thunkAPI) => {
  try {
    const response = await monitoringMapApi.getZones();
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Ошибка соединения. Повторите попытку');
  }
});

export const getMapObjects = createAsyncThunk<MapObjectDto[], void, { state: RootState }>(`${sliceName}/getMapObjects`, async (_, thunkAPI) => {
  try {
    const response = await monitoringMapApi.getMapObjects();
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Ошибка соединения. Повторите попытку');
  }
});

export const getCIMModel = createAsyncThunk<any, { objectGuids: string[]; pointsToRemove?: number[] }, { state: RootState }>(
  `${sliceName}/getCIMModel`,
  async (parameters, thunkAPI) => {
    try {
      const pointsToRemove = parameters.pointsToRemove ?? [5, 6, 7, 9, 10, 11, 13];

      const response = await Promise.all(
        parameters.objectGuids.map((guid) => {
          return monitoringMapApi.getCIMModel(guid, pointsToRemove).then((result) => ({
            objectGuid: guid,
            cimModel: result.data,
          }));
        }),
      );

      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue('Ошибка соединения. Повторите попытку');
    }
  },
);

const monitoringMapSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setShowCimModel: (state, action) => {
      state.showCimModel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getZones.fulfilled, (state, action) => {
      state.zones = action.payload;
    });
    builder.addCase(getMapObjects.fulfilled, (state, action) => {
      state.mapObjects = action.payload;
    });

    builder.addCase(getCIMModel.pending, (state, action) => {
      state.isCimModelLoading = true;
    });
    builder.addCase(getCIMModel.rejected, (state, action) => {
      state.isCimModelLoading = false;
    });
    builder.addCase(getCIMModel.fulfilled, (state, action) => {
      state.cimModels = action.payload;
      state.isCimModelLoading = false;
    });
  },
});

export const monitoringMapReducer = monitoringMapSlice.reducer;
export const { setShowCimModel } = monitoringMapSlice.actions;
