import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";
import { DataAnalysisApi } from "../../../pages/dataAnalysis/api/dataAnalysisApi";
import { AnomaliesEventsData } from "../../../models/dataAnalysis/anomalies/anomaliesEventsData";
import { AnomalyDetailDto as AnomalyDetailDto } from "../../../models/dataAnalysis/anomalies/anomalyDetailDto";
import { AnomalyDetailUpdateDto } from "../../../models/dataAnalysis/anomalies/anomalyDetailUpdateDto";
import { DefaultPaginationSettings, PaginationSettings } from "../../../models/common/paginationSettings";

const sliceName = "anomalies";

interface InitialState {
  eventsData?: AnomaliesEventsData;
  eventListPaginationSettings: PaginationSettings;

  anomalyDetails: AnomalyDetailDto[];

  isLoading: LoadingState;
  isAnomaliesLoading: LoadingState;
}

const initialState: InitialState = {
  eventListPaginationSettings: DefaultPaginationSettings,

  anomalyDetails: [],

  isLoading: LoadingState.EMPTY,
  isAnomaliesLoading: LoadingState.EMPTY,
};

export const getAnomaliesEventsData = createAsyncThunk<AnomaliesEventsData, void, { state: RootState }>(
  `${sliceName}/getAnomaliesEventsData`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState().anomalies;
      const response = await DataAnalysisApi.getAnomaliesEventsData(
        state.eventListPaginationSettings.currentPage,
        state.eventListPaginationSettings.pageSize
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const respondAnomaliesEvent = createAsyncThunk<void, { guid: string }, { state: RootState }>(
  `${sliceName}/respondAnomaliesEvent`,
  async (params, thunkAPI) => {
    try {
      const { guid } = params;
      const response = await DataAnalysisApi.respondEvent(guid);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const getAnomalies = createAsyncThunk<AnomalyDetailDto[], void, { state: RootState }>(
  `${sliceName}/getAnomalies`,
  async (_, thunkAPI) => {
    try {
      const response = await DataAnalysisApi.getAnomalies();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const updateAnomalyNote = createAsyncThunk<void, AnomalyDetailUpdateDto, { state: RootState }>(
  `${sliceName}/updateAnomalyNote`,
  async (params, thunkAPI) => {
    try {
      const response = await DataAnalysisApi.updateAnomalyNote(params);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const anomaliesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setEventListPaginationSettings: (state, action) => {
      state.eventListPaginationSettings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnomaliesEventsData.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getAnomaliesEventsData.fulfilled, (state, action: PayloadAction<AnomaliesEventsData>) => {
      state.eventsData = action.payload;
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getAnomaliesEventsData.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });

    builder.addCase(getAnomalies.pending, (state) => {
      state.isAnomaliesLoading = LoadingState.PENDING;
    });
    builder.addCase(getAnomalies.fulfilled, (state, action) => {
      state.anomalyDetails = action.payload;
      state.isAnomaliesLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getAnomalies.rejected, (state) => {
      state.isAnomaliesLoading = LoadingState.ERROR;
    });
  },
});

export const anomaliesReducer = anomaliesSlice.reducer;
export const { setInitialState, setEventListPaginationSettings } = anomaliesSlice.actions;
