import { Box } from "@mui/system";
import React, { useState } from "react";
import CustomButton from "../../../components/uiComponents/Button/CustomButton";
import { BlockInfoData } from "../BalansApis";
import AddIcon from "@mui/icons-material/Add";
import { BlockInfoItemDetails } from "./BlockInfoItemDetails";
import { IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface IProps {
  block: BlockInfoData;
  withDetailedInfo?: boolean;

  isGrayColorValue: boolean;

  onModalOpen?: () => void;
}

export const BlockInfoItem = ({ block, withDetailedInfo, isGrayColorValue, onModalOpen }: IProps) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const toggleDetailsVisible = () => {
    setIsDetailsVisible((prev) => !prev);
  };

  const handleModalOpen = () => {
    onModalOpen && onModalOpen();
  };

  const ButtonIcon = () => {
    return <AddIcon style={{ width: "16px", height: "16px" }} />;

    // TODO: Подумать над реализацией кнопки редактирование и добавление
    // return <EditIcon style={{ width: "16px", height: "16px" }} />;
  };

  return (
    <Box sx={styles.container}>
      <Box className="usualText" sx={{ display: "flex", alignItems: "center" }}>
        {block.name}

        {block.editable && (
          <CustomButton type={"offBorders"} style={styles.actionBtn} onClick={handleModalOpen}>
            <ButtonIcon />
          </CustomButton>
        )}
      </Box>
      <Box>
        <Box sx={styles.dataContainer}>
          {block.details && block.details.length > 0 ? (
            <IconButton onClick={toggleDetailsVisible} size="small">
              {isDetailsVisible ? (
                <KeyboardArrowUpIcon sx={styles.array} />
              ) : (
                <KeyboardArrowDownIcon sx={styles.array} />
              )}
            </IconButton>
          ) : (
            <></>
          )}

          <Box className="headerH2" style={withDetailedInfo && isGrayColorValue ? { color: "rgba(0,0,0, 0.5)" } : {}}>
            {block.value !== null ? block.value : "В процессе"}
          </Box>

          <Box sx={styles.subData}>
            <Box className="headerH3" style={{ color: subDataTextColor[block.typeOfAddValue] }}>
              {block.addValue}
            </Box>
            <Box className="chartScales">{block.addValue !== null ? block.addText : ""}</Box>
          </Box>
        </Box>
        {isDetailsVisible && block.details && <BlockInfoItemDetails details={block.details} />}
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    gap: "8px",
  },
  subData: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "4px",
  },
  actionBtn: {
    padding: 0,
    paddingLeft: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  array: {
    fontSize: "inherit",
    color: "#1478BE",
  },
};

const subDataTextColor: { [key: string]: string } = {
  positive: "#4bb8a9d7",
  negative: "#ca807fcc",
};
