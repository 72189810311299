import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/uiComponents/Button/CustomButton";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField/TextField";

interface ElectricityModalInterface {
    active: boolean
    setActive: (b: boolean) => void
    label: string
}

const ElectricityModal: React.FC<ElectricityModalInterface> = ({active, setActive, label}) => {
    const [valueType, setValueType] = useState("parus");
    const [month, setMonth] = useState("1");
    useEffect(() => {

    }, []);

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "10px 10px" }}>
            <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Объект</InputLabel>
                <Select
                    sx={{ height: "40px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={valueType}
                    label="Тип отчета"
                    onChange={(e) => {
                        setValueType(e.target.value);
                    }}
                >
                    <MenuItem
                        value={"parus"}
                        onClick={() => {
                        }}
                    >
                        Парус
                    </MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Месяц</InputLabel>
                <Select
                    sx={{ height: "40px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={month}
                    label="Тип отчета"
                    onChange={(e) => {
                        setMonth(e.target.value);
                    }}
                >
                    <MenuItem
                        value={"1"}
                        onClick={() => {
                        }}
                    >
                        Январь
                    </MenuItem>
                    <MenuItem
                        value={"2"}
                        onClick={() => {
                        }}
                    >
                        Февраль
                    </MenuItem>
                </Select>
            </FormControl>
            <TextField id="outlined-basic" label={label} variant="outlined" size="small" />
            <div style={{display:"flex", width:"100%", justifyContent:"space-between", gap:"12px"}}>
                            <CustomButton style={{width:"100%"}}>Сохранить</CustomButton>
                            <CustomButton style={{width:"100%"}} onClick={()=>setActive(false)}>Отмена</CustomButton>
            </div>
        </div>

    );
};

export default ElectricityModal;
