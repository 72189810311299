import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getTableViewData } from "../../../../store/slices/dataAnalysis/dataAnalysisTableViewSlice";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { LoadingState } from "../../../../types/loadingState";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";

export interface DataAnalysisTableViewProps {
  urlPart: string;
  columns: BaseTableColumnDef[];
}

export const DataAnalysisTableView = (props: DataAnalysisTableViewProps) => {
  const dispatch = useDispatch<any>();

  const data = useTypedSelector((state) => state.dataAnalysisTableView.data);
  const isLoading = useTypedSelector((state) => state.dataAnalysisTableView.isLoading);

  useEffect(() => {
    dispatch(getTableViewData(props.urlPart));
  }, []);

  return (
    <BaseTable columns={props.columns} data={data} isLoading={isLoading === LoadingState.PENDING} useClientPagination />
  );
};
