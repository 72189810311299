import { routeUrls } from "./routeUrls";
import Monitoring from "../pages/monitoring/Monitoring";
import { ObjectInfo } from "../pages/objectInfo/objectInfo";
import Balance from "../pages/balance/Balance";
import { Settings } from "../pages/settings/Settings";
import { UserManagement } from "../pages/userManagement/userManagement";
import { DataAnalysis } from "../pages/dataAnalysis/dataAnalysis";
import { Layout } from "../pages/common/layout/layout";
import { Login } from "../pages/account/login/login";
import { protectedLoader } from "./protectedLoader";
import { loginLoader } from "./loginLoader";
import { ProductionVolume } from "../pages/dataAnalysis/tabs/productionVolume/productionVolume";
import { NetworkSupply } from "../pages/dataAnalysis/tabs/networkSupply/networkSupply";
import { DischangeVolume } from "../pages/dataAnalysis/tabs/dischangeVolume/dischangeVolume";
import { Balance as BalanceAndVolume } from "../pages/dataAnalysis/tabs/balance/balance";
import { Anomalies } from "../pages/dataAnalysis/tabs/anomalies/anomalies";
import { CrossCheckout } from "../pages/dataAnalysis/tabs/crossCheckout/crossCheckout";
import { ModelCustomer } from "../pages/dataAnalysis/tabs/modelCustomer/modelCustomer";
import { redirect } from "react-router-dom";
import { MonitoringEventInfo } from "../pages/monitoringEventInfo/MonitoringEventInfo";
import { PassportData } from "../pages/settings/tabs/passportData/passportData";
import { ElectricityConsumedStandards } from "../pages/settings/tabs/electricityConsumedStandards/electricityConsumedStandards";
import { UsersTabManager } from "../pages/userManagement/components/usersTabManager/usersTabManager";
import { Roles } from "../pages/userManagement/components/roles/roles";
import SummaryData from "../pages/balance/summaryData/SummaryData";
import WaterDischarge from "../pages/balance/waterDischarge/WaterDischarge";
import Electricity from "../pages/balance/electricity/Electricity";
import Zones from "../pages/balance/zones/Zones";
import BG from "../pages/balance/bG/BG";
import { ProtectedRouteWrapper } from "./protectedRouteWrapper";
import { BalanceRouteWrapper } from "./balance/balanceRouteWrapper";
import { NotFound } from "../pages/common/notFound/notFound";
import { HomeRoute } from "./homeRoute";

export const routerConfig = [
  {
    loader: protectedLoader,
    path: routeUrls.home,
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: routeUrls.monitoring,
        element: (
          <ProtectedRouteWrapper path={routeUrls.monitoring}>
            <Monitoring />
          </ProtectedRouteWrapper>
        ),
      },
      {
        path: routeUrls.signalEventInfo,
        element: (
          <ProtectedRouteWrapper path={routeUrls.monitoring}>
            <MonitoringEventInfo />
          </ProtectedRouteWrapper>
        ),
      },
      {
        path: routeUrls.objectInfoPage,
        element: (
          <ProtectedRouteWrapper path={routeUrls.objectInfoPage}>
            <ObjectInfo />
          </ProtectedRouteWrapper>
        ),
      },
      {
        path: routeUrls.objectInfo,
        element: (
          <ProtectedRouteWrapper path={routeUrls.objectInfoPage}>
            <ObjectInfo />
          </ProtectedRouteWrapper>
        ),
      },
      {
        path: routeUrls.balance,
        element: <Balance />,
        children: [
          {
            path: routeUrls.waterSupply,
            element: (
              <BalanceRouteWrapper path={routeUrls.waterSupply}>
                <SummaryData />
              </BalanceRouteWrapper>
            ),
          },
          {
            path: routeUrls.waterDischarge,
            element: (
              <BalanceRouteWrapper path={routeUrls.waterDischarge}>
                <WaterDischarge />
              </BalanceRouteWrapper>
            ),
          },
          {
            path: routeUrls.electricity,
            element: (
              <BalanceRouteWrapper path={routeUrls.electricity}>
                <Electricity />
              </BalanceRouteWrapper>
            ),
          },
          {
            element: (
              <BalanceRouteWrapper path={routeUrls.waterSupply}>
                <SummaryData />
              </BalanceRouteWrapper>
            ),
            index: true,
          },
        ],
      },
      {
        path: routeUrls.zone,
        element: (
          <ProtectedRouteWrapper path={routeUrls.zones}>
            <Zones />
          </ProtectedRouteWrapper>
        ),
      },
      {
        path: routeUrls.bg,
        element: (
          <ProtectedRouteWrapper path={routeUrls.zones}>
            <BG />
          </ProtectedRouteWrapper>
        ),
      },
      {
        path: routeUrls.dataAnalysis,
        element: <DataAnalysis />,
        children: [
          {
            path: routeUrls.productionVolume,
            element: <ProductionVolume />,
          },
          {
            path: routeUrls.networkSupply,
            element: <NetworkSupply />,
          },
          {
            path: routeUrls.dischangeVolume,
            element: <DischangeVolume />,
          },
          {
            path: routeUrls.balanceAndVolume,
            element: <BalanceAndVolume />,
          },
          {
            path: routeUrls.anomalies,
            element: <Anomalies />,
          },
          {
            path: routeUrls.crossCheckout,
            element: <CrossCheckout />,
          },
          {
            path: routeUrls.modelCustomer,
            element: <ModelCustomer />,
          },
          {
            element: <ProductionVolume />,
            index: true,
          },
        ],
      },
      {
        path: routeUrls.settings,
        element: (
          <ProtectedRouteWrapper path={routeUrls.settings}>
            <Settings />
          </ProtectedRouteWrapper>
        ),
        children: [
          {
            path: routeUrls.passport,
            element: <PassportData />,
          },
          {
            path: routeUrls.electricityConsumedStandards,
            element: <ElectricityConsumedStandards />,
          },
          {
            element: <PassportData />,
            index: true,
          },
        ],
      },
      {
        path: routeUrls.users,
        element: (
          <ProtectedRouteWrapper path={routeUrls.users}>
            <UserManagement />
          </ProtectedRouteWrapper>
        ),
        children: [
          {
            path: routeUrls.userList,
            element: <UsersTabManager />,
          },
          {
            path: routeUrls.roleList,
            element: <Roles />,
          },
          {
            element: <UsersTabManager />,
            index: true,
          },
        ],
      },
      {
        path: routeUrls.home,
        element: <HomeRoute />
      },
    ],
  },
  {
    path: routeUrls.login,
    loader: loginLoader,
    element: <Login />,
  },
];
