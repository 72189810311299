import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "../../../types/loadingState";
import { usersApi } from "../../../pages/userManagement/api/usersApi";
import { Role } from "../../../pages/userManagement/types/usersTypes";

const sliceName = "roles";

interface IInitialState {
  rolesData: Role[];

  isLoading: LoadingState;
}

const initialState: IInitialState = {
  rolesData: [],

  isLoading: LoadingState.EMPTY,
};

export const getRoles = createAsyncThunk<Role[]>(`${sliceName}/getRoles`, async (_, thunkAPI) => {
  try {
    const response = await usersApi.getRoles();
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
  }
});

const rolesSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRoles.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.rolesData = action.payload;
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const rolesReducer = rolesSlice.reducer;
export const { setInitialState } = rolesSlice.actions;
