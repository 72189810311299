import axios, { backURLs } from '../../core/APIs';
import { PeriodType } from '../../models/summaryData/periodType';

// Получение списка Зон
export interface ZoneShortInfo {
  id: number;
  fullName: string;
  number: string;
}

export async function GetZonesList() {
  const { data } = await axios.get<ZoneShortInfo[]>(`${backURLs.zones}/zone-list`);
  return data;
}

// Пончиковая диаграмма
export interface counterContractsProps {
  data: number[];
  labels: string[];
  colors: string[];
}

// Пончиковая диаграмма в сбыту по контрактам
export const GetCounterContracts = async (zoneNumber: string) => {
  const { data } = await axios.get<counterContractsProps>(`${backURLs.counterContracts}/counter-contracts/${zoneNumber}`);

  return data;
};

// Интерфейс для диаграмм с несколькими данными (с категориями)
export interface summaryGraphProps {
  data: dataInterface[];
  categories: string[];
}

// Интерфейс для одной линии (в графике)
export interface dataInterface {
  name: string; // название
  data: number[]; // данные
  color: string; // цвет линии
}

// Запрос для графа подачи воды в Геленджик
export const GetWaterSupplyByPeriod = async (period: PeriodType, yearToCompare?: string) => {
  const { data } = await axios.get(`${backURLs.waterSupplySummary}/supply-sale-water`, {
    params: {
      period,
      yearToCompare,
      formatCategories: period === 'month' ? 'ddmmmm' : 'mmmm',
    },
  });

  return data;
};

export const GetWaterSupplyByDateRange = async (startDate: Date, endDate: Date, yearToCompare?: string) => {
  const differenceInDays = (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24;
  const { data } = await axios.get(`${backURLs.waterSupplySummary}/supply-sale-water-dates`, {
    params: {
      startDate,
      endDate,
      yearToCompare,
      formatCategories: differenceInDays < 32 ? 'ddmmmm' : 'mmmm',
    },
  });

  return data;
};

// Получение данных для карточек в Сводных Данных
export interface BlocksInfoCard {
  header: string;
  blocks: BlockInfoData[];
  name: string;
}

export interface BlockInfoData {
  name: string;
  value: string;
  addValue: string;
  typeOfAddValue: string;
  addText: string;
  editable: boolean;
  details?: BlockInfoDataDetail[];
}
export interface BlockInfoDataDetail {
  name: string;
  value: string;
}

export async function GetCardsByPeriod(period: PeriodType | null) {
  const { data } = await axios.get<BlocksInfoCard[]>(`${backURLs.waterSupplySummary}/cards`, { params: { period } });

  return data;
}

export async function GetCardsByDateRange(startDate: Date, endDate: Date) {
  const { data } = await axios.get<BlocksInfoCard[]>(`${backURLs.waterSupplySummary}/cards-dates`, {
    params: { startDate, endDate },
  });

  return data;
}

// Получение данных для карточек в Зоне.Подача
export async function GetSupplyCard(StartDate: string, EndDate: string, ZoneNumber: string) {
  const { data } = await axios.get<BlocksInfoCard>(`${backURLs.supply}/supply-stats-card/?StartDate=${StartDate}&EndDate=${EndDate}&ZoneNumber=${ZoneNumber}`);

  return data;
}

// Получение данных для карточек в Зоне.Сбыт
export async function GetDistributionCard(zoneNumber: string) {
  const { data } = await axios.get<BlocksInfoCard[]>(`${backURLs.summary}/person-statistics-cards/${zoneNumber}`);

  return data;
}
export interface counterConsumptionProps {
  series: number[];
  labels: string[];
  colors: string[];
}
// Пончиковая диаграмма в сбыту по поданной воде
export const GetConsumption = async (zoneNumber: string) => {
  const { data } = await axios.get<counterConsumptionProps>(`${backURLs.consumption}/water-consumption-amount?zoneNumber=${zoneNumber}`);

  return data;
};

// Интерфейс для графиков с датами и временем
export interface ChartData {
  dates: string[]; // Массив дат
  series: dataInterface[]; //
}

// Запрос для графика в Зона.Подача
export async function GetSupplyChartData(startDate: string, endDate: string, zoneNumber: string) {
  const { data } = await axios.get<ChartData>(`${backURLs.supply}/supply-by-zone?StartDate=${startDate}&EndDate=${endDate}&ZoneNumber=${zoneNumber}`);

  return data;
}

//
export interface BalanceChartProps {
  losses: number[];
  categories: string[];
}

// Запрос для графика потерь в Сводных Данных
export const getLossesChartData = async (period: string, units: string) => {
  const { data } = await axios.post<BalanceChartProps>(`${backURLs.balances}/balances/balance-by-zones-chart?period=${period}&units=${units}`);

  return data;
};

interface seriasInterface {
  data: number[];
  name: string;
  group?: string;
  color: string;
  groupIndex?:string|number;
}

export interface lossInterface {
  series: seriasInterface[];
  categories: number[];
}

// Запрос для графика в  Зона.Потери
export async function GetLoss(Period: string, ZoneNumber: string, IsPercent: boolean) {
  const { data } = await axios.get<lossInterface>(`${backURLs.loss}/get-water-losses-by-zone?Period=${Period}&ZoneNumber=${ZoneNumber}&IsPercent=${IsPercent}`);

  return data;
}

export interface salesChartInterface {
  series: seriasInterface[];
  categories: number[];
}

// Запрос для графика в Зона.Сбыт.Начисления
export async function GetZoneSalesSeries(period: string, zoneNumber: string, statisticsSubject: string, isRubles: string) {
  const { data } = await axios.get<lossInterface>(`${backURLs.zoneWaterSales}/sales-series?`, {
    params: { period, zoneNumber, statisticsSubject, isRubles },
  });
  // TODO
  data.series.forEach(series=>{
    series.group = series.groupIndex?.toString()
  })
  return data;
}

interface chartDataXYFormat {
  x: string;
  y: number[] | number;
}

// Интерфейс для запросов со смешенным типом графиков и по временной оси
export interface ChartCombineInterface {
  color: string;
  data: chartDataXYFormat[];
  name: string;
  type: string;
}

// Запрос для графика в Зона.Сбыт.Прогнозирование
export async function GetZoneConsumptionPrediction(period: string, zoneNumber: string, statisticsSubject: string, isRubles: string) {
  const { data } = await axios.get<ChartCombineInterface[]>(
    `${backURLs.predictionServiceBaseUrl}/get_consumption_statistics_forecast?zoneNumber=${zoneNumber}&Period=${period}&StatisticsSubject=${
      statisticsSubject.charAt(0).toUpperCase() + statisticsSubject.slice(1)
    }&IsRubles=${isRubles}`,
  );
  return data;
}

//Зона.Сбыт.Таблица

export interface ZoneConsumptionTable {
  id: number;
  bG: string;
  pU: number;
  payment: number;
}

export async function GetZoneConsumptionTable(period: string, zoneNumber: string, statisticsSubject: string) {
  const { data } = await axios.get<ZoneConsumptionTable[]>(`${backURLs.consumption}/table`, { params: { statisticsSubject, period, zoneNumber } });
  return data;
}

// Зона.Подача.Таблица
export interface ZoneSupplyTable {
  id: number;
  group: string;
  released: number;
  givenAway: number;
}

export async function GetZoneSupplyTable(zoneNumber: string, DtStart: string, DtEnd: string) {
  const { data } = await axios.get<ZoneSupplyTable[]>(`${backURLs.supply}/table?ZoneNumber=${zoneNumber}&DtStart=${DtStart}&DtEnd=${DtEnd}`);
  return data;
}

// Зона.Потери.Таблица
export interface zoneLossTable {
  id: number | string;
  bg: string;
  lossNow: number;
  lossPrev: number;
  predLossNow: number;
}

export async function GetZoneLossesTable(period: string, zoneNumber: string, isPercent: boolean) {
  const { data } = await axios.get<zoneLossTable[]>(`${backURLs.balances}/zone-losses/table?period=${period}&zoneNumber=${zoneNumber}&isPercent=${isPercent}`);
  return data;
}

// ***___БАЛАНСОВЫЕ ГРУППЫ___***
// Запрос для получения списка балансовых групп
export interface BalanceGroupShortInfo {
  id: number;
  fullName: string;
}

export async function GetBalanceGroupsByZone(zoneNumber: string) {
  const { data } = await axios.get<BalanceGroupShortInfo[]>(`${backURLs.zones}/balance-group-list?zoneNumber=${zoneNumber}`);

  return data;
}

// Запрос для графика в БГ.Подача
export async function GetBalanceGroupSupply(startDate: string, endDate: string, zoneNumber: string, balanceGroupId: number) {
  const { data } = await axios.get<ChartData>(
    `${backURLs.supply}/supply-by-balance-group?StartDate=${startDate}&EndDate=${endDate}&ZoneNumber=${zoneNumber}&BalanceGroupId=${balanceGroupId}`,
  );
  return data;
}

// Зона.БГ.Пасспорт.Таблица
export interface BGPassportTable {
  guid: string;
  name: string;
  address: string;
  type: string;
  typeObject: string;
}

// Зона.БГ.Паспорт.Таблица
export interface BGPassportTable {
  guid: string;
  name: string;
  address: string;
  type: string;
  typeObject: string;
}

export async function GetBGPassportTable(zoneNumber: string, bgNumber: number) {
  const { data } = await axios.get<BGPassportTable[]>(`${backURLs.balansGroup}/passport-table/${zoneNumber}/${bgNumber}`);
  return data;
}

// БГ.Паспорт.Карта
export async function GetBGGeolocation(zoneNumber: string, balanceGroupId: number) {
  const { data } = await axios.get(`${backURLs.balansGroup}/geojson/${zoneNumber}/${balanceGroupId}`);
  return data;
}

// Получение данных для карточек в БГ.Подача
export async function GetSupplyBGCard(StartDate: string, EndDate: string, ZoneNumber: string, bgNumber: number) {
  const { data } = await axios.get<BlocksInfoCard>(
    `${backURLs.supply}/supply-stats-card-by-balance-group/?StartDate=${StartDate}&EndDate=${EndDate}&ZoneNumber=${ZoneNumber}&BalanceGroupId=${bgNumber}`,
  );

  return data;
}

// БГ.Подача.Таблица
export interface BGSupplyTable {
  id: number;
  date: string;
  released: number;
  givenAway: number;
}
interface data {
  data: BGSupplyTable[];
}
export async function GetBGSupplyTable(zoneNumber: string, bgNumber: number, DtStart: string, DtEnd: string) {
  const { data } = await axios.get<data>(
    `${backURLs.supply}/supply-table-by-balance-group?StartDate=${DtStart}&EndDate=${DtEnd}&ZoneNumber=${zoneNumber}&BalanceGroupId=${bgNumber}`,
  );
  return data.data;
}

// Запрос для графика в  Зона.Потери
export interface lossBGInterface {
  series: seriasInterface[];
  categories: number[];
}

export async function GetBGLoss(period: string, ZoneNumber: string, bgNumber: number, IsPercent: string) {
  const { data } = await axios.get<lossBGInterface>(
    `${backURLs.loss}/get-water-losses-by-balance-group?period=${period}&zoneNumber=${ZoneNumber}&balanceGroupId=${bgNumber}&isPercent=${IsPercent}`,
  );

  return data;
}

// Карточки бг.сбыт.карточки
export async function GetSummaryBGData(zoneNumber: string, bGNumber: number) {
  const { data } = await axios.get<BlocksInfoCard[]>(`${backURLs.summary}/person-statistics-cards/${zoneNumber}/${bGNumber}`);

  return data;
}

// Запрос для графика в  БГ.Реализация.Потребление
export async function GetSaleBG(period: string, ZoneNumber: string, bGNumber: number) {
  const { data } = await axios.get<lossInterface>(`${backURLs.balansGroup}/sales-series?Period=${period}&ZoneNumber=${ZoneNumber}&BalanceGroupId=${bGNumber}`);
  // TODO
  data.series.forEach(series=>{
    series.group = series.groupIndex?.toString()
  })
  return data
}

// Запрос для графика в  БГ.Сбыт.Потребление
export async function GetAccStatBG(statisticsSubject: string, Period: string, ZoneNumber: string, bgNumber: number, isRubles: string) {
  const { data } = await axios.get<lossInterface>(
    `${backURLs.consumptionZone}/get-consumption-statistics?statisticsSubject=${statisticsSubject}&Period=${Period}&ZoneNumber=${ZoneNumber}&BalanceGroupId=${bgNumber}&isRubles=${isRubles}`,
  );

  return data;
}

// Запрос для БГ.Сбыт.Прогноз Потребления
export async function GetBGConsumptionPrediction(period: string, zoneNumber: string, statisticsSubject: string, isRubles: boolean, balanceGroupId: number) {
  const { data } = await axios.get<ChartCombineInterface[]>(
    `${backURLs.consumption}/consumption?ZoneNumber=${zoneNumber}&Period=${period}&StatisticsSubject=${statisticsSubject}&IsRubles=${isRubles}&BalanceGroupId=${balanceGroupId}`,
  );
  return data;
}

// Пончиковая диаграмма в сбыту по поданной воде БГ
export const GetConsumptionBG = async (zoneNumber: string, bgNumber: number) => {
  const { data } = await axios.get<counterConsumptionProps>(`${backURLs.consumption}/water-consumption-amount/${zoneNumber}/${bgNumber}`);

  return data;
};
// Пончиковая диаграмма в сбыту по контрактам БГ
export const GetCounterContractsBG = async (zoneNumber: string, bgNumber: number) => {
  const { data } = await axios.get<counterContractsProps>(`${backURLs.counterContracts}/counter-contracts/${zoneNumber}/${bgNumber}`);

  return data;
};
export interface BGConsumptionTable {
  id: number;
  mounth: string;
  pU: number;
  payment: number;
}

// Таблица сбыт бг
export async function GetBGConsumptionTable(zoneNumber: string, BGNumber: number) {
  const { data } = await axios.get<BGConsumptionTable[]>(`${backURLs.consumption}/balance-group-table?ZoneNumber=${zoneNumber}&BalanceGroupId=${BGNumber}`);
  return data;
}

// БГ.Потери.Таблица
export interface BGLossTable {
  id: number | string;
  month: string;
  lossNow: number;
  lossPrev: number;
  predLossNow: number;
}

export async function GetBGLossesTable(zoneNumber: string, BGNumber: number, isPercent: string) {
  const { data } = await axios.get<BGLossTable[]>(
    `${backURLs.balances}/zone-losses/balance-group-table?zoneNumber=${zoneNumber}&balanceGroupId=${BGNumber}&isPercent=${isPercent}`,
  );
  return data;
}

///Водоотведение

// Запрос для графика в  водоотведение
export async function GetSewerage(Period: string) {
  const { data } = await axios.get<ChartData>(`${backURLs.sewerage}/chart/${Period}?years=4`);
  console.log(data);
  return data;
}
//водоотведение таблица
export interface SewerageTable {
  month: string;
  total: number;
  realization: number;
  addInflow: number;
}

export async function GetSewerageTable() {
  const { data } = await axios.get<SewerageTable[]>(`${backURLs.sewerage}/table`);
  return data;
}
// Получение данных для карточек в Зоне.Подача
export async function GetSewerageCard() {
  const { data } = await axios.get<BlocksInfoCard>(`${backURLs.sewerage}/card`);

  return data;
}
/// Отчеты
export interface typeReports {
  displayName: string;
  value: string;
}

export async function GetTypeReports() {
  const { data } = await axios.get<typeReports[]>(`${backURLs.reports}/analytics-types`);
  return data;
}

export const DownloadFile = async (value: string) => {
  const { data } = await axios.get(`${backURLs.reports}/analytics?reportType=${value}`, {
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    responseType: 'blob',
  });

  return data;
};

// Плановые потери
export interface IPlanLossesData {
  losses: number[];
}

export const getPlanLossesData = async () => {
  const res = await axios.get<IPlanLossesData>(`${backURLs.planLoss}`);
  return res;
};

export const savePlanLossesData = async (data: IPlanLossesData) => {
  await axios.post(`${backURLs.planLoss}`, data);
};
