import { useDispatch } from "react-redux";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";
import { useEffect } from "react";
import { getAnomalies, updateAnomalyNote } from "../../../../store/slices/dataAnalysis/anomaliesSlice";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { LoadingState } from "../../../../types/loadingState";
import { AnomalyDetailDto } from "../../../../models/dataAnalysis/anomalies/anomalyDetailDto";
import { eventTypeCellRenderer } from "../../../../widgets/eventsTable/eventTypeCellRenderer/eventTypeCellRenderer";
import { setYupLocales } from "../../../../utilities/validation/setYupLocales";
import { ObjectSchema, object, string } from "yup";
import { AnomalyDetailUpdateDto } from "../../../../models/dataAnalysis/anomalies/anomalyDetailUpdateDto";

setYupLocales();
const validationSchema: ObjectSchema<AnomalyDetailUpdateDto> = object({
  signalEventGuid: string().optional(),
  anomalyNote: string().required().label("Аномалия"),
});

export const AnomaliesTable = () => {
  const dispatch = useDispatch<any>();

  const data = useTypedSelector((state) => state.anomalies.anomalyDetails);
  const isLoading = useTypedSelector((state) => state.anomalies.isAnomaliesLoading);

  const columns: BaseTableColumnDef[] = [
    {
      headerName: "Инцидент",
      field: "type",
      renderCell: eventTypeCellRenderer,
    },
    {
      headerName: "Объект",
      field: "objectName",
    },
    {
      headerName: "Наименование датчика",
      field: "deviceName",
    },
    {
      headerName: "Сигнал",
      field: "indicationName",
    },
    {
      headerName: "Дата и время",
      field: "dateTime",
      type: "dateTime",
    },
    {
      headerName: "Показание",
      field: "value",
    },
    {
      headerName: "Норма",
      field: "normValue",
    },
    {
      headerName: "Отклонение",
      field: "variance",
    },
    {
      headerName: "Аномалия",
      field: "anomalyNote",
      editable: true,
    },
  ];

  const loadAnomaliesDetails = () => {
	dispatch(getAnomalies());
  }

  const handleSave = async (values: AnomalyDetailDto) => {
    await dispatch(updateAnomalyNote({ signalEventGuid: values.guid, anomalyNote: values.anomalyNote! }));
	loadAnomaliesDetails();
  };

  useEffect(() => {
    loadAnomaliesDetails();
  }, []);

  return (
    <>
      <BaseTable
        data={data}
        columns={columns}
        isLoading={isLoading === LoadingState.PENDING}
        idGetter={(item: AnomalyDetailDto) => item.guid}
        useClientPagination
        editable
        validationSchema={validationSchema}
        onSave={handleSave}
      />
    </>
  );
};
