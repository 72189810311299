import { redirect } from "react-router-dom";
import { routeUrls } from "./routeUrls";

export const protectedLoader = () => {
  const isAuthenticated = Boolean(localStorage.getItem("accessToken"));
  if (!isAuthenticated) {
    return redirect(routeUrls.login);
  }

  return null;
};
