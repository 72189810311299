import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../reducers";
import { AuthenticationDataDto } from "../../../models/account/authenticationDataDto";
import { accountApi } from "../../../pages/account/accountApi";
import { LoadingState } from "../../../types/loadingState";
import { ForgotPasswordDataDto } from "../../../models/account/forgotPasswordDataDto";
import { FormType } from "../../../models/account/formType";

const sliceName = "login";

interface IInitialState {
  displayedForm: FormType;

  isLoginLoading: LoadingState;
  isSendEmailWithCredentialsLoading: LoadingState;
}

const initialState: IInitialState = {
  displayedForm: FormType.Login,
  isLoginLoading: LoadingState.EMPTY,
  isSendEmailWithCredentialsLoading: LoadingState.EMPTY,
};

export const authenticate = createAsyncThunk<void, AuthenticationDataDto, { state: RootState }>(
  `${sliceName}/authenticate`,
  async (form, thunkAPI) => {
    try {
      const response = await accountApi.autenticate(form);
      localStorage.setItem("accessToken", response.data);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data || "Ошибка соединения. Повторите попытку");
    }
  }
);

export const sendEmailWithcredentials = createAsyncThunk<void, ForgotPasswordDataDto, { state: RootState }>(
  `${sliceName}/sendEmailWithcredentials`,
  async (form, thunkAPI) => {
    try {
      await accountApi.sendEmailWithcredentials(form);
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data || "Ошибка соединения. Повторите попытку");
    }
  }
);

export const logout = createAsyncThunk<void, void, { state: RootState }>(
  `${sliceName}/getUsers`,
  async (_, thunkAPI) => {
    localStorage.removeItem("accessToken");
  }
);

const loginSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCurrentDisplayedForm: (state, action) => {
      state.displayedForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticate.pending, (state) => {
      state.isLoginLoading = LoadingState.PENDING;
    });
    builder.addCase(authenticate.fulfilled, (state) => {
      state.isLoginLoading = LoadingState.SUCCESS;
    });
    builder.addCase(authenticate.rejected, (state) => {
      state.isLoginLoading = LoadingState.ERROR;
    });

    builder.addCase(sendEmailWithcredentials.pending, (state) => {
      state.isSendEmailWithCredentialsLoading = LoadingState.PENDING;
    });
    builder.addCase(sendEmailWithcredentials.fulfilled, (state) => {
      state.isSendEmailWithCredentialsLoading = LoadingState.SUCCESS;
    });
    builder.addCase(sendEmailWithcredentials.rejected, (state) => {
      state.isSendEmailWithCredentialsLoading = LoadingState.ERROR;
    });
  },
});

export const loginReducer = loginSlice.reducer;
export const { setCurrentDisplayedForm } = loginSlice.actions;
