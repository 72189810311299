import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EventsDataDto } from "../../../models/events/eventsData";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";
import { monitoringEventApi } from "../../../pages/monitoring/api/monitoringEventApi";
import { EventStatisticCardDto } from "../../../models/eventStatisticCard/eventStatisticCardDto";
import { EventItemDto } from "../../../models/events/eventItemDto";
import { EventKeyDto } from "../../../models/events/eventKeyDto";
import { SignalType } from "../../../models/events/signalType";
import { DefaultPaginationSettings, PaginationSettings } from "../../../models/common/paginationSettings";

const sliceName = "monitoringEvents";

interface InitialState {
  eventsData?: EventsDataDto;
  paginationSettings: PaginationSettings;

  events: EventItemDto[];

  eventStatistics: EventStatisticCardDto[];

  isStatisticsLoading: LoadingState;
  isLoading: LoadingState;
}

const initialState: InitialState = {
  paginationSettings: DefaultPaginationSettings,

  events: [],
  eventStatistics: [],

  isStatisticsLoading: LoadingState.EMPTY,
  isLoading: LoadingState.EMPTY,
};

export const getMonitoringEventsData = createAsyncThunk<EventsDataDto, void, { state: RootState }>(
  `${sliceName}/getMonitoringEventsData`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState().monitoringEvents;
      const response = await monitoringEventApi.getEventList(
        state.paginationSettings.currentPage,
        state.paginationSettings.pageSize
      );
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const sendEvent = createAsyncThunk<EventKeyDto, EventKeyDto, { state: RootState }>(
  `${sliceName}/sendEvent`,
  async (key, thunkAPI) => {
    try {
      await monitoringEventApi.sendEvent(key);
      return key;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const acceptEvent = createAsyncThunk<EventKeyDto, EventKeyDto, { state: RootState }>(
  `${sliceName}/acceptEvent`,
  async (key, thunkAPI) => {
    try {
      await monitoringEventApi.acceptEvent(key);
      return key;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const getEventsStatistics = createAsyncThunk<EventStatisticCardDto[], void, { state: RootState }>(
  `${sliceName}/getEventsStatistics`,
  async (_, thunkAPI) => {
    try {
      const response = await monitoringEventApi.getEventsStatistics();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const monitoringEventsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setPaginationSettings: (state, action) => {
      state.paginationSettings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMonitoringEventsData.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getMonitoringEventsData.fulfilled, (state, action) => {
      action.payload.page?.data?.forEach((x) => (x.key.sourceTime = new Date(x.key.sourceTime).toISOString()));
      state.eventsData = action.payload;
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getMonitoringEventsData.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });

    builder.addCase(getEventsStatistics.pending, (state) => {
      state.isStatisticsLoading = LoadingState.PENDING;
    });
    builder.addCase(getEventsStatistics.fulfilled, (state, action) => {
      state.eventStatistics = action.payload;
      state.isStatisticsLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getEventsStatistics.rejected, (state) => {
      state.isStatisticsLoading = LoadingState.ERROR;
    });

    builder.addCase(sendEvent.fulfilled, (state, action) => {
      const event = state.eventsData?.page?.data.find((x) => JSON.stringify(x.key) === JSON.stringify(action.payload));
      if (event) {
        event.feature = SignalType.Send;
        event.handledTime = new Date();
      }
    });

    builder.addCase(acceptEvent.fulfilled, (state, action) => {
      const event = state.eventsData?.page?.data.find((x) => JSON.stringify(x.key) === JSON.stringify(action.payload));
      if (event) {
        event.feature = SignalType.Accepted;
        event.handledTime = new Date();
      }
    });
  },
});

export const monitoringEventsReducer = monitoringEventsSlice.reducer;
export const { setInitialState, setPaginationSettings } = monitoringEventsSlice.actions;
