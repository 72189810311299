import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { ApexLocales } from "../../../../constants/ApexChartsSettings";
import ModalWindow from "../../../../components/ordinary/ModalWindow/ModalWindow";
import moment from "moment";
import ElectricityModal from "../../electricity/ElectricityModa";

interface ElectricityZoneInterface {
  // zoneNumber: string;
}

const ElectricityZone: React.FC<ElectricityZoneInterface> = () => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Объект",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "month",
      headerName: "Месяц",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "electicity",
      headerName: "Удельное потребление, кВт*ч/м3",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "norm",
      headerName: "Норматив, кВт*ч/м3",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
  ];

  const rows = [
    {
      id: 1,
      name: "-",
      month: "Январь",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 2,
      name: "-",
      month: "Февраль",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 3,
      name: "-",
      month: "Март",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 4,
      name: "-",
      month: "Апрель",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 5,
      name: "-",
      month: "Май",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 6,
      name: "-",
      month: "Июнь",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 7,
      name: "-",
      month: "Июль",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 8,
      name: "-",
      month: "Август",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 9,
      name: "-",
      month: "Сентябрь",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 10,
      name: "-",
      month: "Октябрь",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 11,
      name: "-",
      month: "Ноябрь",

      electicity: 0,
      norm: 1.55,
    },
    {
      id: 12,
      name: "-",
      month: "Декабрь",

      electicity: 0,
      norm: 1.55,
    },
  ];

  const [period, setPeriod] = useState("year");
  const [label, setLabel] = useState("");
  const [isLoadingLine, setIsLoadingLine] = useState(false);
  const buttonSensor = ["buttonYear", "buttonHalfYear", "buttonQuarter", "buttonQuarterYear", "buttonMonth"];
  const [lineGraph, setLineGraph] = useState<ApexOptions>({
    series: [
      {
        name: "Удельное потребление",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        color: "#777777",
      },
      {
        name: "Медина норматива",
        data: [1.55, 1.55, 1.55, 1.55, 1.55, 1.55, 1.55, 1.55, 1.55, 1.55, 1.55, 1.55],
        color: "#4BB8A9",
      },
    ],
    chart: {
      id: "mainChart",
      animations: {
        enabled: false,
      },
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
      events: {
        // mounted: (_, options) => {
        //   options?.config?.series?.forEach((data: { name: string }) => {
        //     if (data.name !== "Удельное потребление") {
        //       ApexCharts.exec("mainChart", "hideSeries", data.name);
        //     }
        //   });
        // },
      },
    },

    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
    },
    xaxis: {
      categories: ["Янв", "Фев", "Мар", "Aпр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(2) + ' кВт*ч/м3';
        }
      }
    }
  });
  const [openWindow, setOpenWindow] = useState(false);

  const [isLoadingTable, setIsLoadingTable] = useState(false);

  // Функция смены класса кнопки периода
  function buttonClassChange(id: string) {
    // Находим кнопку по id
    var btn = document.getElementById(id);
    // Была ли кнопка найдена
    if (btn !== null) {
      btn.classList.add("timePeriodButtonActive");
    }
    if (btn !== null || id === "undefined") {
      buttonSensor.forEach((button) => {
        if (button !== id) {
          var btn1 = document.getElementById(button);
          if (btn1 !== null) {
            btn1.classList.remove("timePeriodButtonActive");
          }
        }
      });
    }
  }

  // Функция выбора периода
  function setTimePeriod(type: string) {
    let per = "";

    switch (type) {
      case "year":
        per = "year";
        break;
      case "quarter":
        per = "quarter";
        break;

      case "month":
        per = "month";
        break;

      default:
        per = "year";
    }
    setPeriod(per);
  }

  useEffect(() => {
    buttonClassChange("buttonYear");
  }, []);

  return (
    <div className="balanceTabDefault" style={{ gap: "16px" }}>
      <div className="leftLegendaDate">
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonYear"
          onClick={() => {
            buttonClassChange("buttonYear");
            setTimePeriod("year");
          }}
        >
          12 мес
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonQuarterYear"
          onClick={() => {
            buttonClassChange("buttonQuarterYear");
            setTimePeriod("quarter");
          }}
        >
          6 мес
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonMonth"
          onClick={() => {
            buttonClassChange("buttonMonth");
            setTimePeriod("month");
          }}
        >
          3 мес
        </CustomButton>
      </div>
      <div style={{ height: "357px", width: "100%" }}>
        {/* {!isLoadingLine ? (
                    <Animation></Animation>
                ) : ( */}
        <Chart height={"100%"} width={"100%"} options={lineGraph} series={lineGraph.series} type="line" />
        {/* )} */}
      </div>
      {/* <div style={{ display: "flex", justifyContent: "start", alignItems: 'center', gap: "12px" }}>
                <CustomButton onClick={() => { setOpenWindow(true); setLabel("Норматив") }}>Внести Норматив</CustomButton>
            </div> */}
      <ModalWindow active={openWindow} setActive={setOpenWindow}>
        <ElectricityModal active={openWindow} setActive={setOpenWindow} label={label}></ElectricityModal>
      </ModalWindow>
      <Box sx={{ width: "100%" }} className="electricityTable tableHeight">
        {/* {!isLoadingTable ? (
                    <Animation></Animation>
                ) : (
                     */}
        <DataGrid
          rows={rows}
          columns={columns}
          //pageSize={20}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        />
        {/* )} */}
      </Box>
    </div>
  );
};

export default ElectricityZone;
