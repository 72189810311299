import { Box } from "@mui/system";
import React, { useState } from "react";
import { BlockInfoData } from "../BalansApis";
import { blockInfoModalConfig } from "../configs/blockInfoConfig";
import { BlockInfoItem } from "./BlockInfoItem";

export interface BlockInfoProps {
  style?: object;
  withDetailedInfo?: boolean;

  name?: string;
  header: string;
  blocks: BlockInfoData[];

  onModalSave?: () => void;
}

const BlockInfo: React.FC<BlockInfoProps> = ({
  name,
  header,
  blocks,
  style,
  withDetailedInfo = false,
  onModalSave,
}) => {
  const [modalActive, setModalActive] = useState(false);

  const handleModalOpen = () => {
    setModalActive(true);
  };

  const handleModalClose = () => {
    setModalActive(false);
  };

  const handleModalSave = () => {
    onModalSave && onModalSave();
    handleModalClose();
  };

  const BlockInfoModal = blockInfoModalConfig.find((i) => i.name === name)?.modal;

  return (
    <Box sx={styles.container} style={style}>
      <Box className="headerH3">{header}</Box>
      <Box sx={styles.listContainer}>
        {blocks.map((block, index) => (
          <BlockInfoItem
            withDetailedInfo={withDetailedInfo}
            block={block}
            key={index}
            isGrayColorValue={index !== 0}
            onModalOpen={handleModalOpen}
          />
        ))}
      </Box>
      {modalActive && BlockInfoModal && <BlockInfoModal onSave={handleModalSave} onCancel={handleModalClose} />}
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    gap: "8px",

    minWidth: "280px",
    maxWidth: "350px",

    alignSelf: "stretch",

    background: "#f7f8f9",
    boxShadow: "0px 4px 14px rgba(3, 0, 124, 0.1)",
    borderRadius: "8px",

    "@media screen and (max-height: 800px)": {
      paddingTop: "12px",
      paddingBottom: "12px",
    },
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
};

export default BlockInfo;
