import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { ConfirmationModal } from "../../../../components/confirmationModal/confirmationModal";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { DefaultPageSizeOptions, PaginationSettings } from "../../../../models/common/paginationSettings";
import {
  deleteUser,
  getUsers,
  setInitialState,
  setPaginationSettings,
  setSelectedUserGuid,
} from "../../../../store/slices/userManagement/usersSlice";
import { LoadingState } from "../../../../types/loadingState";
import { setCurrentUserGuid } from "../../../../store/slices/userManagement/usersAccessEditorSlice";
import { CreateEditUserModal } from "../createEditUserModal/createEditUserModal";

interface IProps {
  onShowUserAccessEditorClick?: () => void;
}

export const Users = ({ onShowUserAccessEditorClick }: IProps) => {
  const dispatch = useDispatch<any>();

  const [showDeleteConfirmationModalOpen, setShowDeleteConfirmationModalOpen] = useState(false);
  const [showCreateEditUserModal, setShowCreateEditUserModal] = useState(false);
  const [userIdForEdit, setUserIdForEdit] = useState<string>();

  const tableData = useTypedSelector((state) => state.users.tableData);
  const paginationSettings = useTypedSelector((state) => state.users.paginationSettings);
  const totalItems = useTypedSelector((state) => state.users.totalItems);

  const isLoading = useTypedSelector((state) => state.users.isLoading);

  const columns: GridColDef[] = [
    {
      field: "firstName",
      headerName: "ФИО",
      flex: 1,
      valueGetter: ({ row }) => {
        return `${row.firstName} ${row.middleName} ${row.lastName}`;
      },
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "department",
      headerName: "Подразделение",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "position",
      headerName: "Должность",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "role",
      headerName: "Роль",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{ display: "flex", gap: "8px", justifyContent: "space-between", alignItems: "center", width: "100%" }}
          >
            <Box>{params.value.name}</Box>
            <Button onClick={() => handleOpenUserAccessEditor(params.id as string)}>Настр.</Button>
          </Box>
        );
      },
    },
    {
      field: "district",
      headerName: "Район",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "login",
      headerName: "Логин",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Ред / Уд",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: "10px" }}>
            <IconButton onClick={() => handleEditUserButtonClicked(params.id as string)}>
              <EditIcon sx={{ color: "#1599D7" }} />
            </IconButton>
            <IconButton onClick={() => handleOpenDeleteConfirmationModal(params.id as string)}>
              <DeleteIcon sx={{ color: "#1599D7" }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleOpenUserAccessEditor = async (userGuid: string) => {
    await dispatch(setCurrentUserGuid(userGuid));
    onShowUserAccessEditorClick && onShowUserAccessEditorClick();
  };

  const handleOpenDeleteConfirmationModal = async (userGuid: string) => {
    await dispatch(setSelectedUserGuid(userGuid));
    setShowDeleteConfirmationModalOpen(true);
  };

  const handleOkDeleteConfirmationModal = async () => {
    setShowDeleteConfirmationModalOpen(false);
    await dispatch(deleteUser());
    dispatch(getUsers());
  };

  const handleCloseDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModalOpen(false);
  };

  const handlePaginationChange = (paginationSettings: PaginationSettings) => {
    dispatch(setPaginationSettings({ ...paginationSettings, currentPage: paginationSettings.currentPage + 1 }));
  };

  const handleCancelCreateEditUserModal = () => {
    setShowCreateEditUserModal(false);
    setUserIdForEdit(undefined);
  };

  const handleSaveCreateEditUserModal = () => {
    setShowCreateEditUserModal(false);
    setUserIdForEdit(undefined);
    dispatch(getUsers());
  };

  const handleEditUserButtonClicked = (userId: string) => {
    setUserIdForEdit(userId);
    setShowCreateEditUserModal(true);
  }

  useEffect(() => {
    return () => dispatch(setInitialState());
  }, []);

  useEffect(() => {
    dispatch(getUsers());
  }, [paginationSettings]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 100%" }}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={() => setShowCreateEditUserModal(true)}>Добавить пользователя</Button>
        </Box>
        <BaseTable
          columns={columns}
          data={tableData}
          currentPage={paginationSettings.currentPage - 1}
          pageSize={paginationSettings.pageSize}
          pageSizeOptions={DefaultPageSizeOptions}
          totalItems={totalItems}
          onChangePagination={handlePaginationChange}
          isLoading={isLoading === LoadingState.PENDING}
        />
      </Box>
      {showDeleteConfirmationModalOpen && (
        <ConfirmationModal
          title="Подтвердите действие"
          message="Вы действительно хотите удалить учетную запись пользователя?"
          okClick={handleOkDeleteConfirmationModal}
          cancelClick={handleCloseDeleteConfirmationModal}
        />
      )}
      {showCreateEditUserModal && (
        <CreateEditUserModal onCancel={handleCancelCreateEditUserModal} onSave={handleSaveCreateEditUserModal} id={userIdForEdit} />
      )}
    </>
  );
};
