import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSettingsTableName } from "../../../../store/slices/settings/SettingsTableSlice";
import { SettingsTable } from "../../components/settingsTable/SettingsTable";
import { ObjectSchema, bool, number, object, string } from "yup";
import { setYupLocales } from "../../../../utilities/validation/setYupLocales";
import { BaseTableDataItem } from "../../../../models/baseTable/baseTableDataItem";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";
import { ElectricityConsumedStandardsDeleteDto } from "../../../../models/electricityConsumedStandards/electricityConsumedStandardsDeleteDto";
import { ElectricityConsumedStandardsTableItemDto } from "../../../../models/electricityConsumedStandards/electricityConsumedStandardsTableItemDto";
import { deleteElectricityConsumedStandardsValue } from "../../../../store/slices/settings/electricityConsumedStandardsSlice";
import { SimpleListElem, GetObjectTypes, GetObjectNames } from "../../../balance/ElectricityAPI";
import { GridEditSingleSelectCell, GridEditSingleSelectCellProps, useGridApiContext } from "@mui/x-data-grid";

setYupLocales();
const validationSchema: ObjectSchema<ElectricityConsumedStandardsTableItemDto> = object({
  guid: string().when("isNew", {
    is: true,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required(),
  }),
  objectName: string().required().label("Объект"),
  objectType: string().required().label("Тип Объекта"),
  date: string()
    .label("Дата")
    .when("isNew", {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(),
    }),
  standard: number().required().label("Норматив"),
  isNew: bool().optional(),
  id: string().required(),
});

export const ElectricityConsumedStandards = () => {
  const dispatch = useDispatch<any>();

  const [objectTypes, setObjectTypes] = useState<SimpleListElem[]>([]);
  const [objects, setObjects] = useState<{ type: string; names: SimpleListElem[] }[]>([]);

  const tableName = "electricity-consumed-standards";

  const CustomTypeEditComponent = (props: GridEditSingleSelectCellProps) => {
    const apiRef = useGridApiContext();
    const handleValueChange = async () => {
      await apiRef.current.setEditCellValue({ id: props.id, field: "objectName", value: "" });
    };
    return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
  };

  const tableColumns: BaseTableColumnDef[] = [
    {
      headerName: "Тип Объекта",
      field: "objectType",
      type: "singleSelect",
      editable: true,
      renderEditCell: CustomTypeEditComponent,
      getOptionValue: (value: any) => value.name,
      getOptionLabel: (value: any) => value.name,
      valueOptions: objectTypes,
    },
    {
      headerName: "Объект",
      field: "objectName",
      type: "singleSelect",
      editable: true,
      getOptionValue: (value: any) => value.name,
      getOptionLabel: (value: any) => value.name,
      valueOptions: ({ row }) => {
        const objectNames = objects.find((objArr) => objArr.type === row.objectType);
        if (objectNames) return objectNames.names;
        else return [];
      },
    },
    {
      headerName: "Дата",
      field: "date",
      type: "date",
    },
    {
      headerName: "Норматив, кВт*ч/м3",
      field: "standard",
      type: "number",
      editable: true,
    },
  ];

  const generateCreateData = (data: BaseTableDataItem) => {
    return {
      objectName: data.objectName,
      objectType: data.objectType,
      date: data.date,
      standard: data.standard,
    };
  };

  const generateEditData = (data: BaseTableDataItem) => {
    return {
      guid: data.guid,
      objectName: data.objectName,
      objectType: data.objectType,
      date: data.date,
      standard: data.standard,
    };
  };

  const generateDeleteData = (data: BaseTableDataItem) => {
    return {
      guid: data.guid,
    };
  };

  const deleteItem = (params: ElectricityConsumedStandardsDeleteDto) => {
    return dispatch(deleteElectricityConsumedStandardsValue(params.guid));
  };

  const crudDataMethods = {
    generateCreateData,
    generateEditData,
    generateDeleteData,
    deleteFunc: deleteItem,
    getInitialValues: () => ({
      date: new Date(),
    }),
  };

  async function getObjectTypes() {
    try {
      const response = await GetObjectTypes();
      setObjectTypes(response);
      getNames(response.map((t) => t.name));
    } catch (e) {
      console.error(e);
    }
  }

  function getNames(objectTypesArray: string[]) {
    const namesAndTypes: { type: string; names: SimpleListElem[] }[] = [];
    objectTypesArray.forEach((objType) =>
      getObjectNames(objType).then((response) => {
        namesAndTypes.push({ type: objType, names: response });
      })
    );
    setObjects(namesAndTypes);
  }

  async function getObjectNames(type: string) {
    try {
      const response = await GetObjectNames(type);
      return response;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  useEffect(() => {
    getObjectTypes();
    dispatch(setSettingsTableName(tableName));
  }, []);

  return (
    <SettingsTable tableColumns={tableColumns} crudDataMethods={crudDataMethods} validationSchema={validationSchema} />
  );
};
