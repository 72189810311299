import { Box } from '@mui/material';
import { MonitoringEvents } from './components/monitoringEvents/MonitoringEvents';
import { MonitoringEventsStatistic } from './components/monitoringEventsStatistic/MonitoringEventsStatistic';
import { MonitoringMap } from '../../widgets/monitoringMap/monitoringMap';

const Monitoring = () => {
  return (
    <Box sx={styles.container} className="defaultPageStyle">
      <Box sx={styles.left}>
        <Box sx={styles.mapBox}>
          <MonitoringMap />
        </Box>
        <Box sx={styles.statisticsBox}>
          <MonitoringEventsStatistic />
        </Box>
      </Box>
      <Box sx={styles.right}>
        <MonitoringEvents />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: 'calc(100% - 20px)',
    gap: '40px',
    padding: '20px 32px 0',
  },
  left: {
    flex: '0 1 75%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  mapBox: {
    flex: '1 1 60%',
  },
  statisticsBox: {
    flex: '1 1 40%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  right: {
    flex: '0 1 25%',
  },
};

export default Monitoring;
