import { Box, Button, Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Outlet, useLocation } from "react-router";
import { customTabProps } from "../../utilities/customTabProps";
import { Link } from "react-router-dom";
import { DataAnalysisTabsConfig } from "./dataAnalysisTabsConfig";
import { ExportData } from "./components/exportData/exportData";

export const DataAnalysis = () => {
  const path = useLocation().pathname.split("/").reverse()[0];

  const currentPage = DataAnalysisTabsConfig.find((x) => x.route === path) || DataAnalysisTabsConfig[0];
  const currentTab = currentPage.route;

  return (
    <Box sx={styles.contentContainer}>
      <Box sx={styles.tabsContaiter}>
        <Tabs value={currentTab} TabIndicatorProps={{ style: { background: "#1478BE" } }}>
          {DataAnalysisTabsConfig.map((tab, index) => (
            <Tab
              label={tab.label}
              value={tab.route}
              to={tab.route}
              component={Link}
              key={`tab-${index}`}
              {...customTabProps(index)}
            />
          ))}
        </Tabs>
        <ExportData urlPart={currentPage.exportDataUrpPart} />
      </Box>

      <Outlet />
    </Box>
  );
};

const styles = {
  contentContainer: {
    height: "100%",
    padding: "20px",
    boxSizing: "border-box",
  },
  tabsContaiter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
