import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/uiComponents/Button/CustomButton";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import BlockInfo from "../blockInfo/BlockInfo";
import { ApexLocales } from "../../../constants/ApexChartsSettings";
import { GetSewerage, GetSewerageTable, SewerageTable, GetSewerageCard, BlocksInfoCard } from "../BalansApis";
import Animation from "../../../components/uiComponents/Animation/Animation";

interface WaterDischargeInterface {}

const WaterDischarge: React.FC<WaterDischargeInterface> = () => {
  const columns: GridColDef[] = [
    {
      field: "month",
      headerName: "Месяц",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "total",
      headerName: "Принято стоков, тыс. м. куб",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "realization",
      headerName: "Реализация, тыс. м. куб",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "addInflow",
      headerName: "Дополнительный приток, тыс. м. куб",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
  ];

  const buttonSensor = ["buttonYear", "buttonQuarter", "buttonMonth"];
  const [period, setPeriod] = useState("year");
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [isLoadingBar, setIsLoadingBar] = useState(false);
  const [sewerageTable, setSewerageTable] = useState<SewerageTable[]>([]);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [card, setCards] = useState<BlocksInfoCard>({
    header: "",
    blocks: [],
    name: "",
  });
  const getRowId = (sewerageTable: SewerageTable) => sewerageTable.month;
  const [barOpt, setBarOpt] = useState<ApexOptions>({
    series: [],
    chart: {
      id: "mainChart",
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,

      events: {
        mounted: (_, options) => {
          options?.config?.series?.forEach((data: { name: string }) => {
            if (data.name !== new Date().getFullYear().toString()) {
              ApexCharts.exec("mainChart", "hideSeries", data.name);
            }
          });
        },
      },
    },

    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
    },
    labels: [],
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: `тыс. м. куб`,
      },
    },
  });

  function setTimePeriod(type: string) {
    let per = "";
    setIsLoadingBar(false);
    switch (type) {
      case "year":
        per = "year";
        break;

      case "halfYear":
        per = "halfYear";
        break;

      case "quarterYear":
        per = "quarterYear";
        break;

      default:
        per = "year";
    }
    setPeriod(per);
    getSewerage(per);
  }

  // Функция смены класса кнопки периода
  function buttonClassChange(id: string) {
    // Находим кнопку по id
    var btn = document.getElementById(id);
    // Была ли кнопка найдена
    if (btn !== null) {
      btn.classList.add("timePeriodButtonActive");
    }
    if (btn !== null || id === "undefined") {
      buttonSensor.forEach((button) => {
        if (button !== id) {
          var btn1 = document.getElementById(button);
          if (btn1 !== null) {
            btn1.classList.remove("timePeriodButtonActive");
          }
        }
      });
    }
  }

  async function getSewerage(period: string) {
    setIsLoadingBar(true);
    try {
      const response = await GetSewerage(period);

      setBarOpt({ ...barOpt, series: response.series, labels: response.dates });
      setIsLoadingBar(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function getSewerageTable() {
    setIsLoadingTable(true);
    try {
      const response = await GetSewerageTable();

      setSewerageTable(response);
      setIsLoadingTable(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function getCards() {
    setIsLoadingCard(false);
    try {
      const response = await GetSewerageCard();
      setCards(response);
      setIsLoadingCard(true);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getSewerageTable();
    getSewerage(period);
    getCards();
    buttonClassChange("buttonYear");
  }, []);

  return (
    <div className="balanceTabDefault">
      <div className="BalanceMainForm">
        <div style={{ width: "100%" }}>
          {/* Верхняя легенда */}
          <div className="leftLegendaDate">
            <div style={{ whiteSpace: "nowrap" }} className="headerH2">
              Водоотведение
            </div>

            <FormControl fullWidth size="small" style={{ display: "none" }}>
              <InputLabel id="demo-simple-select-label">Бассейн</InputLabel>
              <Select
                // sx={{ height: "40px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={type}
                label="Тип отчета"
                //onChange={handleChange}
              >
                <MenuItem value={0}>Геленджик</MenuItem>
                <MenuItem value={1}>ОСК 1</MenuItem>
                <MenuItem value={2}>ОСК 2</MenuItem>
              </Select>
            </FormControl>

            <CustomButton
              style={{ width: "100%", height: "40px" }}
              type="timePeriodButton"
              id="buttonYear"
              onClick={() => {
                buttonClassChange("buttonYear");
                setTimePeriod("year");
              }}
            >
              12 месяцев
            </CustomButton>
            <CustomButton
              style={{ width: "100%", height: "40px" }}
              type="timePeriodButton"
              id="buttonQuarter"
              onClick={() => {
                buttonClassChange("buttonQuarter");
                setTimePeriod("halfYear");
              }}
            >
              6 месяцев
            </CustomButton>
            <CustomButton
              style={{ width: "100%", height: "40px" }}
              type="timePeriodButton"
              id="buttonMonth"
              onClick={() => {
                buttonClassChange("buttonMonth");
                setTimePeriod("quarterYear");
              }}
            >
              3 месяца
            </CustomButton>

            <FormControlLabel
              style={{ display: "none" }}
              control={<Switch defaultChecked />}
              label={<div style={{ whiteSpace: "nowrap" }}>Прошлый год</div>}
            />
          </div>

          {/* Первый график с легендой */}
          <div style={{ height: "357px", width: "100%", maxWidth: "calc(100vw - 300px)", minWidth: "700px" }}>
            {isLoadingBar ? (
              <Animation />
            ) : (
              <Chart height={"100%"} width={"100%"} options={barOpt} series={barOpt.series} type="line" />
            )}
          </div>
        </div>
        {/* Блок описания */}
        <div className="description">
          {!isLoadingCard ? (
            <Animation></Animation>
          ) : (
            <BlockInfo
              header={card.header}
              blocks={card.blocks}
              withDetailedInfo={true}
              style={{ height: "100%", justifyContent: "center" }}
              key={card.name}
            />
          )}
        </div>
      </div>
      <Box sx={{ width: "100%" }} className="waterDischargeTable tableHeight">
        {isLoadingTable ? (
          <Animation></Animation>
        ) : (
          <DataGrid
            rows={sewerageTable}
            columns={columns}
            // // pageSize={20}
            getRowId={getRowId}
            localeText={{
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) => `${from} - ${to} из ${count}`,
              },
              ...ruRU,
              filterOperatorIsEmpty: "Пустые значения",
              filterOperatorIsNotEmpty: "Заполненные",
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default WaterDischarge;
