import React, { useEffect, useState } from "react";
import { YMaps, Map, GeoObject, Placemark } from "@pbe/react-yandex-maps";
import { brandBookColors } from "../../../../assets/themes/theme";
import { GetBGGeolocation } from "../../BalansApis";

// interface geoObjInterface {
//   type?:string;
//   id:string;
//   geometry: {type: "LineString" | "Point" | "Rectangle" | "Polygon" | "Circle";
//     coordinates: number[][]|number[]
//   },
//   options?: {
//     geodesic: boolean
//       strokeWidth: number,
//       strokeColor: string,
//   }
// }

function BGMap({ zoneNumber, bgNumber }) {
  const mapRef = React.createRef(null);
  const [center, setCenter] = useState([38.077115, 44.561012]);
  const [geoData, setGeoData] = useState([]);
  const [sourceID, setSourceID] = useState(0);

  let mapState = {
    center: center,
    zoom: 18,
  };

  async function getGeolocation() {
    if (bgNumber < 1000) return 0;
    try {
      // console.log(bgNumber, center);
      const response = await GetBGGeolocation(zoneNumber, bgNumber);
      
      setSourceID(response.sourceId);
      
      let lng = 0,
        lat = 0,
        pointsCount = 0,
        isThereSource = false,
        sourceCoords=[0,0];

      //console.log(response)

      response.features.forEach((geoObj) => {
        if (geoObj.geometry.type === "Point") {
          lng = lng + geoObj.geometry.coordinates[0];
          lat = lat + geoObj.geometry.coordinates[1];
          pointsCount++;
          // console.log(geoObj);
          if (response.sourceId.toString() === geoObj.id.toString()) {
            isThereSource = true;
            sourceCoords = [geoObj.geometry.coordinates[0], geoObj.geometry.coordinates[1]];
          }
        }
        
      });
      setGeoData(response.features);

      // console.log(lng, lat, pointsCount, isThereSource, sourceCoords);
      if (pointsCount !== 0) {
        if (isThereSource){
          setCenter(sourceCoords);
        }
        else{
          const centerC = [lng / pointsCount, lat / pointsCount];
          setCenter(centerC);
          // console.log(centerC);
        }
        
      }
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    getGeolocation();
  }, [bgNumber]);

  return (
    <YMaps
      enterprise
      query={{
        apikey: "14b2656c-bbe8-430d-aba8-2c01f9eac80f",
        coordorder: "longlat",
      }}
      id="y"
    >
      <Map instanceRef={mapRef} state={mapState} defaultState={{ center: center, zoom: 18 }} width="100%" height="100%">
        <GeoObject
          geometry={{
            type: "LineString",
            coordinates: [
              [44.6159474551505, 38.0309574705295],
              [46.6158004570813, 38.0311230300364],
            ],
          }}
          options={{
            geodesic: true,
            strokeWidth: 5,
            strokeColor: "#F008",
          }}
        />
        {geoData.map((geoObj) => {
          return (
            <CustomGeoObject
              geometry={geoObj.geometry}
              isSource={sourceID.toString() === geoObj.id.toString()}
              key={geoObj.id}
            />
          );
        })}
      </Map>
    </YMaps>
  );
}

export default BGMap;

function CustomGeoObject(geoObj) {
  if (geoObj.geometry.type === "LineString") {
    return (
      <GeoObject
        geometry={geoObj.geometry}
        options={{
          geodesic: true,
          strokeWidth: 4,
          strokeColor: brandBookColors.additionalTurquoise,
        }}
        // key={geoObj.id}
      />
    );
  } else if (geoObj.geometry.type === "Point") {
    if (geoObj.isSource) {
      return (
        <Placemark
          geometry={geoObj.geometry.coordinates}
          modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
          properties={{
            // iconCaption: geoObj.geometry.type,
            balloonContent: `<div class="BGSourceBalloon headerH2" id="${geoObj.id}">
              <svg width="43" height="18" viewBox="0 0 43 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1.71484" width="41.0015" height="14.5735" stroke="#383839" stroke-width="2"/>
                <path d="M0.895996 1.60938L42.1057 16.392H0.895996V1.60938Z" fill="#383839"/>
                <path d="M42.1069 16.3906L0.897196 1.60803L42.1069 1.60803L42.1069 16.3906Z" fill="url(#paint0_radial_4841_24272)"/>
                <defs>
                <radialGradient id="paint0_radial_4841_24272" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.4585 15.8452) rotate(-154.886) scale(34.21 32.5915)">
                  <stop offset="0.276797" stop-color="white"/>
                  <stop offset="0.995314" stop-color="#F2F7FF"/>
                </radialGradient>
                </defs>
              </svg>
              Источник воды
            </div>`,
          }}
          options={{
            preset: "islands#circleIcon", // список темплейтов на сайте яндекса
            iconColor: "#CA807F",
            zIndex: "675",
            // iconImageSize: [16, 16],
            // iconImageOffset: [-12, -20],
          }}
        ></Placemark>
      );
    }
    return (
      <Placemark
        geometry={geoObj.geometry.coordinates}
        modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
        options={{
          preset: "islands#circleIcon", // список темплейтов на сайте яндекса
          iconColor: brandBookColors.additionalTurquoise,
        }}
      ></Placemark>
    );
  } else {
    console.error("unknown geoObject Type: ", geoObj.geometry.type);
  }
}
