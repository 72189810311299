import { createTheme } from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { ruRU } from "@mui/material/locale";
// Цвета по брендбуку + дополнительный серый
export const brandBookColors = {
  mainBlue: "#1478BE",
  mainGrey: "#777777",
  additionalBrightBlue: "#E1F3FF",
  additionalBlue: "#1599D7",
  additionalTurquoise: "#4BB8A9",
  outBBGrey: "#A7B6CD",
};

const theme = createTheme(
  {
    palette: {
      // Основыные цвета
      primary: {
        main: brandBookColors.additionalBlue,
      },
    },
    components: {
      MuiFormControl: {
        //variant:"outlined"
      },

      // Селект (дропдаун [выпадающий список])
      MuiSelect: {
        //variant: "outlined",
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          outlined: {
            //border:"1px solid #1599D7"
          },
          // Основные стили
          select: {
            fontFamily: "PF Beau Sans Pro Light",
            fontSize: "14px",
            "&:hover": {
              icon: {
                fill: brandBookColors.mainBlue,
              },
            },
          },
          // Цвет иконки
          icon: {
            fill: brandBookColors.additionalBlue,
          },
        },
      },

      // Стили для компоненты ввода
      // MuiInput: {
      //   //disableUnderline: true

      //   styleOverrides: {
      //     input: {
      //       fontFamily: "PF Beau Sans Pro Light",
      //       fontSize: "14px",
      //     },
      //     underline: {
      //       // Смена цвета подчёркивающей линии
      //       "&:before": { borderBottomColor: brandBookColors.additionalBlue },
      //       "&:after": { borderBottomColor: brandBookColors.additionalBlue },
      //       "&:hover:not(.Mui-disabled):before": {
      //         borderBottomColor: brandBookColors.mainBlue,
      //       },
      //     },
      //   },
      // },

      MuiTextField: {
        // defaultProps:{
        //   variant: "outlined"
        // },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            //borderColor: 'pink',
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: brandBookColors.mainBlue,
            },
          },
        },
      },

      MuiInput: {
        //disableUnderline: true

        styleOverrides: {
          input: {
            fontFamily: "PF Beau Sans Pro Light",
            fontSize: "14px",
          },
          underline: {
            // Смена цвета подчёркивающей линии
            "&:before": { borderBottomColor: brandBookColors.additionalBlue },
            "&:after": { borderBottomColor: brandBookColors.additionalBlue },
            "&:hover:not(.Mui-disabled):before": {
              borderBottomColor: brandBookColors.mainBlue,
            },
          },
        },
      },

      // Стили для кнопки
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            textTransform: "none",
          },
          containedPrimary: {
            "&:hover": {
              backgroundColor: brandBookColors.mainBlue,
              color: "#fff",
            },
          },
        },
      },

      // Стили для элементов выпадающего списка
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily: "PF Beau Sans Pro Light",
            fontSize: "14px",
          },
        },
      },

      // Стили для ярлыков (над полями для ввода)
      MuiInputLabel: {
        styleOverrides: {
          root: {
            textTransform: "uppercase",
            fontFamily: "PF Beau Sans Pro SemiBold",
            fontSize: "14px",
          },
        },
      },
    },
  },
  ruRU
);

// // Дефолтные пропсы элементы
// theme.props = {
//   MuiFormControl: {
//     //variant:"outlined"
//   },
//   MuiSelect: {
//     //variant: "outlined",
//     styleOverrides: {
//       outlined: {
//         //border:"1px solid #1599D7"
//       },
//       // Основные стили
//       select: {
//         fontFamily: "PF Beau Sans Pro Light",
//         fontSize: "14px",
//         "&:hover": {
//           icon: {
//             fill: brandBookColors.mainBlue,
//           },
//         },
//       },
//       // Цвет иконки
//       icon: {
//         fill: brandBookColors.additionalBlue,
//       },
//     },
//   },
//   MuiInput: {
//     //disableUnderline: true
//     styleOverrides: {
//       input: {
//         fontFamily: "PF Beau Sans Pro Light",
//         fontSize: "14px",
//       },
//       underline: {
//         // Смена цвета подчёркивающей линии
//         "&:before": { borderBottomColor: brandBookColors.additionalBlue },
//         "&:after": { borderBottomColor: brandBookColors.additionalBlue },
//         "&:hover:not(.Mui-disabled):before": {
//           borderBottomColor: brandBookColors.mainBlue,
//         },
//       },
//     },
//   },
// };

export default theme;
