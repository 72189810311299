import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";
import { LoadingState } from "../../../../types/loadingState";
import { GridRowParams } from "@mui/x-data-grid";
import { EventInfoTableDTO } from "../../../../models/eventInfo/EventInfoTableDTO";
import { Box } from "@mui/material";
import Loader from "../../../../components/uiComponents/Animation/Animation";

export const MonitoringEventInfoMetersTable = () => {
  const eventInfoTableData = useTypedSelector((state) => state.monitoringEventInfo.eventInfoTableData);
  const isLoading = useTypedSelector((state) => state.monitoringEventInfo.isLoadingTableData);
  
  const columns: BaseTableColumnDef[] = [
    {
      headerName: "Дата и время",
      field: "date",
      type: "dateTime",
    },
    {
      headerName: "Показание, кгс/м2",
      field: "value",
    },
  ];

  const getFormattedRowClassName = (params: GridRowParams<EventInfoTableDTO>) => {
    return params.row.anomalySign ? `monitoring-event-info__row-red` : "";
  };

  if (isLoading === LoadingState.PENDING) {
    return <Loader />
  }

  return (
    <Box sx={styles.container}>
      <BaseTable
        columns={columns}
        data={eventInfoTableData}
        getRowClassName={getFormattedRowClassName}
        useClientPagination
      />
    </Box>
  );
};

const styles = {
  container: {
    width: "100%",
    height: "100%",
    "& .monitoring-event-info__row-red": {
      background: "#CA807F99",
    },
  },
};
