import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { settingsTableApi } from "../../../pages/settings/api/SettingsTableApi";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";
import { v4 as uuidv4 } from "uuid";
import { fileService } from "../../../utilities/file/fileService";
import { PassportDataTableItemDto } from "../../../models/passportData/passportDataTableItemDto";

const sliceName = "passportData";

interface PassportDataState {
  data: PassportDataTableItemDto[];
  isLoading: LoadingState;
  exportProcessing: LoadingState;
}

const initialState: PassportDataState = {
  data: [],
  isLoading: LoadingState.EMPTY,
  exportProcessing: LoadingState.EMPTY,
};

export const getPassportTableData = createAsyncThunk<PassportDataTableItemDto[], void, { state: RootState }>(
  `${sliceName}/getPassportTableData`,
  async (_, thunkAPI) => {
    try {
      const response = await settingsTableApi.getPassportTableData();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const exportPassportData = createAsyncThunk<Blob, void, { state: RootState }>(
  `${sliceName}/exportPassportData`,
  async (_, thunkAPI) => {
    try {
      const response = await settingsTableApi.exportPassportData();
      fileService.downloadFile(response.data, "passport-data");
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const passportDataSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPassportTableData.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getPassportTableData.fulfilled, (state, action: PayloadAction<PassportDataTableItemDto[]>) => {
      state.data = action.payload.map((i: PassportDataTableItemDto) => ({
        ...i,
        id: uuidv4(),
      }));
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getPassportTableData.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });

    builder.addCase(exportPassportData.pending, (state) => {
      state.exportProcessing = LoadingState.PENDING;
    });
    builder.addCase(exportPassportData.fulfilled, (state) => {
      state.exportProcessing = LoadingState.SUCCESS;
    });
    builder.addCase(exportPassportData.rejected, (state) => {
      state.exportProcessing = LoadingState.ERROR;
    });
  },
});

export const passportDataReducer = passportDataSlice.reducer;
export const passportDataActions = passportDataSlice.actions;
