import AuthAxios, { backURLs } from '../../core/APIs';

const anomaliesBaseUrl = backURLs.anomaliesServiceBaseUrl;
const cimModelBaseUrl = backURLs.cimModel;

export const monitoringMapApi = {
  getZones: () => AuthAxios.get(`${backURLs.anomalyData}/get_zones`),
  getMapObjects: () => AuthAxios.get(`${backURLs.anomalyData}/get_objects`),
  getCIMModel: (guid: string, geoObjectsToRemove: number[]) =>
    AuthAxios.get(`${cimModelBaseUrl}/${guid}`, { params: { geoObjectsToRemove }, paramsSerializer: { indexes: null } }),
};
