import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { ApexLocales } from '../../../../constants/ApexChartsSettings';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { Opacity } from '@mui/icons-material';
import { isNullOrEmpty } from '../../../../utilities/string/isNullOrEmpty';
import { isNullOrUndefined } from '../../../../utilities/isNullOrUndefined';

export const DeviceSignalsChart = () => {
  const signalValues = useTypedSelector((state) => state.deviceInfo.signalValues);
  const norms = useTypedSelector((state) => state.deviceInfo.norms);

  const chartOptions: ApexOptions = {
    series: [
      {
        data: signalValues.map((item) => ({ x: item.dateTime, y: item.value })),
        color: '#1599D7',
      },
    ],
    chart: {
      animations: {
        enabled: false,
      },
      width: '100%',
      type: 'line',
      zoom: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },
    markers: {
      size: 4,
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
    },
    xaxis: {
      type: 'datetime',
    },
  };

  if (norms) {
    const annotationsOptions = { borderColor: '#4BB8A9' };
    if (!isNullOrUndefined(norms.low) && !isNullOrUndefined(norms.high)) {
      const t = !isNullOrUndefined(norms.high);
      chartOptions.annotations = {
        yaxis: [
          {
            y: norms.low,
            y2: norms.high,
            label: {
              text: 'Норма',
            },
            ...annotationsOptions,
          },
        ],
      };
    } else if (!isNullOrUndefined(norms.low)) {
      chartOptions.annotations = {
        yaxis: [
          {
            y: norms.low,
            label: {
              text: 'Нижнее значение нормы',
            },
            ...annotationsOptions,
          },
        ],
      };
    } else if (!isNullOrUndefined(norms.high)) {
      chartOptions.annotations = {
        yaxis: [
          {
            y: norms.high,
            label: {
              text: 'Верхнее значение нормы',
            },
            ...annotationsOptions,
          },
        ],
      };
    }
  }

  return <Chart options={chartOptions} series={chartOptions.series} type="line" height={'100%'} />;
};
