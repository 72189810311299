import { useState } from "react";
import { UserAccessEditor } from "../userAccessEditor/userAccessEditor";
import { Users } from "../users/users";

export const UsersTabManager = () => {
  const [showUsersTable, setShowUsersTable] = useState(true);

  const handleShowUsersTableClick = () => {
    setShowUsersTable(true);
  };

  const handleShowUserAccessEditorClick = () => {
    setShowUsersTable(false);
  };

  return showUsersTable ? (
    <Users onShowUserAccessEditorClick={handleShowUserAccessEditorClick} />
  ) : (
    <UserAccessEditor onShowUsersTableClick={handleShowUsersTableClick} />
  );
};
