import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { SettingsTabsConfig } from "./settingsTabsConfig";
import { TabsWithRoutes } from "../../components/tabsWithRoutes/tabsWithRoutes";

export const Settings = () => {
  return (
    <Box sx={styles.wrapper}>
      <TabsWithRoutes config={SettingsTabsConfig} />
      <Outlet />
    </Box>
  );
};

const styles = {
  wrapper: {
    padding: "20px",
    ".MuiTab-root": {
      maxWidth: "100%",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "140%",
      textTransform: "uppercase",
    },
  },
};
