import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField/TextField";
import moment, { Moment } from "moment";
import { Link } from "react-router-dom";
import {
  BlocksInfoCard,
  GetSupplyCard,
  GetSupplyChartData,
  GetZoneSupplyTable,
  ZoneSupplyTable,
} from "../../BalansApis";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { ApexLocales } from "../../../../constants/ApexChartsSettings";
import BlockInfo from "../../blockInfo/BlockInfo";
import { Button } from "@mui/material";
import { useTimeout } from "@mui/x-data-grid/internals";
import { CustomDateTimePicker } from "../../../../components/formFields/customDateTimePicker.tsx/customDateTimePicker";
import { getFormattedDate } from "../../../../utilities/dateHelper";

interface SupplyInterface {
  //zoneNumber: string;
}

//первый день года
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const firstDayOfYear = new Date(currentYear, 0, 1);
//последний день года
const lastDayOfYear = new Date(currentYear, 11, 32);

const Supply: React.FC<SupplyInterface> = () => {
  // Переменные начала и конца периода соответствено
  const [isLoadingCard, setIsLoadingCard] = useState(false);

  const [startDate, setStartDate] = useState<Moment>(moment(firstDayOfYear));
  const [endDate, setEndDate] = useState<Moment>(moment());

  const [zoneSupplyTable, setZoneSupplyTable] = useState<ZoneSupplyTable[]>([]);
  const [supplyData, setSupplyData] = useState<BlocksInfoCard>({
    header: "",
    blocks: [],
    name: "",
  });
  const { zoneNumber } = useTypedSelector((state) => state.zone);

  const [chartData, setChartData] = useState<ApexOptions>({
    series: [],
    chart: {
      id: "mainChart",
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
      events: {
        mounted: (_, options) => {
          options?.config?.series?.forEach((data: { name: string }) => {
            if (data.name !== new Date().getFullYear().toString()) {
              ApexCharts.exec("mainChart", "hideSeries", data.name);
            }
          });
        },
      },
    },

    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
    },
    labels: [],
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "тыс. м. куб",
      },
    },
  });

  const columns: GridColDef[] = [
    {
      field: "group",
      headerName: "Группа",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "released",
      headerName: "Подано в группу, тыс. м.куб",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "givenAway",
      headerName: "Реализовано абонентам, тыс. м.куб",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "Переход",
      headerName: "",
      flex: 1,
      minWidth: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link style={{ display: "flex", alignItems: "center", cursor: "pointer" }} to={`${params.id}`}>
            ПОДРОБНО
          </Link>
        );
      },
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);

  async function getSupplyCard(startDate: Moment, endDate: Moment) {
    // var currentDate = new Date();
    // currentDate.setMonth(0); // Устанавливаем месяц на январь (индекс месяца: 0)
    // currentDate.setDate(1);
    // setStartDate(String(moment(currentDate).format("YYYY-MM-DD HH:mm")));
    if (zoneNumber.length === 0) return 0;
    setIsLoadingCard(false);
    try {
      const response = await GetSupplyCard(getFormattedDate(startDate), getFormattedDate(endDate), zoneNumber);
      if (response !== undefined) setSupplyData(response);
      setIsLoadingCard(true);
    } catch (e) {
      console.log(e);
    }
  }

  //Запрос для таблицы балансовых групп (Зона.Подача.Таблица)
  async function getZoneSupplyTable(startDate: Moment, endDate: Moment) {
    if (zoneNumber.length === 0) return 0;
    setIsLoadingTable(false);
    try {
      const response = await GetZoneSupplyTable(zoneNumber, getFormattedDate(startDate), getFormattedDate(endDate));
      setZoneSupplyTable(response);
      setIsLoadingTable(true);
    } catch (e) {
      console.log(e);
    }
  }

  async function getChartData(startDate: Moment, endDate: Moment) {
    if (zoneNumber.length === 0) return 0;
    setIsLoading(true);
    try {
      const response = await GetSupplyChartData(getFormattedDate(startDate), getFormattedDate(endDate), zoneNumber);
      setChartData({ ...chartData, series: response.series, labels: response.dates });
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  const handleStartDateChange = (value: Moment | null) => {
    if (value) {
      setIsLoading(false);
      setStartDate(value);
      getZoneSupplyTable(value, endDate);
      getChartData(value, endDate);
      getSupplyCard(value, endDate);
    }
  };

  const handleEndDateChange = (value: Moment | null) => {
    if (value) {
      setIsLoading(false);
      setEndDate(value);
      getZoneSupplyTable(startDate, value);
      getChartData(startDate, value);
      getSupplyCard(startDate, value);
    }
  };

  useEffect(() => {
    getZoneSupplyTable(startDate, endDate);
    getSupplyCard(startDate, endDate);
    getChartData(startDate, endDate);
  }, [zoneNumber]);

  return (
    <div className="balanceTabDefault">
      <div className="BalanceMainForm">
        <div style={{ width: "100%" }}>
          {/* Верхняя легенда */}
          <div className="leftLegendaDate">
            <div style={{ whiteSpace: "nowrap" }} className="headerH2">
              Подача за период с
            </div>
            <CustomDateTimePicker
              dateValue={startDate}
              onDateValueChange={handleStartDateChange}
              minDateTime={moment(firstDayOfYear)}
              maxDateTime={moment(lastDayOfYear)}
            />
            <div style={{ whiteSpace: "nowrap" }} className="headerH2">
              по
            </div>
            <CustomDateTimePicker
              dateValue={endDate}
              onDateValueChange={handleEndDateChange}
              minDateTime={moment(firstDayOfYear)}
              maxDateTime={moment(lastDayOfYear)}
            />
          </div>

          <div style={{ height: "357px", width: "100%" }}>
            {isLoading ? (
              <Animation></Animation>
            ) : (
              <Chart height={"100%"} width={"100%"} options={chartData} series={chartData.series} type="line" />
            )}
          </div>
        </div>
        {/* Блок описания */}
        <div className="description">
          {/* {supplyData.map((item) => ( */}
          {!isLoadingCard ? (
            <Animation></Animation>
          ) : (
            supplyData !== undefined && (
              <BlockInfo
                style={{ height: "100%", justifyContent: "center" }}
                header={supplyData.header}
                blocks={supplyData.blocks}
              />
            )
          )}
          {/* ))} */}
        </div>
      </div>
      <Box sx={{ width: "100%" }} className="supplyTable tableHeight">
        {!isLoadingTable ? (
          <Animation></Animation>
        ) : (
          <DataGrid
            rows={zoneSupplyTable}
            columns={columns}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </Box>
    </div>
  );
};

export default Supply;
