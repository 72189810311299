import { Box } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";

export const eventTypeCellRenderer = (params: GridRenderCellParams) => {
  switch (params.value) {
    case "critical":
      return <Box sx={styles.critical}>Критическое отклонение</Box>;
    case "excess":
      return <Box sx={styles.excess}>Отклонение от нормы</Box>;
    case "service":
      return <Box sx={styles.service}>Сервисные сигналы</Box>;
    default:
      return params.value;
  }
};

const styles = {
  critical: {
    color: "#CA807F",
  },
  excess: {
    color: "#EAAF6B",
  },
  service: {
    color: "#1478BE",
  },
};
