import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LoginForm } from "./loginForm/loginForm";
import { ReactComponent as LogoLarge } from "../../../assets/images/loginPage/logoLarge.svg";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { ForgotPasswordForm } from "./forgotPasswordForm/forgotPasswordForm";
import { EmailSentForm } from "./emailSentForm/emailSentForm";
import { FormType } from "../../../models/account/formType";

export const Login = () => {
  const navigate = useNavigate();
  const displayedForm = useTypedSelector((state) => state.login.displayedForm);

  const handleLoginSuccessfully = () => {
    navigate("/");
  };

  return (
    <Box sx={styles.loginPage}>
      <Box>
        <Grid container spacing={2} sx={styles.content}>
          <Grid item xs={6} sx={styles.form}>
            {displayedForm === FormType.Login && <LoginForm onSuccess={handleLoginSuccessfully} />}
            {displayedForm === FormType.ForgotPassword && <ForgotPasswordForm />}
            {displayedForm === FormType.EmailSent && <EmailSentForm />}
          </Grid>
          <Grid item xs={6}>
            <Box sx={styles.logoContainer}>
              <Box sx={styles.logo}>
                <LogoLarge />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const styles = {
  loginPage: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
  },
  form: {
    width: "550px",
  },
  logoContainer: {
    paddingLeft: "120px",
    display: "flex",
  },
  logo: {
    width: "410px",
    height: "390px",
  },
};
