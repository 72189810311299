import { Box, Button } from "@mui/material";
import { setCurrentDisplayedForm } from "../../../../store/slices/login/loginSlice";
import { useDispatch } from "react-redux";
import { FormType } from "../../../../models/account/formType";
import { HeaderText } from "../../../../components/headerText/headerText";
import { TextMessage } from "../../../../components/textMessage/textMessage";

export const EmailSentForm = () => {
  const dispatch = useDispatch<any>();

  const handleGoToLoginPage = () => {
    dispatch(setCurrentDisplayedForm(FormType.Login));
  };

  return (
    <>
      <HeaderText>Восстановление доступа</HeaderText>
      <TextMessage>Данные для входа в систему отправлены на Ваш email</TextMessage>
      <Box sx={styles.buttonContainer}>
        <Button className="primary" onClick={handleGoToLoginPage}>Вернуться на страницу логина</Button>
      </Box>
    </>
  );
};

const styles = {
  fieldsContainer: {
    backgroundColor: "white",
    boxShadow: "0px 40px 80px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
};
