import { Button, ButtonProps, CircularProgress } from "@mui/material";

export type BaseButtonProps = ButtonProps & { component?: React.ElementType; loading?: boolean };

export const BaseButton = (props: BaseButtonProps) => {
  if (!props.loading) {
    return <Button {...props}>{props.children}</Button>;
  }

  if (props.endIcon) {
    return (
      <Button {...props} endIcon={<CircularProgress size={22} />}>
        {props.children}
      </Button>
    );
  } else {
    return (
      <Button {...props} startIcon={<CircularProgress size={22} />}>
        {props.children}
      </Button>
    );
  }
};
