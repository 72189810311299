import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  DataAnalysisApi,
  PredictionDataInterface,
  SupplySaleWaterDatesInterface,
  TotalDataInterface,
} from "../../api/dataAnalysisApi";
import { ApexOptions } from "apexcharts";
import { ApexLocales } from "../../../../constants/ApexChartsSettings";
import Chart from "react-apexcharts";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { BaseTableDataItem } from "../../../../models/baseTable/baseTableDataItem";
import { ChartData } from "../../../balance/BalansApis";

interface APILinkGradedInterface {
  name: string;
  link: string;
  id: number;
  isHidden?: boolean;
  units: string;
  isPrediction: boolean;
  dataStructure: string;
}

const paramsList: APILinkGradedInterface[] = [
  {
    name: "",
    link: "",
    id: 0,
    isHidden: true,
    units: "",
    isPrediction: false,
    dataStructure: "",
  },
  {
    name: "Водоснабжение (Подача), тыс. м.куб",
    link: "api/waterchannel-gateway/v1/summary/supply-sale-water-dates",
    id: 1,
    units: "м.куб",
    isPrediction: false,
    dataStructure: "supply-sale-water",
  },
  {
    name: "Прогноз Водоснабжения (Подачи), тыс. м.куб",
    link: "api/predictionservice/v1.0/volume_predict",
    id: 2,
    units: "м.куб",
    isPrediction: true,
    dataStructure: "predictionservice",
  },
  {
    name: "Потребление (Реализация), тыс.м.куб.",
    link: "api/waterchannel-gateway/v1/summary/supply-sale-water-dates",
    id: 3,
    units: "м.куб",
    isPrediction: false,
    dataStructure: "supply-sale-water",
  },
  {
    name: "Прогноз Потребления (Реализация), тыс.м.куб.",
    link: "api/predictionservice/v1.0/get_supply_sale_water",
    id: 4,
    units: "м.куб",
    isPrediction: true,
    dataStructure: "predictionservice",
  },
  {
    name: "Водоотведение, тыс.м.куб.",
    link: "api/waterchannel-gateway/v1/sewerage/data",
    id: 5,
    isHidden: false,
    units: "м.куб",
    isPrediction: false,
    dataStructure: "sewerage",
  },
  {
    name: "Прогноз Водоотведения, тыс.м.куб.",
    link: "api/predictionservice/v1.0/get_sewerage",
    id: 6,
    units: "м.куб",
    isPrediction: true,
    dataStructure: "predictionservice",
  },
  {
    name: "Начисления, тыс. руб.",
    link: "api/waterchannel-gateway/v1/total-data/accruals",
    id: 7,
    units: "руб",
    isPrediction: false,
    dataStructure: "total-data",
  },
  {
    name: "Прогноз Начислений, тыс. руб.",
    link: "api/predictionservice/v1.0/get_accruals",
    id: 8,
    units: "руб",
    isPrediction: true,
    dataStructure: "predictionservice",
  },
  {
    name: "Оплата, тыс. руб.",
    link: "api/waterchannel-gateway/v1/total-data/payments",
    id: 9,
    units: "руб",
    isPrediction: false,
    dataStructure: "total-data",
  },
  {
    name: "Прогноз Оплаты, тыс. руб",
    link: "api/predictionservice/v1.0/get_payments",
    id: 10,
    units: "руб",
    isPrediction: true,
    dataStructure: "predictionservice",
  },
];

export const CrossCheckout = () => {
  const [measure1, setMeasure1] = useState<APILinkGradedInterface>(paramsList[0]);
  const [measure2, setMeasure2] = useState<APILinkGradedInterface>(paramsList[0]);
  const [measureData1, setMeasureData1] = useState<{ x: string; y: number }[]>([]);
  const [measureData2, setMeasureData2] = useState<{ x: string; y: number }[]>([]);
  const [years, setYears] = useState<number[]>([]);

  const columns: BaseTableColumnDef[] = [
    {
      headerName: "Дата",
      field: "date",
    },
    {
      headerName: measure1.name,
      field: "indication1",
    },
    {
      headerName: measure2.name,
      field: "indication2",
    },
  ];

  const [endDate, setEndDate] = useState(String(moment(Date.now()).format("YYYY-MM-DD")));
  const [startDate, setStartDate] = useState(String(moment(Date.now()).format("YYYY-MM-DD")));
  const [isFixedPeriod, setIsFixedPeriod] = useState(true);

  const [data, setData] = useState<BaseTableDataItem[]>([
    {
      id: "1",
      date: "date",
      indication1: "indication1",
      indication2: "indication2",
    },
  ]);

  const buttonSensor = ["buttonYear", "buttonHalfYear", "buttonQuarter", "buttonQuarterYear", "buttonMonth"];

  const [isLoadingLine, setIsLoadingLine] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [lineGraph, setLineGraph] = useState<ApexOptions>({
    series: [
      { name: "Показатель 1", data: [], color: "#0E9CFF" },
      { name: "Показатель 2", data: [], color: "#EAAF6B" },
    ],
    chart: {
      id: "mainChart",
      animations: {
        enabled: false,
      },
      width: "100%",
      type: "line",
      zoom: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },

    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
    },
    xaxis: {
      type: "datetime",
    },
  });

  // Функция смены класса кнопки периода
  function buttonClassChange(id: string) {
    // Находим кнопку по id
    var btn = document.getElementById(id);
    // Была ли кнопка найдена
    if (btn !== null) {
      btn.classList.add("timePeriodButtonActive");
      setIsFixedPeriod(true);
    } else {
      setIsFixedPeriod(false);
    }
    if (btn !== null || id === "undefined") {
      buttonSensor.forEach((button) => {
        if (button !== id) {
          var btn1 = document.getElementById(button);
          if (btn1 !== null) {
            btn1.classList.remove("timePeriodButtonActive");
          }
        }
      });
    }
  }

  // Функция выбора периода
  function setTimePeriod(type: string) {
    var endDate = new Date();
    var startDate = new Date();

    switch (type) {
      case "year":
        endDate = new Date(Date.now() + 1000 * 3600 * 24 * 365);
        break;

      case "halfYear":
        endDate = new Date(Date.now() + 1000 * 3600 * 24 * 183);
        break;

      case "quarter":
        endDate = new Date(Date.now() + 1000 * 3600 * 24 * 91);
        break;

      default:
        endDate = new Date(Date.now() + 1000 * 3600 * 24 * 365);
    }
    // setPeriod(per);

    setStartDate(moment(startDate).format("YYYY-MM-DD"));
    setEndDate(moment(endDate).format("YYYY-MM-DD"));
    getMeasuresChart(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), true);
  }

  // isFixed - фиксированные период времени или нет
  // option - вариант изменения графика и отправки запросов
  // msure1 -
  async function getMeasuresChart(
    startDate: string,
    endDate: string,
    isFixedTime = isFixedPeriod,
    option = 0,
    msure1 = measure1,
    years2 = years
  ) {
    const daysInterval = (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24);

    let totalStartDate = startDate;
    let totalEndDate = endDate;

    try {
      setIsLoadingLine(true);
      setIsLoadingTable(true);
      switch (option) {
        case 1: {
          setYears([]);
          if (msure1.link.length > 0) {
            let values: { x: string; y: number }[] = [];

            if (msure1.dataStructure === "predictionservice") {
              const response = await DataAnalysisApi.getMeasuresChart<PredictionDataInterface[]>(
                msure1.link,
                startDate,
                endDate
              );
              values = response.data.map((measure) => {
                return { x: measure.date_time, y: measure.volume };
              });
            }
            if (msure1.dataStructure === "total-data") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<TotalDataInterface[]>(
                msure1.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );
              values = response.data
                .map((measure) => {
                  return { x: measure.date, y: measure.value };
                })
                .sort();
            }

            if (msure1.dataStructure === "sewerage") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<ChartData>(
                msure1.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );

              for (let i = 0; i < response.data.dates.length; i++) {
                values.push({ x: response.data.dates[i], y: response.data.series[0].data[i] });
              }
            }

            if (msure1.dataStructure === "supply-sale-water") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<SupplySaleWaterDatesInterface>(
                msure1.link,
                totalStartDate,
                totalEndDate
              );

              if (msure1.id === 1) {
                for (let i = 0; i < response.data.dates.length; i++) {
                  values.push({ x: response.data.dates[i], y: response.data.data[0].data[i] });
                }
              } else {
                // id===3
                for (let i = 0; i < response.data.dates.length; i++) {
                  if (i < response.data.data[1].data.length)
                    values.push({ x: response.data.dates[i], y: response.data.data[1].data[i] });
                }
              }
            }

            let newSeries: ApexAxisChartSeries = [];

            values.sort(function (a, b) {
              if (a.x < b.x) {
                return -1;
              }
              if (a.x > b.x) {
                return 1;
              }
              return 0;
            });

            if (lineGraph.series !== undefined && lineGraph.series.length > 1) {
              newSeries = [
                { name: msure1.name, data: values, color: "#0E9CFF" },
                { name: measure2.name, data: measureData2, color: "#EAAF6B" },
              ];
            } else {
              newSeries = [
                { name: "Показатель 1", data: values, color: "#0E9CFF" },
                { name: "Показатель 2", data: [], color: "#EAAF6B" },
              ];
            }

            setMeasureData1(values);

            setLineGraph({
              ...lineGraph,
              series: newSeries,
            });
          }
          setIsLoadingLine(false);
          break;
        }
        case 2: {
          setYears([]);
          if (msure1.link.length > 0) {
            let values: { x: string; y: number }[] = [];

            if (msure1.dataStructure === "predictionservice") {
              const response = await DataAnalysisApi.getMeasuresChart<PredictionDataInterface[]>(
                msure1.link,
                startDate,
                endDate
              );
              values = response.data.map((measure) => {
                return { x: measure.date_time, y: measure.volume };
              });
            }

            if (msure1.dataStructure === "total-data") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<TotalDataInterface[]>(
                msure1.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );
              values = response.data.map((measure) => {
                return { x: measure.date, y: measure.value };
              });
            }

            if (msure1.dataStructure === "sewerage") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<ChartData>(
                msure1.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );

              for (let i = 0; i < response.data.dates.length; i++) {
                values.push({ x: response.data.dates[i], y: response.data.series[0].data[i] });
              }
            }

            if (msure1.dataStructure === "supply-sale-water") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<SupplySaleWaterDatesInterface>(
                msure1.link,
                totalStartDate,
                totalEndDate
              );

              if (msure1.id === 1) {
                for (let i = 0; i < response.data.dates.length; i++) {
                  values.push({ x: response.data.dates[i], y: response.data.data[0].data[i] });
                }
              } else {
                // id===3
                for (let i = 0; i < response.data.dates.length; i++) {
                  if (i < response.data.data[1].data.length)
                    values.push({ x: response.data.dates[i], y: response.data.data[1].data[i] });
                }
              }
            }

            let newSeries: ApexAxisChartSeries = [];

            values.sort(function (a, b) {
              if (a.x < b.x) {
                return -1;
              }
              if (a.x > b.x) {
                return 1;
              }
              return 0;
            });

            if (lineGraph.series !== undefined && lineGraph.series.length > 0) {
              newSeries = [
                { name: measure1.name, data: measureData1, color: "#0E9CFF" },
                { name: msure1.name, data: values, color: "#EAAF6B" },
              ];
            } else {
              newSeries = [
                { name: "Показатель 1", data: [], color: "#0E9CFF" },
                { name: "Показатель 2", data: values, color: "#EAAF6B" },
              ];
            }

            setMeasureData2(values);
            setLineGraph({ ...lineGraph, series: newSeries });
          }
          setIsLoadingLine(false);
          break;
        }
        case 11: {
          let values1: { x: string; y: number }[] = [];
          let values2: { x: string; y: number }[] = [];

          if (years2.length !== 2) {
            setIsLoadingLine(false);
            setIsLoadingTable(false);
            return 0;
          }
          const firstYear = years2.sort()[0];
          const secondYear = years2.sort()[1];

          if (measure1.link.length > 0) {
            totalStartDate = `${firstYear}-01-01`;
            totalEndDate = `${firstYear}-12-31`;

            if (measure1.dataStructure === "predictionservice") {
              const response1 = await DataAnalysisApi.getMeasuresChart<PredictionDataInterface[]>(
                measure1.link,
                totalStartDate,
                totalEndDate
              );
              values1 = response1.data.map((measure) => {
                return { x: measure.date_time, y: measure.volume };
              });
            }

            if (measure1.dataStructure === "total-data") {
              const response1 = await DataAnalysisApi.getMeasuresChart<TotalDataInterface[]>(
                measure1.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );
              values1 = response1.data.map((measure) => {
                return { x: measure.date, y: measure.value };
              });
            }

            if (measure1.dataStructure === "sewerage") {
              const response = await DataAnalysisApi.getMeasuresChart<ChartData>(
                measure1.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );

              for (let i = 0; i < response.data.dates.length; i++) {
                values1.push({ x: response.data.dates[i], y: response.data.series[0].data[i] });
              }
            }

            if (measure1.dataStructure === "supply-sale-water") {
              const response = await DataAnalysisApi.getMeasuresChart<SupplySaleWaterDatesInterface>(
                measure1.link,
                totalStartDate,
                totalEndDate
              );

              if (measure1.id === 1) {
                for (let i = 0; i < response.data.dates.length; i++) {
                  values1.push({ x: response.data.dates[i], y: response.data.data[0].data[i] });
                }
              } else {
                // id===3
                for (let i = 0; i < response.data.dates.length; i++) {
                  if (i < response.data.data[1].data.length)
                    values1.push({ x: response.data.dates[i], y: response.data.data[1].data[i] });
                }
              }
            }

            values1.sort(function (a, b) {
              if (a.x < b.x) {
                return -1;
              }
              if (a.x > b.x) {
                return 1;
              }
              return 0;
            });
            setMeasureData1(values1);
          }

          if (measure2.link.length > 0) {
            totalStartDate = `${secondYear}-01-01`;
            totalEndDate = `${secondYear}-12-31`;

            if (measure2.dataStructure === "predictionservice") {
              const response2 = await DataAnalysisApi.getMeasuresChart<PredictionDataInterface[]>(
                measure2.link,
                totalStartDate,
                totalEndDate
              );
              values2 = response2.data
                .map((measure) => {
                  return { x: measure.date_time, y: measure.volume };
                })
                .sort();
            }

            if (measure2.dataStructure === "total-data") {
              const response2 = await DataAnalysisApi.getMeasuresChart<TotalDataInterface[]>(
                measure2.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );
              values2 = response2.data.map((measure) => {
                return { x: measure.date, y: measure.value };
              });
            }

            if (measure2.dataStructure === "sewerage") {
              const response = await DataAnalysisApi.getMeasuresChart<ChartData>(
                measure2.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );

              for (let i = 0; i < response.data.dates.length; i++) {
                values2.push({ x: response.data.dates[i], y: response.data.series[0].data[i] });
              }
            }

            if (measure2.dataStructure === "supply-sale-water") {
              const response = await DataAnalysisApi.getMeasuresChart<SupplySaleWaterDatesInterface>(
                measure2.link,
                totalStartDate,
                totalEndDate
              );

              if (measure2.id === 1) {
                for (let i = 0; i < response.data.dates.length; i++) {
                  values2.push({ x: response.data.dates[i], y: response.data.data[0].data[i] });
                }
              } else {
                // id===3
                for (let i = 0; i < response.data.dates.length; i++) {
                  if (i < response.data.data[1].data.length)
                    values2.push({ x: response.data.dates[i], y: response.data.data[1].data[i] });
                }
              }
            }

            values2.sort(function (a, b) {
              if (a.x < b.x) {
                return -1;
              }
              if (a.x > b.x) {
                return 1;
              }
              return 0;
            });
            setMeasureData2(values2);
          }

          // Для отображения с наложением
          values2.forEach((valuePair) => {
            valuePair.x = valuePair.x.replace(secondYear.toString(), firstYear.toString());
          });

          setLineGraph({
            ...lineGraph,
            series: [
              { name: measure1.name + ", " + years2[0], data: values1, color: "#0E9CFF" },
              { name: measure2.name + ", " + years2[1], data: values2, color: "#EAAF6B" },
            ],
          });
          setIsLoadingLine(false);
          break;
        }
        default: {
          setYears([]);
          let values1: { x: string; y: number }[] = [];
          let values2: { x: string; y: number }[] = [];

          if (measure1.link.length > 0) {
            if (measure1.dataStructure === "predictionservice") {
              const response1 = await DataAnalysisApi.getMeasuresChart<PredictionDataInterface[]>(
                measure1.link,
                startDate,
                endDate
              );
              values1 = response1.data.map((measure) => {
                return { x: measure.date_time, y: measure.volume };
              });
            }

            if (measure1.dataStructure === "total-data") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }

              const response1 = await DataAnalysisApi.getMeasuresChart<TotalDataInterface[]>(
                measure1.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );
              values1 = response1.data.map((measure) => {
                return { x: measure.date, y: measure.value };
              });
            }

            if (measure1.dataStructure === "sewerage") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<ChartData>(
                measure1.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );

              for (let i = 0; i < response.data.dates.length; i++) {
                values1.push({ x: response.data.dates[i], y: response.data.series[0].data[i] });
              }
            }

            if (measure1.dataStructure === "supply-sale-water") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<SupplySaleWaterDatesInterface>(
                measure1.link,
                totalStartDate,
                totalEndDate
              );

              if (measure1.id === 1) {
                for (let i = 0; i < response.data.dates.length; i++) {
                  values1.push({ x: response.data.dates[i], y: response.data.data[0].data[i] });
                }
              } else {
                // id===3
                for (let i = 0; i < response.data.dates.length; i++) {
                  if (i < response.data.data[1].data.length)
                    values1.push({ x: response.data.dates[i], y: response.data.data[1].data[i] });
                }
              }
            }

            values1.sort(function (a, b) {
              if (a.x < b.x) {
                return -1;
              }
              if (a.x > b.x) {
                return 1;
              }
              return 0;
            });
            setMeasureData1(values1);
          }

          if (measure2.link.length > 0) {
            if (measure2.dataStructure === "predictionservice") {
              const response2 = await DataAnalysisApi.getMeasuresChart<PredictionDataInterface[]>(
                measure2.link,
                startDate,
                endDate
              );
              values2 = response2.data
                .map((measure) => {
                  return { x: measure.date_time, y: measure.volume };
                })
                .sort();
            }

            if (measure2.dataStructure === "total-data") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response2 = await DataAnalysisApi.getMeasuresChart<TotalDataInterface[]>(
                measure2.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );
              values2 = response2.data.map((measure) => {
                return { x: measure.date, y: measure.value };
              });
            }

            if (measure2.dataStructure === "sewerage") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<ChartData>(
                measure2.link,
                totalStartDate,
                totalEndDate,
                [{ name: "Interval", value: "month" }]
              );

              for (let i = 0; i < response.data.dates.length; i++) {
                values2.push({ x: response.data.dates[i], y: response.data.series[0].data[i] });
              }
            }

            if (measure2.dataStructure === "supply-sale-water") {
              if (isFixedTime) {
                totalEndDate = startDate;
                totalStartDate = moment(
                  new Date(new Date(startDate).getTime() - daysInterval * (1000 * 3600 * 24))
                ).format("YYYY-MM-DD");
              }
              const response = await DataAnalysisApi.getMeasuresChart<SupplySaleWaterDatesInterface>(
                measure2.link,
                totalStartDate,
                totalEndDate
              );

              if (measure2.id === 1) {
                for (let i = 0; i < response.data.dates.length; i++) {
                  values2.push({ x: response.data.dates[i], y: response.data.data[0].data[i] });
                }
              } else {
                // id===3
                for (let i = 0; i < response.data.dates.length; i++) {
                  if (i < response.data.data[1].data.length)
                    values2.push({ x: response.data.dates[i], y: response.data.data[1].data[i] });
                }
              }
            }

            values2.sort(function (a, b) {
              if (a.x < b.x) {
                return -1;
              }
              if (a.x > b.x) {
                return 1;
              }
              return 0;
            });
            setMeasureData2(values2);
          }
          setLineGraph({
            ...lineGraph,
            series: [
              { name: measure1.name, data: values1, color: "#0E9CFF" },
              { name: measure2.name, data: values2, color: "#EAAF6B" },
            ],
          });
          setIsLoadingLine(false);
          break;
        }
      }
    } catch (e) {
      console.error(`option: ${option}`, e);
    }
  }

  function updateTableData() {
    if (measureData1.length > 0 || measureData2.length > 0) {
      let tableData: BaseTableDataItem[] = [];
      let DataCases = measureData1.length - measureData2.length;

      if (DataCases === 0) {
        for (let i = 0; i < measureData1.length; i++) {
          tableData.push({
            id: measureData1[i].x + "_" + measureData1[i].y + measureData2[i].y + i,
            date: measureData1[i].x,
            indication1: measureData1[i].y,
            indication2: measureData2[i].y,
          });
        }
      } else if (DataCases < 0) {
        let i = 0;
        for (i; i < measureData1.length; i++) {
          tableData.push({
            id: measureData1[i].y + measureData2[i].y.toString() + i,
            date: "",
            indication1: measureData1[i].y,
            indication2: measureData2[i].y,
          });
        }
        for (i; i < measureData2.length; i++) {
          tableData.push({
            id: "null" + measureData2[i].y + i,
            date: "",
            indication1: "",
            indication2: measureData2[i].y,
          });
        }
      } else if (DataCases > 0) {
        let i = 0;
        for (i; i < measureData2.length; i++) {
          tableData.push({
            id: measureData1[i].y + measureData2[i].y.toString() + i,
            date: "",
            indication1: measureData1[i].y,
            indication2: measureData2[i].y,
          });
        }
        for (i; i < measureData1.length; i++) {
          tableData.push({
            id: measureData1[i].y + "null" + i,
            date: "",
            indication1: measureData1[i].y,
            indication2: "",
          });
        }
      }

      setData(tableData);
    } else {
      setData([]);
    }
    setIsLoadingTable(false);
  }

  useEffect(() => {
    buttonClassChange("buttonYear");
    setTimePeriod("year");
  }, []);

  useEffect(() => {
    updateTableData();
  }, [measureData1, measureData2]);

  // useEffect(() => {
  //   console.log(lineGraph.series);
  // }, [lineGraph.series]);

  return (
    <Box sx={styles.contentContainer}>
      <Box className="headerH2">Сравнение показателей технологического и коммерческого балансов</Box>

      <Box className="leftLegendaDate">
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Показатель 1</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Показатель 1"
            value={measure1.id}
            onChange={(e) => {
              const chosenMeasure = paramsList.find((measure) => measure.id === e.target.value);
              if (chosenMeasure) {
                setMeasure1(chosenMeasure);
                getMeasuresChart(startDate, endDate, isFixedPeriod, 1, chosenMeasure);
              }
            }}
          >
            {paramsList.map((param) => {
              return (
                <MenuItem value={param.id} key={param.link} style={param.isHidden ? { display: "none" } : {}}>
                  {param.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Показатель 2</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Показатель 2"
            value={measure2.id}
            onChange={(e) => {
              const chosenMeasure = paramsList.find((measure) => measure.id === e.target.value);
              if (chosenMeasure) {
                setMeasure2(chosenMeasure);
                getMeasuresChart(startDate, endDate, isFixedPeriod, 2, chosenMeasure);
              }
            }}
          >
            {paramsList.map((param) => {
              return (
                <MenuItem value={param.id} key={param.link} style={param.isHidden ? { display: "none" } : {}}>
                  {param.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <CustomButton
          style={{ width: "150px", height: "40px" }}
          type="timePeriodButton"
          id="buttonYear"
          onClick={() => {
            buttonClassChange("buttonYear");
            setTimePeriod("year");
          }}
        >
          12 мес
        </CustomButton>
        <CustomButton
          style={{ width: "150px", height: "40px" }}
          type="timePeriodButton"
          id="buttonHalfYear"
          onClick={() => {
            buttonClassChange("buttonHalfYear");
            setTimePeriod("halfYear");
          }}
        >
          6 мес
        </CustomButton>
        <CustomButton
          style={{ width: "150px", height: "40px" }}
          type="timePeriodButton"
          id="buttonQuarterYear"
          onClick={() => {
            buttonClassChange("buttonQuarterYear");
            setTimePeriod("quarter");
          }}
        >
          3 мес
        </CustomButton>
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          Период С
        </div>
        <TextField
          id="date1"
          size="small"
          type="date"
          style={{ width: "100%" }}
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value as string);
            buttonClassChange("undefined");
            getMeasuresChart(e.target.value, endDate, false);
          }}
        />
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          По
        </div>
        <TextField
          size="small"
          id="date2"
          style={{ width: "100%" }}
          type="date"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value as string);
            buttonClassChange("undefined");
            getMeasuresChart(startDate, e.target.value, false);
          }}
        />
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Годы</InputLabel>
          <Select
            disabled={measure1.id !== measure2.id}
            multiple
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={years}
            label="Годы"
            onChange={(e) => {
              if (Array.isArray(e.target.value)) {
                buttonClassChange("undefined");
                if (e.target.value.length <= 2) {
                  setYears(e.target.value);
                  getMeasuresChart(startDate, endDate, true, 11, measure1, e.target.value);
                }
              }
            }}
          >
            <MenuItem value={2025}>2025</MenuItem>
            <MenuItem value={2024}>2024</MenuItem>
            <MenuItem value={2023}>2023</MenuItem>
            <MenuItem value={2022}>2022</MenuItem>
            <MenuItem value={2021}>2021</MenuItem>
            <MenuItem value={2020}>2020</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <div style={{ height: "357px", width: "100%" }}>
          {isLoadingLine ? (
            <Animation></Animation>
          ) : (
            <Chart height={"100%"} width={"100%"} options={lineGraph} series={lineGraph.series} type="line" />
          )}
        </div>
      </Box>

      <Box sx={styles.tableContainer}>
        <BaseTable columns={columns} data={data} isLoading={isLoadingTable} useClientPagination />
      </Box>
    </Box>
  );
};

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",

    paddingTop: "20px",
  },
  tableContainer: {
    height: "400px",
  },
};
