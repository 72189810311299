import React, { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { GetBGLoss, GetBGLossesTable, BGLossTable } from "../../BalansApis";
import { ApexLocales } from "../../../../constants/ApexChartsSettings";
import { useMatch } from "react-router-dom";
import { routeUrls } from "../../../../routes/routeUrls";

interface BGLossInterface {
  bgNumber: number;
}

const BGLoss: React.FC<BGLossInterface> = ({ bgNumber }) => {
  const [textTable, setTextTable] = useState("%");
  const columns: GridColDef[] = [
    {
      field: "month",
      headerName: "Дата",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "lossNow",
      headerName: `Потери в 2023, ${textTable}`,
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "lossPrev",
      headerName: `Потери в 2022, ${textTable}`,
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "predLossNow",
      headerName: `Прогнозные потери в 2023, ${textTable}`,
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
  ];

  const [isLoadingBar, setIsLoadingBar] = useState(false);
  const [barOpt, setBarOpt] = useState<ApexOptions>({
    series: [],
    chart: {
      type: "bar",
      width: "100%",
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      colors: ["#1599D7"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 2,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ["Янв", "Фев", "Мар", "Aпр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    },
    yaxis: {
      title: {
        text: `м.куб`,
      },
    },
  });

  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [switchUnit, setSwitchUnit] = useState("true");
  const [period, setPeriod] = useState("year");
  const buttonSensor = ["buttonYear", "buttonQuarter", "buttonMonth"];
  const [lossesBGTable, setLossesBGTable] = useState<BGLossTable[]>([]);

  const routeMatch = useMatch({ path: routeUrls.bg });
  const zoneNumber = routeMatch?.params.zone;

  // Функция смены класса кнопки периода
  function buttonClassChange(id: string) {
    // Находим кнопку по id
    var btn = document.getElementById(id);
    // Была ли кнопка найдена
    if (btn !== null) {
      btn.classList.add("timePeriodButtonActive");
    }
    if (btn !== null || id === "undefined") {
      buttonSensor.forEach((button) => {
        if (button !== id) {
          var btn1 = document.getElementById(button);
          if (btn1 !== null) {
            btn1.classList.remove("timePeriodButtonActive");
          }
        }
      });
    }
  }

  function setTimePeriod(type: string) {
    let per = "";
    setIsLoadingBar(false);
    switch (type) {
      case "year":
        per = "year";
        break;

      case "quarter":
        per = "quarter";
        break;

      case "month":
        per = "month";
        break;

      default:
        per = "year";
    }
    setPeriod(per);
    getBGLoss(per, switchUnit);
  }

  async function getBGLoss(period: string, percent: string) {
    if (zoneNumber === undefined || bgNumber < 1000) return 0;

    setIsLoadingBar(true);
    try {
      const response = await GetBGLoss(period, zoneNumber, bgNumber, percent);
      // console.log(response.serias)
      if (percent === "false")
        setBarOpt({
          ...barOpt,
          series: response.series,
          xaxis: { categories: response.categories },
          yaxis: { title: { text: "тыс. м. куб" } },
        });
      else
        setBarOpt({
          ...barOpt,
          series: response.series,
          xaxis: { categories: response.categories },
          yaxis: { title: { text: "%" } },
        });
      setIsLoadingBar(false);
    } catch (e) {
      console.log(e);
    }
  }

  async function getLossesTable(percent: string) {
    if (zoneNumber === undefined || bgNumber < 1000) return 0;
    setIsLoadingTable(true);
    try {
      const response = await GetBGLossesTable(zoneNumber, bgNumber, percent);
      // console.log(response);
      setLossesBGTable(response);
      setIsLoadingTable(false);
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    buttonClassChange("buttonYear");
  }, []);

  useEffect(() => {
    getLossesTable(switchUnit);
    getBGLoss(period, switchUnit);
  }, [bgNumber]);

  return (
    <div className="balanceTabDefault">
      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          Потери внутри БГ
        </div>
        <FormControlLabel
          control={
            <Switch
              defaultValue={"false"}
              onClick={() => {
                if (switchUnit === "false") {
                  setTextTable("%");
                  setSwitchUnit("true");
                  getBGLoss(period, "true");
                  getLossesTable("true");
                } else {
                  setTextTable("тыс. м. куб");
                  setSwitchUnit("false");
                  getBGLoss(period, "false");
                  getLossesTable("false");
                }
              }}
            />
          }
          label={
            <div className="usualText" style={{ whiteSpace: "nowrap" }}>
              Абсолютная величина
            </div>
          }
        />
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonYear"
          onClick={() => {
            buttonClassChange("buttonYear");
            setTimePeriod("year");
          }}
        >
          Годы
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonQuarter"
          onClick={() => {
            buttonClassChange("buttonQuarter");
            setTimePeriod("quarter");
          }}
        >
          Кварталы
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonMonth"
          onClick={() => {
            buttonClassChange("buttonMonth");
            setTimePeriod("month");
          }}
        >
          Месяцы
        </CustomButton>
      </div>
      <div className="lowerChart" style={{ height: "300px", width: "100%" }}>
        {isLoadingBar ? (
          <Animation></Animation>
        ) : (
          <Chart height={"100%"} width={"100%"} options={barOpt} series={barOpt.series} type="bar" />
        )}
      </div>
      <Box sx={{ width: "100%" }} className="lossesTable tableHeight">
        {isLoadingTable ? (
          <Animation></Animation>
        ) : (
          <DataGrid
            rows={lossesBGTable}
            columns={columns}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </Box>
    </div>
  );
};

export default BGLoss;
