import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { BaseModalProps } from "../../../../components/baseModal/BaseModal";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { Form, Formik, FormikHelpers } from "formik";
import { useEffect } from "react";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";
import * as yup from "yup";
import { ObjectSchema, object } from "yup";
import { TextInput } from "../../../../components/formFields/formikFields/textInput/textInput";
import { SelectField } from "../../../../components/formFields/formikFields/selectField/selectField";
import { LoadingState } from "../../../../types/loadingState";
import Loader from "../../../../components/uiComponents/Animation/Animation";
import { isNullOrEmpty } from "../../../../utilities/string/isNullOrEmpty";
import { UserEditorDto } from "../../../../models/userManagement/userEditorDto";
import { useSnackbar } from "notistack";

import {
  createUser,
  editUser,
  getRoleSelectItems,
  getUser,
  setInitialState,
} from "../../../../store/slices/userManagement/createEditUserSlice";

const editSchema: ObjectSchema<UserEditorDto> = object({
  email: yup.string().email("введите валидный email"),
  firstName: yup.string().max(255).required(),
  middleName: yup.string().max(255).required(),
  lastName: yup.string().max(255).required(),
  district: yup.string().max(255).optional(),
  position: yup.string().max(255).required(),
  department: yup.string().max(255).required(),
  roleGuid: yup.string().required(),
  roleId: yup.string().required(),
});

const createSchema = editSchema.concat(
  object({
    email: yup.string().email("введите валидный email").required(),
    roleId: yup.string().optional(),
  })
);

interface CreateEditUserModalProps extends BaseModalProps {
  id?: string;
}

export const CreateEditUserModal = (props: CreateEditUserModalProps) => {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();

  const isLoading = useTypedSelector((store) => store.createEditUser.isLoading);
  const editorDto = useTypedSelector((store) => store.createEditUser.userEditorDto);
  const roleSelectItems = useTypedSelector((store) => store.createEditUser.roleSelectItems);
  const isCreate = isNullOrEmpty(props.id);
  const validationSchema = isCreate ? createSchema : editSchema;
  const dialogTitle = isCreate ? "Новый пользователь" : "Редактирование пользователя";
  const buttonText = isCreate ? "Создать" : "Обновить";

  const handleSave = async (values: UserEditorDto, formikHelpers: FormikHelpers<UserEditorDto>) => {
    const result = isCreate
      ? await dispatch(createUser({ data: values }))
      : await dispatch(editUser({ id: props.id!, data: values }));

    if (result.error) {
      enqueueSnackbar(`Произошла ошибка: ${result.payload}`, { variant: "error" });
      return;
    }

    props.onSave && props.onSave();
  };

  const handleCancel = () => {
    props.onCancel && props.onCancel();
  };

  useEffect(() => {
    dispatch(getRoleSelectItems());
    if (!isCreate) {
      dispatch(getUser({ id: props.id! }));
    }

    return () => {
      dispatch(setInitialState());
    };
  }, []);

  return (
    <Formik initialValues={editorDto} enableReinitialize validationSchema={validationSchema} onSubmit={handleSave}>
      {(formik) => (
        <Dialog open maxWidth="md">
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent>
            <Box sx={{ position: "relative" }}>
              <Form>
                <Grid sx={styles.formGrid} container rowSpacing={4} columnSpacing={4}>
                  <Grid item xs={4}>
                    <TextInput label="Фамилия" name="lastName" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Имя" name="firstName" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Отчество" name="middleName" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Должность" name="position" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Подразделение" name="department" />
                  </Grid>
                  <Grid item xs={4}>
                    <TextInput label="Район" name="district" />
                  </Grid>
                  {isCreate ? (
                    <>
                      <Grid item xs={6}>
                        <SelectField label="Роль" name="roleGuid" options={roleSelectItems} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextInput label="Email" name="email" />
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={6}>
                      <SelectField label="Роль" name="roleId" options={roleSelectItems} />
                    </Grid>
                  )}
                </Grid>
              </Form>
              {isLoading === LoadingState.PENDING && <Loader asOverlay />}
            </Box>
          </DialogContent>

          <DialogActions>
            <CustomButton onClick={formik.submitForm} disabled={isLoading === LoadingState.PENDING}>
              {buttonText}
            </CustomButton>
            <CustomButton onClick={handleCancel} disabled={isLoading === LoadingState.PENDING} className="cancel">
              Отмена
            </CustomButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

const styles = {
  formGrid: {
    padding: "6px",
  },
};
