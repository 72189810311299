import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import BGLoss from "./loss/BGLoss";
import Passport from "./passport/Passport";
import Supply from "./supply/Supply";
import DistributionBG from "./distribution/DistributionBG";
import { useMatch, useNavigate } from "react-router-dom";
import { GetBalanceGroupsByZone, BalanceGroupShortInfo } from "../BalansApis";
import { routeUrls } from "../../../routes/routeUrls";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface BGInterface {}

const BG: React.FC<BGInterface> = () => {
  const [balanceGroupNumber, setBalanceGroupNumber] = React.useState(0);
  const navigate = useNavigate();
  const routeMatch = useMatch({ path: routeUrls.bg });
  const [balanceGroups, setBalanceGroups] = useState<BalanceGroupShortInfo[]>([]);

  const handleChange1 = (event: SelectChangeEvent) => {
    setBalanceGroupNumber(Number(event.target.value));
    navigate(`/balance/zones/${routeMatch?.params.zone}/${event.target.value}`);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  async function getBalanceGroups() {
    if (routeMatch !== null && routeMatch.params.zone !== undefined) {
      try {
        const response = await GetBalanceGroupsByZone(routeMatch.params.zone);
        setBalanceGroups(response);
      } catch (e) {
        console.warn(e);
      }
    } else {
      console.warn("Не верная ссылка");
    }
  }

  useEffect(() => {
    getBalanceGroups();
    if (routeMatch !== null && routeMatch.params.bg !== undefined) setBalanceGroupNumber(Number(routeMatch?.params.bg));
  }, []);

  return (
    <div className="defaultPageStyle">
      <div style={{ marginTop: "12px" }}>
        <span
          className="usualText"
          style={{ marginRight: "8px", color: "#777777", cursor: "pointer" }}
          onClick={() => {
            window.location.assign("/balance");
          }}
        >
          Сводная информация
        </span>
        <span className="usualText" style={{ marginRight: "8px", color: "#777777" }}>
          {"->"}
        </span>
        <span
          className="usualText"
          style={{ marginRight: "8px", color: "#777777", cursor: "pointer" }}
          onClick={() => {
            window.location.assign(`/balance/zones/${routeMatch?.params.zone} `);
          }}
        >
          Зона {routeMatch?.params.zone}
        </span>
        <span className="usualText" style={{ marginRight: "8px", color: "#777777" }}>
          {"->"}
        </span>
        <span className="usualText" style={{ marginRight: "8px", color: "#777777" }}>
          БГ {routeMatch?.params.bg}
        </span>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <Box sx={{ minWidth: 120, height: " 40px" }}>
          <FormControl size="small" fullWidth style={{ height: " 40px" }}>
            <InputLabel id="demo-simple-select-label">БГ</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={routeMatch?.params.bg}
              label="Балансовая группа"
              style={{ height: " 40px" }}
              onChange={handleChange1}
            >
              {balanceGroups.map((bg) => {
                return (
                  <MenuItem value={bg.id} key={bg.id}>
                    {bg.fullName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: "#1478BE" } }}
          aria-label="simple tabs example"
          style={{ width: "100%" }}
        >
          <Tab label="Паспорт" {...a11yProps(0)} />
          <Tab label="Подача" {...a11yProps(1)} />
          <Tab label="Реализация" {...a11yProps(2)} />
          <Tab label="Потери" {...a11yProps(3)} />
        </Tabs>
      </div>
      {/* Начинка внутри разделов */}
      <TabPanel value={value} index={0}>
        <Passport bgNumber={balanceGroupNumber}></Passport>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Supply bgNumber={balanceGroupNumber}></Supply>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DistributionBG bgNumber={balanceGroupNumber}></DistributionBG>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BGLoss bgNumber={balanceGroupNumber}></BGLoss>
      </TabPanel>
    </div>
  );
};

export default BG;
