import { Navigate } from "react-router-dom";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { AccessService } from "../../utilities/access/accessService";
import { BalanceRoutes } from "./balanceRoutes";
import { routeUrls } from "../routeUrls";
import { Forbidden } from "../../pages/common/forbidden/forbidden";

export const BalanceRouteWrapper = (props: { children: React.ReactElement; path: string }) => {
  const userInfo = useTypedSelector((state) => state.account.userInfo);
  const accessList = userInfo?.accessList || [];

  const filteredBalanceRoutes = BalanceRoutes.filter((x) => accessList.includes(x));

  if (!AccessService.hasAccess(props.path, accessList)) {
    if (filteredBalanceRoutes.length > 0) {
      return <Navigate to={filteredBalanceRoutes[0]} />;
    } else {
      return <Forbidden />;
    }
  }

  return props.children;
};
