import { Box } from "@mui/material";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import { EventStatisticCardDto } from "../../../../models/eventStatisticCard/eventStatisticCardDto";

interface MonitoringEventsStatisticCardProps {
  card: EventStatisticCardDto;
  totalCount: number;
  color: string;
  hasChartReversed?: boolean;
}

export const MonitoringEventsStatisticCard = ({
  card,
  totalCount,
  color,
  hasChartReversed = false,
}: MonitoringEventsStatisticCardProps) => {
  const options: ApexOptions = {
    tooltip: { enabled: false },
    colors: hasChartReversed ? ["#FFFFFF", color] : [color, "#FFFFFF"],
    series: hasChartReversed
      ? [totalCount - card.totalCount, card.totalCount]
      : [card.totalCount, totalCount - card.totalCount],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
      pie: {
        donut: {
          size: "50%%",
          labels: {
            show: false,
            name: {
              show: false,
            },
            value: {
              offsetY: 7,
              show: true,
              fontWeight: "600",
            },
            total: {
              show: true,
            },
          },
        },
        dataLabels: {
          offset: -50,
        },
      },
    },
  };
  return (
    <Box sx={styles.card}>
      <Box sx={styles.left}>
        <Box sx={styles.column}>
          <Box sx={[styles.row, styles.title]}>{card.header}</Box>
          <Box sx={[styles.row, styles.value]}>{card.totalCount}</Box>
        </Box>
        {card.cardAdditionDtos && card.cardAdditionDtos.objectsCount > 0 && (
          <Box sx={styles.column}>
            <Box sx={[styles.row, styles.otherInfo]}>
              <Box>Зоны:</Box>
              <Box sx={styles.blueText}>{card.cardAdditionDtos.zones.join(", ")}</Box>
            </Box>
            <Box sx={[styles.row, styles.otherInfo]}>
              <Box>Объекты:</Box>
              <Box sx={styles.blueText}>{card.cardAdditionDtos.objectsCount}</Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={styles.right}>
        {card.countMin !== undefined && card.countMin > 0 && (
          <Box sx={styles.countChangeCell}>
            <Box sx={styles.minus}>-{card.countMin}</Box>
            <Box sx={styles.countChangeText}>{card.text}</Box>
          </Box>
        )}
        {card.countPlus !== undefined && card.countPlus > 0 && (
          <Box sx={styles.countChangeCell}>
            <Box sx={styles.plus}>+{card.countPlus}</Box>
            <Box sx={styles.countChangeText}>{card.text}</Box>
          </Box>
        )}
        <Box sx={styles.donut}>
          <Chart width={96} height={117} options={options} series={options.series} type="donut" />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  card: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    background: "#F7F8F9",
    borderRadius: "16px",
    padding: "16px",
    height: "calc(100% - 32px)",
  },
  left: {
    flex: "0 1 50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  row: {
    display: "flex",
  },
  title: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
  },
  value: {
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "130%",
  },
  right: {
    flex: "0 1 50%",
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
    alignItems: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  countChangeCell: {
    display: "flex",
    flex: "0 1 auto",
    gap: "6px",
  },
  minus: {
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "130%",
    color: "#4BB8A9",
  },
  plus: {
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "130%",
    color: "#CA807F",
  },
  countChangeText: {
    height: "36px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "130%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  otherInfo: {
    display: "flex",
    gap: "4px",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "100%",
    color: "#5E6877",
  },
  blueText: {
    color: "#1478BE",
  },
  donut: {
    marginLeft: "38px",
  },
};
