import { redirect } from "react-router-dom";
import { routeUrls } from "./routeUrls";

export const loginLoader = () => {
  const isAuthenticated = Boolean(localStorage.getItem("accessToken"));
  if (isAuthenticated) {
    return redirect(routeUrls.home);
  }

  return null;
};
