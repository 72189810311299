import { routeUrls } from "../../routes/routeUrls";
import { Roles } from "./components/roles/roles";
import { Users } from "./components/users/users";
import { UsersTabManager } from "./components/usersTabManager/usersTabManager";

export const UserManagementTabsConfig = [
  {
    label: "Список пользователей",
    route: routeUrls.userList,
  },
  {
    label: "Список ролей",
    route: routeUrls.roleList,
  },
];
