import { Box } from "@mui/material";
import Header from "../../../components/simple/Header/Header";
import { Outlet } from "react-router";

export const Layout = () => {
  return (
    <>
      <Header />

      <Box sx={styles.contentContainer}>
        <Outlet />
      </Box>
    </>
  );
};

const styles = {
  contentContainer: {
    marginTop: "88px",
    padding: "0 16px 16px 16px",
    height: "calc(100vh - 104px)",
  },
};
