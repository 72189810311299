import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Loss from "./loss/Loss";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Supply from "./supply/Supply";
import Distribution from "./distribution/Distribution";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useMatch, useNavigate } from "react-router-dom";
import { GetZonesList, ZoneShortInfo } from "../BalansApis";
import ElectricityZone from "./electricity/ElectricityZone";
import { routeUrls } from "../../../routes/routeUrls";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface ZonesInterface { }

const Zones: React.FC<ZonesInterface> = () => {

  const navigate = useNavigate();
  const routeMatch = useMatch({ path: "balance/zones/:zone" });

  const [value, setValue] = React.useState(0);
  const [zones, setZones] = useState<ZoneShortInfo[]>([]);

  const dispatch = useDispatch();
  const { zoneNumber } = useTypedSelector((state) => state.zone);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const changeZone = (newZone: string) => {
    navigate(`/balance/zones/${newZone}`);
    dispatch({ type: "CHANGE_ZONE", payload: newZone });
  };

  async function getZones() {
    try {
      const response = await GetZonesList();
      setZones(response);
    } catch (e) {
      console.warn(e);
    }
  }

  useEffect(() => {
    getZones();
    changeZone(`${routeMatch?.params.zone}`);
    // console.log(routeMatch, routeMatch?.params, routeMatch?.params.zone);
  }, []);

  return (
    <div className="defaultPageStyle">
      <div style={{ marginTop: "12px" }}>
        <span
          className="usualText"
          style={{ marginRight: "8px", color: "#777777", cursor: "pointer" }}
          onClick={() => {
            window.location.assign("/balance");
          }}
        >
          Сводная информация
        </span>
        <span className="usualText" style={{ marginRight: "8px", color: "#777777" }}>
          {"->"}
        </span>
        <span className="usualText" style={{ color: "#777777" }}>
          Зона {routeMatch?.params.zone}
        </span>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
        <Box sx={{ minWidth: 120, height: " 40px" }}>
          <FormControl size="small" fullWidth style={{ height: " 40px" }}>
            <InputLabel id="demo-simple-select-label">Зона</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={zoneNumber}
              label="Zone"
              style={{ height: " 40px" }}
              onChange={(event) => changeZone(event?.target.value as string)}
            >
              {zones.map((z) => {
                return (
                  <MenuItem value={z.number} key={z.id}>
                    {z.fullName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{ style: { background: "#1478BE" } }}
          aria-label="simple tabs example"
          style={{ width: "100%" }}
        >
          <Tab label="Подача" {...a11yProps(0)} />
          <Tab label="Реализация" {...a11yProps(1)} />
          <Tab label="Потери" {...a11yProps(2)} />
          <Tab label="Электроэнергия" {...a11yProps(3)} />
        </Tabs>
      </div>
      {/* Начинка внутри разделов */}
      <TabPanel value={value} index={0}>
        <Supply></Supply>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Distribution></Distribution>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Loss></Loss>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ElectricityZone></ElectricityZone>
      </TabPanel>
    </div>
  );
};

export default Zones;
