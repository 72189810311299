import AuthAxios, { backURLs } from "../../../core/APIs";
import { RoleSelectItemDto } from "../../../models/userManagement/roleSelectItemDto";
import { UserEditorDto } from "../../../models/userManagement/userEditorDto";
import { PageAccessData, putUser, Role, UserAccessPages, UsersTableData } from "../types/usersTypes";

export const usersApi = {
  putSelfUser: async (body: putUser) => {
    const { data } = await AuthAxios.put(`${backURLs.account}/update-user-info`, body);

    return data;
  },
  updatePassword: async (oldPassword: string, password: string, confirmPassword: string) => {
    const { data } = await AuthAxios.post(
      `${backURLs.account}/update-password?oldPassword=${oldPassword}&password=${password}&confirmPassword=${confirmPassword}`
    );
    return data;
  },
  getRoles: async () => {
    const response = await AuthAxios.get<Role[]>(`${backURLs.roles}/info`);
    return response;
  },
  getRoleSelectItems: async () => {
    const response = await AuthAxios.get<RoleSelectItemDto[]>(`${backURLs.roles}/list`);
    return response;
  },
  getUsers: async (pageNum: number, pageSize: number) => {
    const response = await AuthAxios.get<UsersTableData>(`${backURLs.account}/usersManagement`, {
      params: { pageNum, pageSize },
    });
    return response;
  },
  getUserByGuid: async (guid: string) => {
    const response = await AuthAxios.get(`${backURLs.account}/card/${guid}`);
    return response;
  },
  createUser: async (data: UserEditorDto) => {
    const response = await AuthAxios.post<string>(`${backURLs.account}/registration`, data);
    return response;
  },
  editUser: async (id: string, data: UserEditorDto) => {
    const response = await AuthAxios.put(`${backURLs.account}/update-user-info/${id}`, data);
    return response;
  },
  deleteUser: async (userGuid: string) => {
    const response = await AuthAxios.delete(`${backURLs.account}`, {
      params: { userGuid },
    });
    return response;
  },
  getAccessiblePages: async (userGuid: string) => {
    const response = await AuthAxios.get<PageAccessData[]>(`${backURLs.roles}/accessiblePagesByUser/${userGuid}`);
    return response;
  },
  putAccessiblePages: async (data: UserAccessPages) => {
    const response = await AuthAxios.put(`${backURLs.account}/userAccess`, data);
    return response;
  },
};
