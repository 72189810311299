import axios, { backURLs } from "../../core/APIs";
import { ChartData } from "./BalansApis";

export interface SimpleListElem {
  id: number | string;
  name: string;
}

export async function GetObjectTypes() {
  const { data } = await axios.get<SimpleListElem[]>(`${backURLs.electricity}/object-types`);

  return data;
}

export async function GetObjectNames(objectType: string) {
  const { data } = await axios.get<SimpleListElem[]>(`${backURLs.electricity}/object-names?objectType=${objectType}`);

  return data;
}

export async function GetObjectsElectricitySupply(objectsNames: number[] | string[], startDt: string, endDt: string) {
  const { data } = await axios.get<ChartData>(
    `${backURLs.electricity}/supply?${objectsNames
      .map((id) => {
        return `Objects=${id}`;
      })
      .join("&")}&StartDt=${startDt}&EndDt=${endDt}`
  );

  return data;
}
