import { useTypedSelector } from "../hooks/useTypedSelector";
import { Forbidden } from "../pages/common/forbidden/forbidden";
import { AccessService } from "../utilities/access/accessService";

export const ProtectedRouteWrapper = (props: { children: React.ReactElement; path: string }) => {
  const userInfo = useTypedSelector((state) => state.account.userInfo);
  const accessList = userInfo?.accessList || [];

  if (!AccessService.hasAccess(props.path, accessList)) {
    return <Forbidden />;
  }

  return props.children;
};
