import moment from "moment";
import { brandBookColors } from "../../../assets/themes/theme";
import { useState, useEffect } from "react";

export default function Clocks() {
  let date = new Date(); //.toLocaleDateString("ru-RU");
  const [CDtime, setCDtime] = useState(date);

  // Функция обновления времени
  const updateTime = () => {
    //date = new Date();
    setCDtime(new Date());
  };

  useEffect(() => {
    // Функция, вызывающая функцию обновления времени каждые 1 секунд
    setInterval(updateTime, 1 * 1000);
  }, []);
  
  return (
    <div className="headerH2 currentDateTime" style={{ color: brandBookColors.mainGrey }}>
      {moment(CDtime).format("DD.MM.yyyy HH:mm:ss")}
    </div>
  );
}
