import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import BGMap from "./BGMap";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { BGPassportTable, GetBGPassportTable } from "../../BalansApis";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { useMatch } from "react-router-dom";
import { routeUrls } from "../../../../routes/routeUrls";

interface PassportInterface {
  bgNumber: number;
}

const Passport: React.FC<PassportInterface> = ({ bgNumber }) => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Наименование",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "address",
      headerName: "Адрес",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "type",
      headerName: "Тип",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [rowsTable, setRowsTable] = useState<BGPassportTable[]>([]);
  const getRowId = (rowsTable: BGPassportTable) => rowsTable.guid;

  const routeMatch = useMatch({ path: routeUrls.bg });
  const zoneNumber = routeMatch?.params.zone;

  //Запрос для таблицы балансовых групп (Зона.БГ.Паспорт.Таблица)
  async function getBGPassportTable() {
    if (bgNumber < 1000 || zoneNumber === undefined) return 0;
    setIsLoadingTable(false);
    try {
      const response = await GetBGPassportTable(zoneNumber, bgNumber);
      setRowsTable(response);
      setIsLoadingTable(true);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getBGPassportTable();
  }, [bgNumber]);

  return (
    <div style={{ height: "calc(100vh - 200px)", marginTop: "12px" }}>
      <div
        style={{
          width: "100%",
          height: "50%",
          borderRadius: "16px",
          overflow: "hidden",
        }}
      >
        <BGMap zoneNumber={zoneNumber} bgNumber={bgNumber}></BGMap>
      </div>
      <div style={{ height: "50%", width: "100%", marginTop: "12px" }} className="tableHeight">
        {!isLoadingTable ? (
          <Animation></Animation>
        ) : (
          <DataGrid
            rows={rowsTable}
            columns={columns}
            getRowId={getRowId}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </div>
    </div>
  );
};

export default Passport;
