import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPlanLossesData, getPlanLossesData, savePlanLossesData } from "../../pages/balance/BalansApis";
import { LoadingState } from "../../types/loadingState";

const sliceName = "lossesPlan";

interface IInitialState {
  lossesPlanData: number[];
  isLoading: LoadingState;
}

const initialState: IInitialState = {
  lossesPlanData: [],

  isLoading: LoadingState.EMPTY,
};

export const getLossesPlanData = createAsyncThunk<IPlanLossesData>(
  `${sliceName}/getLossesPlanData`,
  async (_, thunkAPI) => {
    try {
      const response = await getPlanLossesData();
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const saveLossesPlanData = createAsyncThunk<void, number[]>(
  `${sliceName}/saveLossesPlanData`,
  async (params, thunkAPI) => {
    try {
      const data = {
        losses: params,
      };
      await savePlanLossesData(data);
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const lossesPlanSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setLossesPlanData: (state, action) => {
      state.lossesPlanData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLossesPlanData.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getLossesPlanData.fulfilled, (state, action) => {
      state.lossesPlanData = action.payload.losses;
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getLossesPlanData.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const lossesPlanReducer = lossesPlanSlice.reducer;
export const { setInitialState, setLossesPlanData } = lossesPlanSlice.actions;
