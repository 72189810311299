import { Balance } from "./tabs/balance/balance";
import { DischangeVolume } from "./tabs/dischangeVolume/dischangeVolume";
import { NetworkSupply } from "./tabs/networkSupply/networkSupply";
import { ProductionVolume } from "./tabs/productionVolume/productionVolume";
import { Anomalies } from "./tabs/anomalies/anomalies";
import { CrossCheckout } from "./tabs/crossCheckout/crossCheckout";
import { ModelCustomer } from "./tabs/modelCustomer/modelCustomer";

export const DataAnalysisTabsConfig = [
  {
    label: "Прогноз подачи",
    route: "productionVolume",
    urlPart: "volume_predict",
    exportDataUrpPart: "export_volume_predict",
    component: <ProductionVolume />,
  },
  {
    label: "Прогноз реализации",
    route: "networkSupply",
    urlPart: "get_supply_sale_water",
    exportDataUrpPart: "export_supply_sale_water",
    component: <NetworkSupply />,
  },
  {
    label: "Прогноз водоотведения",
    route: "dischangeVolume",
    urlPart: "get_sewerage",
    exportDataUrpPart: "export_sewerage",
    component: <DischangeVolume />,
  },
  {
    label: "Прогноз баланса",
    route: "balance",
    urlPart: "get_balance",
    exportDataUrpPart: "export_balance",
    component: <Balance />,
  },
  // {
  //   label: "Аномалии",
  //   route: "anomalies",
  //   component: <Anomalies />,
  // },
  {
    label: "Кросс-проверка",
    route: "crossCheckout",
    component: <CrossCheckout />,
  },
  {
    label: "Эталонный потребитель",
    route: "modelCustomer",
    component: <ModelCustomer />,
  },
];
