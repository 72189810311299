import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApexOptions } from 'apexcharts';
import {
  BalanceChartProps,
  BlocksInfoCard,
  GetCardsByDateRange,
  GetCardsByPeriod,
  GetWaterSupplyByDateRange,
  GetWaterSupplyByPeriod,
  getLossesChartData,
  summaryGraphProps,
} from '../../pages/balance/BalansApis';
import { barOptData, lineGraphData } from '../../pages/balance/summaryData/summaryStaticData';
import { LoadingState } from '../../types/loadingState';
import { RootState } from '../reducers';
import { PeriodType } from '../../models/summaryData/periodType';
import { LossesUnitType } from '../../models/summaryData/lossesUnitType';

const sliceName = 'summaryData';

interface InitialState {
  waterSupplyData: ApexOptions;
  lossesData: ApexOptions;
  cards: BlocksInfoCard[];

  period: PeriodType | null;
  yearToCompare?: string;
  startDate: Date;
  endDate: Date;
  switchUnit: LossesUnitType;

  isSupplyDataLoading: LoadingState;
  isLossesDataLoading: LoadingState;
  isLoadingCards: LoadingState;
}

const initialState: InitialState = {
  waterSupplyData: lineGraphData,
  lossesData: barOptData,
  cards: [],

  period: 'year',
  startDate: new Date(),
  endDate: new Date(),
  switchUnit: 'percent',

  isSupplyDataLoading: LoadingState.EMPTY,
  isLossesDataLoading: LoadingState.EMPTY,
  isLoadingCards: LoadingState.EMPTY,
};

export const getWaterSupplyData = createAsyncThunk<summaryGraphProps, void, { state: RootState }>(`${sliceName}/getWaterSupplyData`, async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState()[sliceName];
    const { period, yearToCompare, startDate, endDate } = state;
    if (period) {
      return await GetWaterSupplyByPeriod(period, yearToCompare);
    } else {
      return await GetWaterSupplyByDateRange(startDate, endDate, yearToCompare);
    }
  } catch (err) {
    return thunkAPI.rejectWithValue('Ошибка соединения. Повторите попытку');
  }
});

interface IGetBalanceChart extends BalanceChartProps {
  switchUnit: string;
}

export const getLossesData = createAsyncThunk<IGetBalanceChart, void, { state: RootState }>(`${sliceName}/getLossesData`, async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState()[sliceName];
    const { period, switchUnit } = state;
    const data = await getLossesChartData(period!, switchUnit);
    return { ...data, switchUnit };
  } catch (err) {
    return thunkAPI.rejectWithValue('Ошибка соединения. Повторите попытку');
  }
});

export const getCards = createAsyncThunk<BlocksInfoCard[],void, { state: RootState }>(`${sliceName}/getCards`, async (_, thunkAPI) => {
  try {
    const state = thunkAPI.getState()[sliceName];
    const { period, startDate, endDate } = state;
    if (period) {
      return await GetCardsByPeriod(period);
    } else {
      return await GetCardsByDateRange(startDate, endDate);
    }
  } catch (err) {
    return thunkAPI.rejectWithValue('Ошибка соединения. Повторите попытку');
  }
});

const summaryDataSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setPeriod: (state, action) => {
      state.period = action.payload;
    },
    setYearToCompare: (state, action) => {
      state.yearToCompare = action.payload;
    },
    setStartDate: (state, action) => {
      state.period = null;
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.period = null;
      state.endDate = action.payload;
    },
    setSwitchUnit: (state, action) => {
      state.switchUnit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWaterSupplyData.pending, (state) => {
      state.isSupplyDataLoading = LoadingState.PENDING;
    });
    builder.addCase(getWaterSupplyData.fulfilled, (state, action) => {
      const { data, categories } = action.payload;
      state.waterSupplyData = {
        ...state.waterSupplyData,
        series: data,
        xaxis: { categories: categories },
      };
      state.isSupplyDataLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getWaterSupplyData.rejected, (state) => {
      state.isSupplyDataLoading = LoadingState.ERROR;
    });

    builder.addCase(getLossesData.pending, (state) => {
      state.isLossesDataLoading = LoadingState.PENDING;
    });
    builder.addCase(getLossesData.fulfilled, (state, action) => {
      const { losses, categories, switchUnit } = action.payload;
      state.lossesData = {
        ...state.lossesData,
        series: [
          {
            name: switchUnit === 'percent' ? 'потери, %' : 'потери, тыс. м.куб',
            data: losses,
          },
        ],
        xaxis: { categories: categories },
      };
      state.isLossesDataLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getLossesData.rejected, (state) => {
      state.isLossesDataLoading = LoadingState.ERROR;
    });

    builder.addCase(getCards.pending, (state) => {
      state.isLoadingCards = LoadingState.PENDING;
    });
    builder.addCase(getCards.fulfilled, (state, action) => {
      state.cards = action.payload;
      state.isLoadingCards = LoadingState.SUCCESS;
    });
    builder.addCase(getCards.rejected, (state) => {
      state.isLoadingCards = LoadingState.ERROR;
    });
  },
});

export const summaryDataReducer = summaryDataSlice.reducer;
export const { setInitialState, setSwitchUnit, setPeriod, setYearToCompare, setStartDate, setEndDate } = summaryDataSlice.actions;
