import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";

interface TextInputProps {
  name: string;
}

export const TextInput = (props: TextInputProps & TextFieldProps) => {
  const [field, meta, helpers] = useField(props.name);
  return (
    <TextField
      fullWidth
      size="small"
      {...props}
      {...field}
      error={meta.touched && Boolean(meta.error)}
      helperText={(meta.touched && meta.error && meta.error)}
    />
  );
};
