import { Box, Button } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { TextInput } from "../../../../components/formFields/formikFields/textInput/textInput";
import { AuthenticationDataDto } from "../../../../models/account/authenticationDataDto";
import { authenticate, setCurrentDisplayedForm } from "../../../../store/slices/login/loginSlice";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { ObjectSchema, object } from "yup";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { LoadingState } from "../../../../types/loadingState";
import { useSnackbar } from "notistack";
import { FormType } from "../../../../models/account/formType";
import { HeaderText } from "../../../../components/headerText/headerText";
import { TextMessage } from "../../../../components/textMessage/textMessage";

interface Props {
  onSuccess: () => void;
}

const validationSchema: ObjectSchema<AuthenticationDataDto> = object({
  email: yup.string().email("введите валидный email").required(),
  password: yup.string().max(255).required(),
});

export const LoginForm = ({ onSuccess }: Props) => {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();

  const isLoading = useTypedSelector((state) => state.login.isLoginLoading);

  const handleSubmit = async (values: AuthenticationDataDto, formikHelpers: FormikHelpers<AuthenticationDataDto>) => {
    const result = await dispatch(authenticate(values));
    if (result.error) {
      enqueueSnackbar(`Произошла ошибка: ${result.payload}`, { variant: "error" });
    } else {
      onSuccess();
    }
  };

  const handleForgotPassword = () => {
    dispatch(setCurrentDisplayedForm(FormType.ForgotPassword));
  };

  return (
    <Formik initialValues={{ email: "", password: "" }} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(formik) => (
        <>
          <HeaderText>Войти в систему</HeaderText>
          <TextMessage>Введите email и пароль для входа в систему</TextMessage>
          <Form>
            <Box sx={styles.fieldsContainer}>
              <TextInput size="medium" label="Email" name="email" />
              <TextInput size="medium" label="Пароль" name="password" type="password" />
              <Box sx={styles.forgotPasswordButtonContainer}>
                <Button sx={styles.forgotPasswordButton} onClick={handleForgotPassword}>
                  Забыли пароль?
                </Button>
              </Box>
              <Button className="primary" type="submit" disabled={isLoading === LoadingState.PENDING}>
                Войти
              </Button>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

const styles = {
  fieldsContainer: {
    backgroundColor: "white",
    boxShadow: "0px 40px 80px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  forgotPasswordButtonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  forgotPasswordButton: {
    width: "180px",
  },
};
