import { createAsyncThunk } from "@reduxjs/toolkit";
import { settingsTableApi } from "../../../pages/settings/api/SettingsTableApi";
import { RootState } from "../../reducers";

export const deleteElectricityConsumedStandardsValue = createAsyncThunk<void, string, { state: RootState }>(
  `settingsTable/deleteSettingsTableData`,
  async (guid, thunkAPI) => {
    try {
      await settingsTableApi.deleteElectricityConsumedStandardsValue(guid);
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);
