import { zoneState, zoneAction, zoneActionTypes } from "../../types/zone";

const initialState: zoneState = {
  zoneNumber: "",
};

export const zoneReducer = (state = initialState, action: zoneAction): zoneState => {
  switch (action.type) {
    case zoneActionTypes.CHANGE_ZONE:
      return { ...state, zoneNumber: action.payload };

    default:
      return state;
  }
};
