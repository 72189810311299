import { Box, Checkbox, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { BaseTableColumnDef } from "../../../../models/baseTable/baseTableColumns";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";
import { useEffect, useState } from "react";
import moment from "moment";
import { DataAnalysisApi, OptionsList } from "../../api/dataAnalysisApi";
import { ApexOptions } from "apexcharts";
import { ApexLocales } from "../../../../constants/ApexChartsSettings";
import Chart from "react-apexcharts";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import { brandBookColors } from "../../../../assets/themes/theme";
import { BaseTableDataItem } from "../../../../models/baseTable/baseTableDataItem";

const selectorsNames = ["floors", "area", "apartments", "purpose", "pipe"];

export const ModelCustomer = () => {
  const columns: BaseTableColumnDef[] = [
    {
      headerName: "Название объекта",
      field: "name",
    },
    {
      headerName: "Потребление",
      field: "volume",
    },
    {
      headerName: "Участие в расчёте",
      field: "isInCalculation",
      maxWidth: 160,
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "center",
      renderCell: (params) => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
          UpdateExcludedList(params.row.guid, checked);
        };
        const isChecked = (id: string) => {
          return !excludedConsumers.includes(id);
        };
        return <Checkbox checked={isChecked(params.row.guid)} defaultChecked onChange={handleChange} />;
      },
    },
  ];

  const UpdateExcludedList = (id: string, value: boolean) => {
    const list = excludedConsumers.map((consumer) => consumer);
    if (list.includes(id)) {
      list.splice(list.indexOf(id), 1);
    } else {
      list.push(id);
    }
    setExcludedConsumers(list);
  };

  const [tableData, setTableData] = useState<BaseTableDataItem[]>([]);

  const [objectName, setObjectName] = useState("");
  const [purposes, setPurposes] = useState<OptionsList[]>([]);
  const [objectArea, setObjectArea] = useState("");
  const [objectAreas, setObjectAreas] = useState<OptionsList[]>([]);
  const [diameter, setDiameter] = useState("");
  const [diameters, setDiameters] = useState<OptionsList[]>([]);

  const [floors, setFloors] = useState("");
  const [numberOfFloors, setNumberOfFloors] = useState<OptionsList[]>([]);
  const [numberOfRooms, setNumberOfRooms] = useState("");
  const [numberOfFlats, setNumberOfFlats] = useState<OptionsList[]>([]);

  const buttonSensor = ["buttonYear", "buttonHalfYear", "buttonQuarter", "buttonQuarterYear", "buttonMonth"];
  const [endDate, setEndDate] = useState(String(moment(Date.now()).format("YYYY-MM-DD")));
  const [startDate, setStartDate] = useState(String(moment(Date.now()).format("YYYY-MM-DD")));
  const [excludedConsumers, setExcludedConsumers] = useState<string[]>([]);

  const [isShowWarning, setIsShowWarning] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [lineGraph, setLineGraph] = useState<ApexOptions>({
    series: [
      {
        name: "Значения",
        type: "column",
        data: [44, 50, 41, 67, 22, 60, 20, 35, 75, 32].sort(),
      },
      {
        name: "Текущий эталон",
        type: "line",
        data: [44, 44, 44, 44, 44, 44, 44, 44, 44, 44],
      },
      {
        name: "Обновлённый эталон",
        type: "line",
        data: [55, 55, 55, 55, 55, 55, 55, 55, 55, 55],
      },
    ],
    // TODO
    // Сделать линии эталонов в виде аннотаций
    // annotations: {
    //   yaxis: [
    //     {
    //       y: 50,
    //       borderColor: "#00E396",
    //       label: {
    //         borderColor: "#00E396",
    //         style: {
    //           color: "#fff",
    //           background: "#00E396"
    //         },
    //         text: "Y Axis Annotation"
    //       }
    //     }
    //   ],
    // },
    chart: {
      id: "mainChart",
      width: "100%",
      animations: {
        enabled: false,
      },
      type: "line",
      zoom: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },
    dataLabels: {
      enabled: true,

      enabledOnSeries: [1],
    },
    stroke: {
      curve: "straight",
      width: [0, 2, 2],
      dashArray: [0, 0, 8],
    },

    labels: ["", "", "", "", "", "", "", "", "", ""],
    legend: {
      show: false,
    },
    xaxis: {
      type: "category",
    },
    fill: {
      colors: [
        function (value: any) {
          if (value < 44) {
            return `${brandBookColors.additionalTurquoise}`;
          } else {
            return `${brandBookColors.mainBlue}`;
          }
        },
      ],
    },
  });

  // Функция смены класса кнопки периода
  function buttonClassChange(id: string) {
    // Находим кнопку по id
    var btn = document.getElementById(id);
    // Была ли кнопка найдена
    if (btn !== null) {
      btn.classList.add("timePeriodButtonActive");
    }
    if (btn !== null || id === "undefined") {
      buttonSensor.forEach((button) => {
        if (button !== id) {
          var btn1 = document.getElementById(button);
          if (btn1 !== null) {
            btn1.classList.remove("timePeriodButtonActive");
          }
        }
      });
    }
  }

  // Функция выбора периода
  function setTimePeriod(type: string) {
    var endDate = new Date();
    var startDate = new Date();

    switch (type) {
      case "year":
        startDate = new Date(Date.now() - 1000 * 3600 * 24 * 365);
        break;

      case "halfYear":
        startDate = new Date(Date.now() - 1000 * 3600 * 24 * 183);
        break;

      case "quarter":
        startDate = new Date(Date.now() - 1000 * 3600 * 24 * 91);
        break;

      default:
        startDate = new Date(Date.now() - 1000 * 3600 * 24 * 365);
    }
    // setPeriod(per);

    setStartDate(moment(startDate).format("YYYY-MM-DD"));
    setEndDate(moment(endDate).format("YYYY-MM-DD"));
    getChartAndTableData(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"));
  }

  async function getChartAndTableData(startDate: string, endDate: string, withExclusions: boolean = false) {
    const params: { name: string; value: string }[] = [];

    if (objectName !== "") {
      params.push({ name: "Purpose", value: objectName });
    }
    if (objectArea !== "") {
      params.push({ name: "Area", value: objectName });
    }
    if (diameter !== "") {
      params.push({ name: "Pipe", value: objectName });
    }
    if (floors !== "") {
      params.push({ name: "Floors", value: floors });
    }
    if (numberOfRooms !== "") {
      params.push({ name: "Apartments", value: numberOfRooms });
    }

    try {
      if (params.length > 0) {
        setIsLoading(true);
        setIsShowWarning(false);
        if (excludedConsumers.length > 0 && withExclusions) {
          excludedConsumers.forEach((consumer) => {
            params.push({ name: "ExcludeObjects", value: consumer });
          });
        }

        const response = await DataAnalysisApi.getReferenceConsumerData(startDate, endDate, params);

        if (!withExclusions) {
          setExcludedConsumers([]);
        }

        const chartData = response.data.data.map((data) => data.volume);

        const myArr = Array(chartData.length).fill(response.data.adjustedReferenceUsage);
        const myArr2 = Array(chartData.length).fill(response.data.currentReferenceUsage);
        setLineGraph({
          ...lineGraph,
          series: [
            {
              name: "Значения",
              type: "column",
              data: chartData,
            },
            {
              name: "Текущий эталон",
              type: "line",
              data: myArr2,
            },
            {
              name: "Обновлённый эталон",
              type: "line",
              data: myArr,
            },
          ],
        });

        setTableData(
          response.data.data.map((data) => {
            return { ...data, id: data.guid, isInCalculation: false };
          })
        );
      } else {
        setIsShowWarning(true);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  async function getPurposes() {
    try {
      const response = await DataAnalysisApi.getModelCustomerOptions("purpose");
      setPurposes(response.data.data);
    } catch (e) {
      console.error(e);
    }
  }

  async function getAreas() {
    try {
      const response = await DataAnalysisApi.getModelCustomerOptions("area");
      setObjectAreas(response.data.data);
    } catch (e) {
      console.error(e);
    }
  }

  async function getDiameters() {
    try {
      const response = await DataAnalysisApi.getModelCustomerOptions("pipe");
      setDiameters(response.data.data);
    } catch (e) {
      console.error(e);
    }
  }

  async function getFloors() {
    try {
      const response = await DataAnalysisApi.getModelCustomerOptions("floors");
      setNumberOfFloors(response.data.data);
    } catch (e) {
      console.error(e);
    }
  }

  async function getRooms() {
    try {
      const response = await DataAnalysisApi.getModelCustomerOptions("apartments");
      setNumberOfFlats(response.data.data);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    buttonClassChange("buttonYear");
    setTimePeriod("year");
    getPurposes();
    getAreas();
    getDiameters();
    getFloors();
    getRooms();
  }, []);

  useEffect(() => {
    getChartAndTableData(startDate, endDate);
  }, [objectName, objectArea, diameter, floors, numberOfRooms]);

  return (
    <Box sx={styles.contentContainer}>
      <Box className="leftLegendaDate">
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Назначение объекта</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Назначение объекта"
            value={objectName}
            onChange={(e) => {
              setObjectName(e.target.value);
              // if (e.target.value === "all") {
              //   setTableData(data);
              // } else setTableData(data.filter((d) => d.name === e.target.value));
              // getChartAndTableData(sta)
            }}
          >
            {/* <MenuItem value={"all"} hidden>
              Все
            </MenuItem> */}
            <MenuItem value={""}>Очистить</MenuItem>;
            {purposes.sort().map((purp) => {
              return (
                <MenuItem key={purp.guid} value={purp.guid}>
                  {purp.values}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Площадь объекта</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Площадь объекта"
            value={objectArea}
            onChange={(e) => {
              setObjectArea(e.target.value);
            }}
          >
            <MenuItem value={""}>Очистить</MenuItem>;
            {objectAreas.map((area) => {
              return (
                <MenuItem value={area.guid} key={area.guid}>
                  {area.values}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Диаметр подключения</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Диаметр подключения"
            value={diameter}
            onChange={(e) => {
              setDiameter(e.target.value);
            }}
          >
            <MenuItem value={""}>Очистить</MenuItem>;
            {diameters.map((d) => {
              return (
                <MenuItem key={d.guid} value={d.guid}>
                  {d.values}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Этажность</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Этажность"
            value={floors}
            onChange={(e) => {
              setFloors(e.target.value);
            }}
          >
            <MenuItem value={""}>Очистить</MenuItem>;
            {numberOfFloors.map((f) => {
              return (
                <MenuItem value={f.guid} key={f.guid}>
                  {f.values}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Кол-во квартир/номеров</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Кол-во квартир/номеров"
            value={numberOfRooms}
            onChange={(e) => {
              setNumberOfRooms(e.target.value);
            }}
          >
            <MenuItem value={""}>Очистить</MenuItem>;
            {numberOfFlats.map((f) => {
              return (
                <MenuItem value={f.guid} key={f.guid}>
                  {f.values}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <CustomButton
          style={{ width: "150px", height: "40px" }}
          type="timePeriodButton"
          id="buttonYear"
          onClick={() => {
            buttonClassChange("buttonYear");
            setTimePeriod("year");
          }}
        >
          12 месяцев
        </CustomButton>
        <CustomButton
          style={{ width: "150px", height: "40px" }}
          type="timePeriodButton"
          id="buttonHalfYear"
          onClick={() => {
            buttonClassChange("buttonHalfYear");
            setTimePeriod("halfYear");
          }}
        >
          6 месяцев
        </CustomButton>
        <CustomButton
          style={{ width: "150px", height: "40px" }}
          type="timePeriodButton"
          id="buttonQuarterYear"
          onClick={() => {
            buttonClassChange("buttonQuarterYear");
            setTimePeriod("quarter");
          }}
        >
          3 месяца
        </CustomButton>
        {/* <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          Период С
        </div> */}
        <TextField
          id="date1"
          size="small"
          type="date"
          style={{ width: "100%" }}
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value as string);
            buttonClassChange("undefined");
            getChartAndTableData(e.target.value, endDate);
          }}
        />
        {/* <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          По
        </div> */}
        <TextField
          size="small"
          id="date2"
          style={{ width: "100%" }}
          type="date"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value as string);
            buttonClassChange("undefined");
            getChartAndTableData(startDate, e.target.value);
          }}
        />
      </Box>
      {isShowWarning ? (
        <p style={{ margin: "auto", marginTop: "150px" }} className="headerH2">
          Выберите параметр
        </p>
      ) : (
        <>
          <Box>
            <div style={{ height: "357px", width: "100%" }}>
              {isLoading ? (
                <Animation></Animation>
              ) : (
                <Chart height={"100%"} width={"100%"} options={lineGraph} series={lineGraph.series} type="line" />
              )}
            </div>
          </Box>
          <Box>
            <CustomButton
              style={{ height: "40px", float: "right", textTransform: "uppercase" }}
              onClick={() => getChartAndTableData(startDate, endDate, true)}
            >
              Пересчитать
            </CustomButton>
          </Box>
          <Box sx={styles.tableContainer}>
            <div style={{ height: "357px", width: "100%" }}>
              {isLoading ? (
                <Animation></Animation>
              ) : (
                <BaseTable columns={columns} data={tableData} isLoading={isLoading} useClientPagination />
              )}
            </div>
          </Box>
        </>
      )}
    </Box>
  );
};

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    paddingTop: "20px",
  },
  tableContainer: {
    height: "300px",
  },
};
