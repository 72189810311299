import React, { useState, useEffect } from "react";
import CustomButton from "../../../../components/uiComponents/Button/CustomButton";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { DataGrid, GridColDef, ruRU } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import BlockInfo from "../../blockInfo/BlockInfo";
import { Box } from "@mui/material";
import MlDataChartBG from "./mlDataChartBG";
import {
  GetSummaryBGData,
  GetSaleBG,
  GetAccStatBG,
  GetConsumptionBG,
  GetCounterContractsBG,
  GetBGConsumptionTable,
  BGConsumptionTable,
  BlocksInfoCard,
} from "../../BalansApis";
import Animation from "../../../../components/uiComponents/Animation/Animation";
import { ApexLocales } from "../../../../constants/ApexChartsSettings";
import { useMatch } from "react-router-dom";
import { routeUrls } from "../../../../routes/routeUrls";

interface distributionBGInterface {
  // zoneNumber: string;
  bgNumber: number;
}

const DistributionBG: React.FC<distributionBGInterface> = ({ bgNumber }) => {
  const columns: GridColDef[] = [
    {
      field: "month",
      headerName: "Дата",
      type: "string",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "pU",
      headerName: "Начисление по ПУ, тыс. м. куб",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
    {
      field: "payment",
      headerName: "Оплата, тыс. руб.",
      type: "number",
      flex: 1,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      // editable: true,
    },
  ];

  const [barOpt, setBarOpt] = useState<ApexOptions>({
    series: [],
    chart: {
      type: "bar",
      width: "100%",
      stacked: true,
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      colors: ["#1599D7"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 2,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      // Отображение чисел в блоках
      // enabled: false,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [2019, 2020, 2021, 2022, 2023],
    },
  });
  const [bar2Opt, setBar2Opt] = useState<ApexOptions>({
    series: [],
    chart: {
      type: "bar",
      width: "100%",
      stacked: true,
      animations: {
        enabled: false,
      },
      defaultLocale: ApexLocales.defaultLocale,
      locales: ApexLocales.locales,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      colors: ["#1599D7"],
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 2,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      // enabled: false,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ["Янв", "Фев", "Мар", "Aпр", "Май", "Июн"],
    },
  });
  const [userType, setUserType] = useState("total");
  const [valueType, setValueType] = useState("false");
  const [isLoadingBar, setIsLoadingBar] = useState(false);
  const [isLoadingBar2, setIsLoadingBar2] = useState(false);
  const [destCards, setDestCards] = useState<BlocksInfoCard[]>([]);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [isLoadingDon1, setIsLoadingDon1] = useState(false);
  const [isLoadingDon2, setIsLoadingDon2] = useState(false);
  const [isLoadingTable, setIsLoadingTable] = useState(false);
  const [zoneConsumptionTable, setZoneConsumptionTable] = useState<BGConsumptionTable[]>([]);
  const [donutOpt, setDonutOpt] = useState<ApexOptions>({
    tooltip: { enabled: true },
    colors: ["#1478BE", "#1599D7", "#4BB8A9", "#777777", "#E1F3FF"],
    series: [],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "left",
      floating: false, // Отключаем плавающее положение легенды
      offsetY: 0, // Устанавливаем вертикальное смещение легенды (может быть отрицательным для отступа вверх)
      offsetX: -10,
    },
    labels: ["Холодная вода", "Кан. холодная вода", "Кан. гор. вода", "Хол. вода. п.к.", "Транспорт"],
    chart: {
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              offsetY: 7,
              show: true,
              fontWeight: "600",
            },
            total: {
              show: true,
              formatter: function (w) {
                return w.globals.seriesTotals
                  .reduce((a: number, b: number) => {
                    return a + b;
                  }, 0)
                  .toFixed(2);
              },
            },
          },
        },
        // dataLabels: {
        //   offset: -50,
        // },
      },
    },
  });
  const [donut2Opt, setDonut2Opt] = useState<ApexOptions>({
    tooltip: {
      enabled: true,
    },
    colors: ["#1478BE", "#1599D7", "#777777", "#E1F3FF"],
    series: [22, 28, 10, 20],
    dataLabels: {
      enabled: false,
    },
    chart: {
      animations: {
        enabled: false,
      },
    },
    legend: {
      show: true,
      position: "bottom",
      offsetY: 0,
      offsetX: -10,
      floating: false,
      horizontalAlign: "left",
    },
    labels: ["Действующий", "Недействующий", "Переведен", "Временно недействующий"],
    plotOptions: {
      bar: {
        borderRadius: 4,
      },
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              offsetY: 7,
              show: true,
              fontWeight: "600",
            },
            total: {
              show: true,
            },
          },
        },
        dataLabels: {
          offset: -50,
        },
      },
    },
  });
  const [period, setPeriod] = useState("year");

  const routeMatch = useMatch({ path: routeUrls.bg });
  const zoneNumber = routeMatch?.params.zone;

  const buttonSensor = ["buttonYear", "buttonHalfYear", "buttonQuarter", "buttonQuarterYear", "buttonMonth"];

  // Функция смены класса кнопки периода
  function buttonClassChange(id: string) {
    // Находим кнопку по id
    var btn = document.getElementById(id);
    // Была ли кнопка найдена
    if (btn !== null) {
      btn.classList.add("timePeriodButtonActive");
    }
    if (btn !== null || id === "undefined") {
      buttonSensor.forEach((button) => {
        if (button !== id) {
          var btn1 = document.getElementById(button);
          if (btn1 !== null) {
            btn1.classList.remove("timePeriodButtonActive");
          }
        }
      });
    }
  }

  // Функция выбора периода
  function setTimePeriod(type: string) {
    let per = "";

    switch (type) {
      case "year":
        per = "year";
        break;
      case "quarter":
        per = "quarter";
        break;

      case "month":
        per = "month";
        break;

      default:
        per = "year";
    }
    setPeriod(per);
    getSalesSeries(per);
    getAccStatBG(userType, per, valueType);
  }

  //Запрос для таблицы балансовых групп (БГ.Зона.Таблица)
  async function getZoneConsumptionTable() {
    if (bgNumber < 1000 || zoneNumber === undefined) return 0;
    setIsLoadingTable(false);
    try {
      const response = await GetBGConsumptionTable(zoneNumber, bgNumber);
      setZoneConsumptionTable(response);
      setIsLoadingTable(true);
    } catch (e) {
      console.log(e);
    }
  }

  // Функция запроса данных для графика начислений
  async function getSalesSeries(period: string) {
    if (bgNumber < 1000 ?? zoneNumber === undefined) return 0;
    setIsLoadingBar2(true);
    try {
      const response = await GetSaleBG(period, zoneNumber, bgNumber);
      setBar2Opt({ ...bar2Opt, series: response.series, xaxis: { categories: response.categories } });
      setIsLoadingBar2(false);
    } catch (e) {
      console.warn(e);
    }
  }

  // Запрос для графика объёмов потребления (Сбыт.Объёмы_потребления)
  async function getAccStatBG(statisticsSubject: string, period: string, valueType: string) {
    if (bgNumber < 1000 ?? zoneNumber === undefined) return 0;
    setIsLoadingBar(true);
    try {
      const response = await GetAccStatBG(statisticsSubject, period, zoneNumber, bgNumber, valueType);
      setBarOpt({ ...barOpt, series: response.series, xaxis: { categories: response.categories } });

      setIsLoadingBar(false);
    } catch (e) {
      console.log(e);
    }
  }

  // Запрос данных для карточек с информацией (Сбыт)
  async function getSummaryBGData() {
    if (bgNumber < 1000 || zoneNumber === undefined) return 0;
    setIsLoadingCard(true);
    try {
      const response = await GetSummaryBGData(zoneNumber, bgNumber);
      setDestCards(response);
      // console.log(response);
      setIsLoadingCard(false);
    } catch (e) {
      console.log(e);
    }
  }

  // Запрос для второй пончиковой диаграммы (Сбыт.Поданная_вода)
  async function getConsumptionBG() {
    if (bgNumber < 1000 || zoneNumber === undefined) return 0;
    setIsLoadingDon1(true);
    try {
      const response = await GetConsumptionBG(zoneNumber, bgNumber);
      setDonutOpt({ ...donutOpt, series: response.series, colors: response.colors, labels: response.labels });
      setIsLoadingDon1(false);
      // console.log(response);
    } catch (e) {
      console.log(e);
    }
  }

  // Запрос для первой пончиковой диаграммы (БГ.cбыт.Котнракты)
  async function getCounterContractsBG() {
    if (bgNumber < 1000 || zoneNumber === undefined) return 0;
    setIsLoadingDon2(true);
    try {
      const response = await GetCounterContractsBG(zoneNumber, bgNumber);
      setDonut2Opt({ ...donut2Opt, series: response.data, colors: response.colors, labels: response.labels });
      // console.log(response);
      setIsLoadingDon2(false);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    buttonClassChange("buttonYear");
  }, []);

  useEffect(() => {
    getSalesSeries(period);
    getAccStatBG("total", period, valueType);
    getSummaryBGData();
    getConsumptionBG();
    // TODO:
    // При рабочем API раскомментить
    // getCounterContractsBG();
    getZoneConsumptionTable();
  }, [zoneNumber, bgNumber]);

  return (
    <div className="balanceTabDefault">
      {/* Верхняя легенда */}
      <div className="leftLegendaDate">
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          Объём потребления
        </div>

        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Тип</InputLabel>
          <Select
            // sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={userType}
            label="Тип отчета"
            onChange={(e) => {
              setUserType(e.target.value);
            }}
          >
            <MenuItem
              value={"total"}
              onClick={() => {
                getAccStatBG("total", period, valueType);
                setIsLoadingBar(true);
              }}
            >
              Все
            </MenuItem>
            <MenuItem
              value={"account"}
              onClick={() => {
                getAccStatBG("account", period, valueType);
                setIsLoadingBar(true);
              }}
            >
              ФЛ
            </MenuItem>
            <MenuItem
              value={"client"}
              onClick={() => {
                getAccStatBG("client", period, valueType);
                setIsLoadingBar(true);
              }}
            >
              ЮЛ
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Эквивалент</InputLabel>
          <Select
            sx={{ height: "40px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={valueType}
            label="Тип отчета"
            onChange={(e) => {
              setValueType(e.target.value);
            }}
          >
            <MenuItem
              value={"false"}
              onClick={() => {
                getAccStatBG(userType, period, "false");
                setIsLoadingBar(true);
              }}
            >
              м.куб
            </MenuItem>
            <MenuItem
              value={"true"}
              onClick={() => {
                getAccStatBG(userType, period, "true");
                setIsLoadingBar(true);
              }}
            >
              Рубли
            </MenuItem>
          </Select>
        </FormControl>
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          по
        </div>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonYear"
          onClick={() => {
            buttonClassChange("buttonYear");
            setTimePeriod("year");
            setIsLoadingBar(true);
            setIsLoadingBar2(true);
          }}
        >
          Годам
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonQuarterYear"
          onClick={() => {
            buttonClassChange("buttonQuarterYear");
            setTimePeriod("quarter");
            setIsLoadingBar(true);
            setIsLoadingBar2(true);
          }}
        >
          Кварталам
        </CustomButton>
        <CustomButton
          style={{ width: "100%", height: "40px" }}
          type="timePeriodButton"
          id="buttonMonth"
          onClick={() => {
            buttonClassChange("buttonMonth");
            setTimePeriod("month");
            setIsLoadingBar(true);
            setIsLoadingBar2(true);
          }}
        >
          Месяцам
        </CustomButton>
      </div>
      <div style={{ display: "flex", gap: "16px" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
          {/* Первый график с легендой */}

          <div style={{ width: "100%", height: "270px" }}>
            {isLoadingBar ? (
              <Animation></Animation>
            ) : (
              <Chart height={270} width={"100%"} options={barOpt} series={barOpt.series} type="bar" />
            )}
          </div>

          {/* График прогнозирования */}
          {/* <MlDataChartBG
            period={period}
            zoneNumber={zoneNumber}
            statisticSubject={userType}
            isRubles={valueType}
            balanceGroupNumber={bgNumber}
          ></MlDataChartBG> */}
        </div>
        <div className="BalanceMainForm">
          {/* Блок Графиков */}
          <div
            style={{
              width: "100%",
              height: "100%",
              minWidth: "240px",
              maxWidth: "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              className="additionalText"
              style={{
                minWidth: `calc(240px - 12px)`,
                maxWidth: `calc(300px - 20px)`,
                textWrap: "pretty",
                textAlign: "center",
              }}
            >
              * Данные с 1-го января текущего года
            </p>
            {isLoadingDon1 ? (
              <Animation></Animation>
            ) : (
              <Chart options={donutOpt} series={donutOpt.series} type="donut" width={240} height={300} />
            )}
            {
              //TO DO:
              // При рабочем API раскоментить
              //       isLoadingDon2 ? (
              //   <Animation></Animation>
              // ) : (
              //   <Chart options={donut2Opt} series={donut2Opt.series} type="donut" width={240} height={300} />
              // )
            }
          </div>
          {/* Блок описания */}
          <div className="description">
            {isLoadingCard ? (
              <Animation></Animation>
            ) : (
              destCards.map((item) => <BlockInfo header={item.header} blocks={item.blocks} key={item.name} />)
            )}
          </div>
        </div>
      </div>

      <div style={{ height: "380px", width: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ whiteSpace: "nowrap" }} className="headerH2">
          Начисления
        </div>
        {isLoadingBar2 ? (
          <Animation />
        ) : (
          <Chart height={"357px"} width={"100%"} options={bar2Opt} series={bar2Opt.series} type="bar" />
        )}
      </div>
      <Box sx={{ height: "calc(100vh - 500px)", width: "100%" }} className="tableHeight">
        {!isLoadingTable ? (
          <Animation></Animation>
        ) : (
          <DataGrid
            rows={zoneConsumptionTable}
            columns={columns}
            //pageSize={20}
            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          />
        )}
      </Box>
    </div>
  );
};

export default DistributionBG;
