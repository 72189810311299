import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

type Props = {
  title: string;
  message: string;
  okClick: () => void;
  cancelClick: () => void;
  okText?: string;
  cancelText?: string;
};

export const ConfirmationModal = ({ title, message, okClick, cancelClick, okText = 'Да', cancelText = 'Отмена' }: Props) => {
  const handleOk = () => {
    okClick && okClick();
  };

  const handleCancel = () => {
    cancelClick && cancelClick();
  };

  return (
    <Dialog open={true} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>{okText}</Button>
        <Button onClick={handleCancel}>{cancelText}</Button>
      </DialogActions>
    </Dialog>
  );
};
