import mime from 'mime';

export const fileService = {
  downloadFile: (data: Blob, fileName: string) => {
    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    const fileExtention = mime.getExtension(data.type);
    const fileNameWithExtention = `${fileName}.${fileExtention}`;
    link.setAttribute("download", fileNameWithExtention);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
};
