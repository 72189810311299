import { Box, TablePagination } from "@mui/material";
import moment from "moment";
import { EventActions } from "../../../models/events/eventActions";
import { EventsCountItem } from "../../../models/events/eventsCountItem";
import { EventCard } from "../eventCard/EventCard";
import { EventItemDto } from "../../../models/events/eventItemDto";
import { LoadingState } from "../../../types/loadingState";
import Loader from "../../../components/uiComponents/Animation/Animation";

interface EventsProps {
  events: EventItemDto[];
  selectedEvent?: EventItemDto;
  criticalCount?: number;
  excessCount?: number;
  serviceCount?: number;
  lastUpdate?: string;
  isLoading: LoadingState;

  eventCardTitleRender?: (eventData: EventItemDto) => React.ReactNode;
  eventActions?: EventActions;

  isHeaderHidden?: boolean;
  isToolBoxCentered?: boolean;

  paginationSettings?: PaginationSettingsProps;
}

interface PaginationSettingsProps {
  currentPage: number;
  pageSize: number;
  totalCount: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

export const EventList = ({
  events,
  selectedEvent,
  criticalCount,
  excessCount,
  serviceCount,
  lastUpdate,
  isLoading,

  eventCardTitleRender,
  eventActions,

  isHeaderHidden = false,
  isToolBoxCentered = false,

  paginationSettings,
}: EventsProps) => {
  const eventsCountList: EventsCountItem[] = [
    {
      title: criticalCount || 0,
      bgColor: "#CA807F",
    },
    {
      title: excessCount || 0,
      bgColor: "#EAAF6B",
    },
    {
      title: serviceCount || 0,
      bgColor: "#1478BE",
    },
  ];

  const isEventSelected = (event: EventItemDto) => {
    return JSON.stringify(event.key) === JSON.stringify(selectedEvent?.key);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    paginationSettings && paginationSettings.onPageChange(newPage + 1);
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    paginationSettings && paginationSettings.onPageSizeChange(parseInt(event.target.value, 10));
  };

  return (
    <Box sx={styles.box}>
      {!isHeaderHidden && (
        <Box sx={styles.header}>
          <Box sx={styles.title}>События</Box>
          {eventsCountList
            .filter((i) => i.title)
            .map((item, indx) => (
              <Box sx={[styles.circle, { backgroundColor: item.bgColor }]} key={indx}>
                {item.title}
              </Box>
            ))}
        </Box>
      )}
      <Box sx={[styles.toolBox, isToolBoxCentered && styles.toolBoxCenter]}>
        <Box>Обновлено: {moment(lastUpdate).format("HH:mm DD.MM.YYYY")}</Box>
        {/* TODO: Будет добавлено в следующих версиях */}
        {/* <EventsTypeSelect /> */}
      </Box>
      <Box sx={styles.typeBox}>
        {isLoading === LoadingState.PENDING ? (
          <Loader />
        ) : (
          <>
            {events.map((item, index) => (
              <EventCard
                key={index}
                cardData={item}
                renderTitle={eventCardTitleRender}
                actions={eventActions}
                isCurrent={isEventSelected(item)}
              />
            ))}
          </>
        )}
      </Box>
      {paginationSettings && (
        <Box>
          <TablePagination
            disabled={isLoading === LoadingState.PENDING}
            component="div"
            count={paginationSettings.totalCount}
            page={paginationSettings.currentPage - 1}
            onPageChange={handlePageChange}
            rowsPerPage={paginationSettings.pageSize}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </Box>
      )}
    </Box>
  );
};

const styles = {
  box: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "100%",
  },
  header: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  title: { fontSize: "24px", fontStyle: "normal", fontWeight: "400", lineHeight: "140%" },
  circle: {
    width: "34px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "140%",
    color: "#fff",
  },
  toolBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toolBoxCenter: {
    justifyContent: "center",
  },
  typeBox: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    overflowY: "auto",
  },
};
