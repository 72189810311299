import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { settingsTableApi } from "../../../pages/settings/api/SettingsTableApi";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";
import { v4 as uuidv4 } from "uuid";
import { BaseTableDataItem } from "../../../models/baseTable/baseTableDataItem";
import { SettingsTableData, SettingsTableDataItem } from "../../../models/settingsTable/settingsTableData";
import { fileService } from "../../../utilities/file/fileService";
import moment from "moment";

const sliceName = "settingsTable";

interface InitialState {
  tableName: string;

  tableData: SettingsTableDataItem[];
  totalItems: number;
  selectedItemId?: string | null;

  currentPage: number;
  pageSize: number;

  isLoading: LoadingState;
}

const initialState: InitialState = {
  tableName: "",

  tableData: [],
  totalItems: 0,
  selectedItemId: null,

  currentPage: 0,
  pageSize: 25,

  isLoading: LoadingState.EMPTY,
};

export const getSettingsTableData = createAsyncThunk<any, void, { state: RootState }>(
  `${sliceName}/getSettingsTableData`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const { tableName, currentPage, pageSize } = state;
      const from = currentPage * pageSize;
      const size = pageSize;
      if (tableName) {
        const response = await settingsTableApi.getTableData(tableName, from, size);
        return response.data;
      } else {
        thunkAPI.abort();
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const downloadSettingsTableDataCsv = createAsyncThunk<Blob, void, { state: RootState }>(
  `${sliceName}/downloadSettingsTableDataCsv`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const { tableName } = state;
      const response = await settingsTableApi.downloadTableDataCsv(tableName);
      fileService.downloadFile(response.data, `${tableName}_${moment().format("YYYY-MM-DD_HH-mm-ss")}`);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);
export const createSettingsTableData = createAsyncThunk<void, BaseTableDataItem, { state: RootState }>(
  `${sliceName}/createSettingsTableData`,
  async (params, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const { tableName } = state;
      const response = await settingsTableApi.createTableData(tableName, params);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const uploadSettingsTableDataCsv = createAsyncThunk<void, { file: File }, { state: RootState }>(
  `${sliceName}/uploadSettingsTableDataCsv`,
  async (parameters, thunkAPI) => {
    try {
      const { file } = parameters;
      const state = thunkAPI.getState()[sliceName];
      const { tableName } = state;
      const data = new FormData();
      data.append("file", file);
      const response = await settingsTableApi.uploadTableDataCsv(tableName, data);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const updateSettingsTableData = createAsyncThunk<void, BaseTableDataItem, { state: RootState }>(
  `${sliceName}/updateSettingsTableData`,
  async (params, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const { tableName } = state;
      const response = await settingsTableApi.updateTableData(tableName, params);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const deleteSettingsTableData = createAsyncThunk<void, BaseTableDataItem, { state: RootState }>(
  `${sliceName}/deleteSettingsTableData`,
  async (params, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const { tableName } = state;
      const response = await settingsTableApi.deleteTableData(tableName, params);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const settingsTableSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setInitialState: () => {
      return initialState;
    },
    setSettingsTableName: (state, action: PayloadAction<string>) => {
      state.tableName = action.payload;
    },
    setSettingsTableData: (state, action: PayloadAction<SettingsTableDataItem[]>) => {
      state.tableData = action.payload;
    },
    setSettingsCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSettingsPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setSelectedItemId: (state, action: PayloadAction<string | null>) => {
      state.selectedItemId = action.payload;
    },
    addNewItem: (state, action) => {
      state.tableData = [{ id: uuidv4(), isNew: true, ...action.payload }, ...state.tableData];
    },
    removeNewItem: (state, action: PayloadAction<string>) => {
      const item = state.tableData.find((x) => x.id === action.payload);
      if (item) {
        const index = state.tableData.indexOf(item);
        if (index >= 0) {
          state.tableData.splice(index, 1);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSettingsTableData.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getSettingsTableData.fulfilled, (state, action: PayloadAction<SettingsTableData>) => {
      const { data, totalItems } = action.payload;
      state.tableData = data.map((i) => ({
        ...i,
        id: uuidv4(),
      }));
      state.totalItems = totalItems;
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getSettingsTableData.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });
  },
});

export const settingsTableReducer = settingsTableSlice.reducer;
export const {
  setInitialState,
  setSettingsTableName,
  setSettingsTableData,
  setSelectedItemId,
  setSettingsCurrentPage,
  setSettingsPageSize,
  addNewItem,
  removeNewItem,
} = settingsTableSlice.actions;
