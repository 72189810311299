import { Box } from "@mui/material";
import { AnomaliesEvents } from "../../components/anomaliesEvents/anomaliesEvents";
import { MonitoringMap } from "../../../../widgets/monitoringMap/monitoringMap";
import { AnomaliesTable } from "../../components/anomaliesTable/anomaliesTable";

export const Anomalies = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.row}>
        <Box sx={[styles.mapCell]}>
          <MonitoringMap />
        </Box>
        <Box sx={[styles.eventsCell]}>
          <AnomaliesEvents />
        </Box>
      </Box>
      <Box>
        <AnomaliesTable />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 32px)",
    paddingTop: "20px",
    gap: "16px",
  },
  row: {
    height: "50%",
    display: "flex",
    gap: "16px",
  },
  mapCell: {
    flex: "0 1 75%",
  },
  eventsCell: {
    flex: "0 1 25%",
  },
};
