import { AxiosResponse } from "axios";
import AuthAxios, { backURLs } from "../../../core/APIs";
import { AnomalyDetailDto } from "../../../models/dataAnalysis/anomalies/anomalyDetailDto";
import { AnomalyDetailUpdateDto } from "../../../models/dataAnalysis/anomalies/anomalyDetailUpdateDto";
import { dataInterface } from "../../balance/BalansApis";

const baseUrl = backURLs.predictionServiceBaseUrl;

export interface PredictionDataInterface {
  date: string;
  date_time: string;
  indication: string;
  volume: number;
}

export interface TotalDataInterface {
  date: string;
  value: number;
}

export interface SupplySaleWaterDatesInterface {
  data: dataInterface[];
  categories: string[];
  dates: string[];
}

export interface OptionsList {
  guid: string;
  values: string;
}

export interface ModelCustomerOptions {
  name: string;
  data: OptionsList[];
}

export interface ModelCustomerData {
  name: string;
  inn: string;
  guid: string;
  typefirm: number;
  addr: string;
  volume: number;
  id: string;
}

export interface ReferenceConsumerResponseInterface {
  currentReferenceUsage: number;
  adjustedReferenceUsage: number;
  data: ModelCustomerData[];
}

export const DataAnalysisApi = {
  getList: (urlPart: string): Promise<AxiosResponse> => AuthAxios.get(`${baseUrl}/${urlPart}`),

  downloadFile: (urlPart: string): Promise<AxiosResponse> =>
    AuthAxios.get(`${baseUrl}/${urlPart}`, { responseType: "blob" }),

  getAnomaliesEventsData: (pageNum: number, pageSize: number): Promise<AxiosResponse> =>
    AuthAxios.get(`${backURLs.eventList}/anomalies`, { params: { pageNum, pageSize } }),

  respondEvent: (guid: string): Promise<AxiosResponse> => AuthAxios.post(`${backURLs.eventList}/respond/${guid}`),

  getAnomalies: (): Promise<AxiosResponse<AnomalyDetailDto[]>> => AuthAxios.get(`${backURLs.anomalies}`),

  updateAnomalyNote: (data: AnomalyDetailUpdateDto): Promise<AxiosResponse> =>
    AuthAxios.put(`${backURLs.anomalies}/change-anomaly-note`, data),

  getMeasuresChart: <Type>(
    measure1Id: string,
    startDate: string,
    endDate: string,
    additionalParams: { name: string; value: string }[] = []
  ): Promise<AxiosResponse<Type>> => {
    const addParams = additionalParams.reduce((a, v) => ({ ...a, [v.name]: v.value }), {});

    return AuthAxios.get(`${process.env.REACT_APP_API_URL}/${measure1Id}`, {
      params: { startDate: startDate, endDate: endDate, ...addParams },
    });
  },

  getModelCustomerOptions: (optionName: string): Promise<AxiosResponse<ModelCustomerOptions>> =>
    AuthAxios.get(`${backURLs.referenceConsumer}/reference-lists?rcList=${optionName}`),

  getReferenceConsumerData: (
    startDate: string,
    endDate: string,
    additionalParams: { name: string; value: string }[] = []
  ): Promise<AxiosResponse<ReferenceConsumerResponseInterface>> => {
    const addParams = additionalParams.map((aP) => `${aP.name}=${aP.value}`);

    return AuthAxios.get(
      `${backURLs.referenceConsumer}/summary?startDate=${startDate}&endDate=${endDate}&${addParams.join("&")}`
    );
  },
};
