import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { EventList } from "../../../../widgets/events/eventList/eventList";
import Loader from "../../../../components/uiComponents/Animation/Animation";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { EventActions } from "../../../../models/events/eventActions";
import {
  acceptEvent,
  getEventsStatistics,
  getMonitoringEventsData,
  sendEvent,
  setInitialState,
  setPaginationSettings,
} from "../../../../store/slices/monitoring/MonitoringEventsSlice";
import { LoadingState } from "../../../../types/loadingState";
import { Link } from "react-router-dom";
import { setSelectedEventItem } from "../../../../store/slices/monitoring/MonitoringEventInfoSlice";
import { EventItemDto } from "../../../../models/events/eventItemDto";
import { EventKeyDto } from "../../../../models/events/eventKeyDto";
import { isNullOrUndefined } from "../../../../utilities/isNullOrUndefined";
import { momentCalendarLocales } from "../../../../utilities/moment/momentCalendarLocales";
import moment from "moment";
import { useSnackbar } from "notistack";
import { DefaultPaginationSettings } from "../../../../models/common/paginationSettings";
import { SignalType } from "../../../../models/events/signalType";
import { getMapObjects, getZones } from "../../../../store/slices/monitoringMapSlice";

export const MonitoringEvents = () => {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();

  const eventsData = useTypedSelector((state) => state.monitoringEvents.eventsData);
  const paginationSettings = useTypedSelector((state) => state.monitoringEvents.paginationSettings);

  const isLoading = useTypedSelector((state) => state.monitoringEvents.isLoading);

  const loadEventList = () => {
    dispatch(getMonitoringEventsData());
  };

  const updateMapData = () => {
    dispatch(getMapObjects());
    dispatch(getZones());
  }

  const updateStatistics = () => {
    dispatch(getEventsStatistics());
  }

  const loadDataAfterEvnetHandled = () => {
    updateMapData();
    loadEventList();
    updateStatistics();
  }

  const handleSendToControlRoom = async (key: EventKeyDto) => {
    const result = await dispatch(sendEvent(key));
    if (result.error) {
      enqueueSnackbar(`Не удалось выполнить действие. Пожалуйста, повторите попытку позже.`, { variant: "error" });
    } else {
      loadDataAfterEvnetHandled();
    }
  };

  const handleTakeNote = async (key: EventKeyDto) => {
    const result = await dispatch(acceptEvent(key));
    if (result.error) {
      enqueueSnackbar(`Не удалось выполнить действие. Пожалуйста, повторите попытку позже.`, { variant: "error" });
    } else {
      loadDataAfterEvnetHandled();
    }
  };

  const getEventActionStatusBox = (statusText: string, handledTime: Date) => {
    return (
      <>
        <Box sx={styles.statusContainer}>
          <Box sx={styles.statusText}>{statusText}</Box>
          <Box sx={styles.statusText}>{moment(handledTime).calendar(momentCalendarLocales)}</Box>
        </Box>
      </>
    );
  };

  const eventActions: EventActions = {
    [SignalType.Null]: (key: EventKeyDto, event: EventItemDto) => {
      return (
        <>
          <Button onClick={() => handleSendToControlRoom(key)} size="small" sx={styles.actionButton}>
            Передать в эл. диспетчерскую
          </Button>
          <Button onClick={() => handleTakeNote(key)} size="small" sx={styles.actionButton}>
            Принять к сведению
          </Button>
        </>
      );
    },
    [SignalType.Send]: (key: EventKeyDto, event: EventItemDto) =>
      getEventActionStatusBox("Передано в эл. диспетчерскую: ", event.handledTime),
    [SignalType.Accepted]: (key: EventKeyDto, event: EventItemDto) =>
      getEventActionStatusBox("Принято к сведению: ", event.handledTime),
  };

  const customEventCardTitle = (data: EventItemDto) => (
    <Link to={`${data.signalEventGuid}`} onClick={() => dispatch(setSelectedEventItem(data))}>
      {data.objectName}
    </Link>
  );

  const handlePageChange = (page: number) => {
    dispatch(setPaginationSettings({ ...paginationSettings, currentPage: page }));
  };

  const handlePageSizeChange = (pageSize: number) => {
    dispatch(setPaginationSettings({ ...paginationSettings, ...DefaultPaginationSettings, pageSize: pageSize }));
  };

  useEffect(() => {
    return () => {
      dispatch(setInitialState());
    };
  }, []);

  useEffect(() => {
    loadEventList();
  }, [paginationSettings]);

  if (!eventsData && isLoading === LoadingState.PENDING) {
    return <Loader />;
  }

  return (
    <>
      {eventsData && (
        <EventList
          events={eventsData.page?.data || []}
          criticalCount={eventsData.criticalCount}
          excessCount={eventsData.excessCount}
          serviceCount={eventsData.serviceCount}
          lastUpdate={new Date().toString()}
          eventCardTitleRender={customEventCardTitle}
          eventActions={eventActions}
          isLoading={isLoading}
          paginationSettings={{
            currentPage: paginationSettings.currentPage,
            pageSize: paginationSettings.pageSize,
            totalCount: eventsData.page?.totalCount || 0,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
          }}
        />
      )}
    </>
  );
};

const styles = {
  actionButton: {
    padding: "0 ",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "100%",
  },
  statusContainer: {
    display: "flex",
    gap: "12px",
  },
  statusText: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "140%",
  },
};
