import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../reducers";
import { UserInfoDto } from "../../../models/account/userInfoDto";
import { accountApi } from "../../../pages/account/accountApi";

const sliceName = "account";

interface IInitialState {
  userInfo?: UserInfoDto;
}

const initialState: IInitialState = {};

export const getUserInfo = createAsyncThunk<UserInfoDto, void, { state: RootState }>(
  `${sliceName}/getUserInfo`,
  async (_, thunkAPI) => {
    try {
      const response = await accountApi.getCurrentUserInfo();
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.response?.data || "Ошибка соединения. Повторите попытку");
    }
  }
);

const accountSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfo = action.payload;
    });
  },
});

export const accountReducer = accountSlice.reducer;
