export const SettingsTabsConfig = [
  // {
  //   label: "Ввод данных по оплаченной воде",
  //   route: "waterPaidData",
  // },
  // {
  //   label: "Ввод данных по показаниям приборов учета",
  //   route: "deviceData",
  // },
  // {
  //   label: "Ввод данных по потребленной электроэнергии",
  //   route: "consumedElectricity",
  // },
  {
    label: "Паспортные данные",
    route: "passport",
  },
  {
    label: "Ввод нормативов по удельному расходу электроэнергии",
    route: "ElectricityConsumedStandards",
  },
];
