import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { EventList } from "../../../../widgets/events/eventList/eventList";
import Loader from "../../../../components/uiComponents/Animation/Animation";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { LoadingState } from "../../../../types/loadingState";
import {
  getEventsDataBySignalEventGuid,
  setPaginationSettings,
} from "../../../../store/slices/monitoring/MonitoringEventInfoSlice";
import { DefaultPaginationSettings } from "../../../../models/common/paginationSettings";

export const MonitoringEventInfoEvents = () => {
  const { guid } = useParams();
  const dispatch = useDispatch<any>();

  const eventsData = useTypedSelector((state) => state.monitoringEventInfo.eventsData);
  const selectedEventItem = useTypedSelector((state) => state.monitoringEventInfo.selectedEventItem);
  const paginationSettings = useTypedSelector((state) => state.monitoringEventInfo.paginationSettings);
  const isLoading = useTypedSelector((state) => state.monitoringEventInfo.isLoadingEventsData);

  const loadData = () => {
    if (guid) {
      dispatch(getEventsDataBySignalEventGuid({ signalEventGuid: guid }));
    }
  };

  const handlePageChange = (page: number) => {
    dispatch(setPaginationSettings({ ...paginationSettings, currentPage: page }));
  };

  const handlePageSizeChange = (pageSize: number) => {
    dispatch(setPaginationSettings({ ...paginationSettings, ...DefaultPaginationSettings, pageSize: pageSize }));
  };

  useEffect(() => {
    loadData();
  }, [paginationSettings]);

  if (!guid) {
    return <Box>Событие не выбрано</Box>;
  }

  if (isLoading === LoadingState.PENDING) {
    return <Loader />;
  }

  return (
    <>
      {eventsData && (
        <EventList
          events={eventsData.data}
          selectedEvent={selectedEventItem}
          isLoading={isLoading}
          paginationSettings={{
            currentPage: paginationSettings.currentPage,
            pageSize: paginationSettings.pageSize,
            totalCount: eventsData.totalCount || 0,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
          }}
        />
      )}
    </>
  );
};
