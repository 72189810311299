import { Box } from "@mui/material";
import { HeaderText } from "../../../components/headerText/headerText";

export const NotFound = () => {
  return (
    <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <HeaderText>404</HeaderText>
        <HeaderText>Страница не найдена</HeaderText>
      </Box>
    </Box>
  );
};
