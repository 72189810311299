import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DefaultPaginationSettings, PaginationSettings } from "../../../models/common/paginationSettings";
import { LoadingState } from "../../../types/loadingState";
import { RootState } from "../../reducers";
import { usersApi } from "../../../pages/userManagement/api/usersApi";
import { User, UsersTableData } from "../../../pages/userManagement/types/usersTypes";

const sliceName = "users";

interface IInitialState {
  tableData: User[];

  paginationSettings: PaginationSettings;
  totalItems: number;

  selectedUserGuid: string;

  isLoading: LoadingState;
}

const initialState: IInitialState = {
  tableData: [],

  paginationSettings: DefaultPaginationSettings,
  totalItems: 0,

  selectedUserGuid: "",

  isLoading: LoadingState.EMPTY,
};

export const getUsers = createAsyncThunk<UsersTableData, void, { state: RootState }>(
  `${sliceName}/getUsers`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const response = await usersApi.getUsers(state.paginationSettings.currentPage, state.paginationSettings.pageSize);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

export const deleteUser = createAsyncThunk<void, void, { state: RootState }>(
  `${sliceName}/deleteUser`,
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState()[sliceName];
      const response = await usersApi.deleteUser(state.selectedUserGuid);
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку");
    }
  }
);

const usersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setSelectedUserGuid: (state, action) => {
      state.selectedUserGuid = action.payload;
    },
    setPaginationSettings: (state, action) => {
      state.paginationSettings = action.payload;
    },
    setInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.tableData = action.payload.data;
      state.totalItems = action.payload.totalCount;
      state.isLoading = LoadingState.SUCCESS;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.isLoading = LoadingState.ERROR;
    });

    builder.addCase(deleteUser.pending, (state) => {
      state.isLoading = LoadingState.PENDING;
    });
  },
});

export const usersReducer = usersSlice.reducer;
export const { setSelectedUserGuid, setPaginationSettings, setInitialState } = usersSlice.actions;
