import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
  GridEventListener,
  GridPaginationModel,
  GridRowEditStopParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowSelectionModel,
  MuiEvent,
  ruRU,
  useGridApiRef,
} from "@mui/x-data-grid";
import { BaseTableColumnDef } from "../../models/baseTable/baseTableColumns";
import { getStandardColumnParams } from "../../utilities/baseTableColumnParams";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { BaseTableDataItem } from "../../models/baseTable/baseTableDataItem";
import { PaginationSettings } from "../../models/common/paginationSettings";

export interface BaseTableProps {
  columns: BaseTableColumnDef[];
  data: BaseTableDataItem[];
  idGetter?: (item: any) => string;

  editable?: boolean;
  selectedId?: string | null;
  onSelectedIdChange?: (id: string | null) => void;
  pageSize?: number;
  pageSizeOptions?: number[];
  totalItems?: number;
  currentPage?: number;
  onChangePage?: (page: number) => void;
  onChangePageSize?: (pageSize: number) => void;
  onChangePagination?: (settings: PaginationSettings) => void;
  useClientPagination?: boolean;

  getRowClassName?: (param: GridRowParams<BaseTableDataItem>) => string;

  validationSchema?: yup.ObjectSchema<any>;

  actionsColumnName?: string;
  getTableActions?: (rowId: string) => React.ReactElement<GridActionsCellItemProps>[];

  onSave?: (row: any) => void;
  onCancel?: (id: string) => void;

  isLoading?: boolean;
}

export const BaseTable = ({
  columns,
  data,
  idGetter,

  editable,
  selectedId,
  onSelectedIdChange,
  pageSize,
  pageSizeOptions,
  totalItems,
  currentPage,
  onChangePage,
  onChangePageSize,
  onChangePagination,
  useClientPagination = false,

  getRowClassName,

  validationSchema,

  actionsColumnName = "",
  getTableActions,

  onSave,
  onCancel,

  isLoading,
}: BaseTableProps) => {
  const apiRef = useGridApiRef();

  const { enqueueSnackbar } = useSnackbar();

  const [rowModesModel, setRowModesModel] = useState<GridRowModel>({});
  const [rowSelectionModel, setSelectedRowModel] = useState<GridRowSelectionModel>([]);

  const handleRowEditStop = (params: GridRowEditStopParams<any>, event: MuiEvent) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => async () => {
    if (validationSchema) {
      const values = apiRef.current.getRowWithUpdatedValues(id, columns[0].field);
      try {
        await validationSchema.validate(values);
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      } catch (error: any) {
        enqueueSnackbar(`${error.params.label}: ${error.message}`, { variant: "error" });
      }
    } else {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    onCancel && onCancel(id.toString());
  };

  const processRowUpdate = (newRow: any, oldRow: any) => {
    onSave && onSave(newRow);
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
    setSelectedRowModel(newRowSelectionModel);
    onSelectedIdChange &&
      onSelectedIdChange(newRowSelectionModel && newRowSelectionModel[0] ? newRowSelectionModel[0].toString() : null);
  };

  const handleDoubleCellClick: GridEventListener<"cellDoubleClick"> = React.useCallback((_, event) => {
    event.defaultMuiPrevented = true;
  }, []);

  const handlePaginationModalChange = ({ page, pageSize }: GridPaginationModel) => {
    onChangePage && onChangePage(page);
    onChangePageSize && onChangePageSize(pageSize);
    onChangePagination && onChangePagination({currentPage: page, pageSize});
  };

  const paginationModel =
    currentPage !== undefined && pageSize !== undefined
      ? {
          page: currentPage,
          pageSize: pageSize,
        }
      : undefined;

  const getMappedColumns = () => {
    const res = columns.map(
      (column) =>
        ({
          ...getStandardColumnParams(column),
          ...column,
        } as GridColDef)
    );
    if (editable || getTableActions) {
      res.push({
        field: "actions",
        type: "actions",
        headerName: actionsColumnName,
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem icon={<CheckIcon />} label="Save" onClick={handleSaveClick(id)} />,
              <GridActionsCellItem icon={<CancelIcon />} label="Cancel" onClick={handleCancelClick(id)} />,
            ];
          }

          let actionComponents = []
          if (editable) {
            actionComponents.push(<GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={handleEditClick(id)} />)
          }
          if (getTableActions) {
            actionComponents.push(...getTableActions(id.toString()))
          }

          return actionComponents;
        },
      });
    }
    return res;
  };

  useEffect(() => {
    const newRow = data.find((x) => x.isNew === true);
    if (newRow) {
      const id = newRow.id.toString();
      setRowModesModel((oldModel) => ({ ...oldModel, [id]: { mode: GridRowModes.Edit } }));
      apiRef.current.scrollToIndexes({ rowIndex: 0 });
    }
  }, [data]);

  useEffect(() => {
    if (selectedId) {
      setSelectedRowModel([selectedId]);
    }
  }, [selectedId]);

  return (
    <DataGrid
      sx={styles.tableContainer}
      columns={getMappedColumns()}
      rows={data}
      rowCount={totalItems ?? data.length}
      editMode="row"
      getRowId={(row) => {
        return idGetter ? idGetter(row) : row.id;
      }}
      initialState={{ pagination: { paginationModel: { pageSize: pageSize ?? 25 } } }}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      rowSelectionModel={rowSelectionModel}
      onRowSelectionModelChange={handleRowSelectionModelChange}
      apiRef={apiRef}
      paginationMode={useClientPagination ? "client" : "server"}
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModalChange}
      pageSizeOptions={pageSizeOptions ?? [25, 50, 100]}
      loading={isLoading}
      getRowClassName={getRowClassName}
      localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
      onCellDoubleClick={handleDoubleCellClick}
    />
  );
};

const styles = {
  tableContainer: {
    flex: "1 0 650px",
    minHeight: "200px",
    border: "none",
    "& .MuiDataGrid-row--editing": {
      "& .MuiDataGrid-cell:not(:last-child)": {
        borderRight: "1px solid rgba(224, 224, 224, 1)",
      },
      "& .MuiDataGrid-editInputCell": {
        height: "100%",
      },
    },
  },
  tableBody: {
    overflowY: "auto",
  },
};
