export interface PaginationSettings {
  currentPage: number;
  pageSize: number;
}

export const DefaultPaginationSettings: PaginationSettings = {
  currentPage: 1,
  pageSize: 10,
};

export const DefaultPageSizeOptions = [10, 25, 50, 100];
