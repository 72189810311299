import { Box, Button } from "@mui/material";
import { Form, Formik } from "formik";
import { TextInput } from "../../../../components/formFields/formikFields/textInput/textInput";
import { sendEmailWithcredentials, setCurrentDisplayedForm } from "../../../../store/slices/login/loginSlice";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { ObjectSchema, object } from "yup";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { LoadingState } from "../../../../types/loadingState";
import { useSnackbar } from "notistack";
import { ForgotPasswordDataDto } from "../../../../models/account/forgotPasswordDataDto";
import { FormType } from "../../../../models/account/formType";
import { HeaderText } from "../../../../components/headerText/headerText";
import { TextMessage } from "../../../../components/textMessage/textMessage";

const validationSchema: ObjectSchema<ForgotPasswordDataDto> = object({
  email: yup.string().email("введите валидный email").required(),
});

export const ForgotPasswordForm = () => {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();

  const isLoading = useTypedSelector((state) => state.login.isSendEmailWithCredentialsLoading);

  const handleSubmit = async (values: ForgotPasswordDataDto) => {
    const result = await dispatch(sendEmailWithcredentials(values));
    if (result.error) {
      enqueueSnackbar(`Произошла ошибка: ${result.payload}`, { variant: "error" });
    } else {
      dispatch(setCurrentDisplayedForm(FormType.EmailSent));
    }
  };

  const handleBack = () => {
    dispatch(setCurrentDisplayedForm(FormType.Login));
  };

  return (
    <Formik initialValues={{ email: "" }} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {(formik) => (
        <>
          <HeaderText>Восстановление доступа</HeaderText>
          <TextMessage>Укажите адрес электронной почты, который вы используете для входа в приложение</TextMessage>
          <Form>
            <Box sx={styles.fieldsContainer}>
              <TextInput size="medium" label="Email" name="email" sx={styles.emailInput} />
              <Box sx={styles.buttonContainer}>
                <Button
                  className="primary"
                  type="submit"
                  disabled={isLoading === LoadingState.PENDING}
                  sx={styles.button}
                >
                  Далее
                </Button>
                <Button
                  variant="text"
                  disabled={isLoading === LoadingState.PENDING}
                  sx={styles.button}
                  onClick={handleBack}
                >
                  Назад
                </Button>
              </Box>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

const styles = {
  fieldsContainer: {
    backgroundColor: "white",
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  emailInput: {
    marginBottom: "40px",
  },
  buttonContainer: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    width: "180px",
  },
};
