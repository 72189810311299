import { Button } from '@mui/material';

interface Props {
  options: { id: string | number; name: string }[];
  value: string | number | null;
  onChange: (id: string | number) => void;
}

export const ButtonGroup = (props: Props) => {
  const handleClick = (id: string | number) => {
    props.onChange && props.onChange(id);
  };
  return (
    <>
      {props.options.map((x) => (
        <Button className={`button timePeriodButton ${props.value === x.id ? 'timePeriodButtonActive' : ''}`} key={x.id} onClick={(e) => handleClick(x.id)}>
          {x.name}
        </Button>
      ))}
    </>
  );
};
