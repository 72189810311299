import { Box } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BaseTable } from "../../../../components/baseTable/BaseTable";
import Loader from "../../../../components/uiComponents/Animation/Animation";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { getRoles, setInitialState } from "../../../../store/slices/userManagement/rolesSlice";
import { LoadingState } from "../../../../types/loadingState";
import { DefaultPageSizeOptions, DefaultPaginationSettings } from "../../../../models/common/paginationSettings";
import { AccessListItem, Role } from "../../types/usersTypes";

interface RulesDataGridItem {
  name: string;
  id: string;
  [key: string]: boolean | string | number;
}

export const Roles = () => {
  const dispatch = useDispatch<any>();

  const rolesData = useTypedSelector((state) => state.roles.rolesData);
  const isLoading = useTypedSelector((state) => state.roles.isLoading);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Доступ",
      minWidth: 400,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => <Box sx={{ paddingLeft: `${params.row.paddingValue * 30}px` }}>{params.value}</Box>,
    },
  ];

  const getFormattedRolesData = () => {
    const res: RulesDataGridItem[] = [];
    const addOrUpdateRow = (role: Role, access: AccessListItem, paddingValue = 0) => {
      const currentTableRow = res.find((item) => item.id === access.id);
      if (currentTableRow) {
        currentTableRow[role.id] = access.accessible;
      } else {
        res.push({
          id: access.id,
          name: access.name,
          paddingValue: paddingValue,
          [role.id]: access.accessible,
        });
      }
      if (access.subpages) {
        access.subpages.forEach((subPagesAccess) => {
          addOrUpdateRow(role, subPagesAccess, paddingValue + 1);
        });
      }
    };

    rolesData.forEach((role) => {
      role.accessList.forEach((access) => {
        addOrUpdateRow(role, access);
      });
    });

    return res;
  };

  const getFormattedColumns = () => {
    const res: GridColDef[] = [];

    rolesData.forEach((role) => {
      res.push({
        field: role.id,
        headerName: role.name,
        flex: 1,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        sortable: false,
        renderCell: (params) => {
          return params.value ? "+" : "";
        },
      });
    });

    return [...columns, ...res];
  };

  useEffect(() => {
    dispatch(getRoles());
    return () => {
      return dispatch(setInitialState());
    };
  }, []);

  if (isLoading === LoadingState.PENDING) {
    return (
      <Box sx={{ height: "100%" }}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: "1 1 100%" }}>
      <BaseTable
        data={getFormattedRolesData()}
        columns={getFormattedColumns()}
        useClientPagination={true}
        pageSize={DefaultPaginationSettings.pageSize}
        pageSizeOptions={DefaultPageSizeOptions}
      />
    </Box>
  );
};
