import { UpdateNormItemDto } from "./../../../models/objects/norms/updateNormItemDto";
import { AxiosResponse } from "axios";
import axios, { backURLs } from "../../../core/APIs";
import { DeviceEventDTO } from "../../../models/deviceEvents/deviceEventDTO";
import { DeviceEventDetailDTO } from "../../../models/deviceEventsDetail/deviceEventDetailDTO";
import { NormTableItemDto } from "../../../models/objects/normTableItemDto";
import { GetDeviceIndicationsDto } from "../../../models/devices/getDeviceIndicationsDto";
import moment from "moment";
import { ObjectDto } from "../../../models/objects/objectListItemDto";

export const objectInfoApi = {
  getObjects(): Promise<AxiosResponse<ObjectDto[]>> {
    return axios.get(`${backURLs.objects}`);
  },

  getDeviceEvents(guid: string): Promise<AxiosResponse<DeviceEventDTO[]>> {
    return axios.get(`${backURLs.deviceEvents}/${guid}`);
  },

  getObjectEvents(guid: string): Promise<AxiosResponse<DeviceEventDetailDTO[]>> {
    return axios.get(`${backURLs.objectEvents}/${guid}`);
  },

  getObjectImage(guid: string): Promise<AxiosResponse<Blob>> {
    return axios.get(`${backURLs.objectEventsInfo}/image/${guid}`, { responseType: "blob" });
  },

  getDeviceImage(guid: string): Promise<AxiosResponse<Blob>> {
    return axios.get(`${backURLs.devices}/image/${guid}`, { responseType: "blob" });
  },

  getNorms(
    guid: string,
    from: number,
    size: number
  ): Promise<AxiosResponse<{ totalItems: number; norms: NormTableItemDto[] }>> {
    return axios.get(`${backURLs.objectInfo}/norms`, { params: { eventObjectGuid: guid, from, size } });
  },

  updateNormItem(guid: string, params: UpdateNormItemDto): Promise<AxiosResponse> {
    return axios.put(`${backURLs.objectInfo}/norms/${guid}`, null, { params });
  },

  deleteNormItem(guid: string): Promise<AxiosResponse> {
    return axios.delete(`${backURLs.objectInfo}/norms/${guid}`);
  },

  getDevices(objectGuid: string): Promise<AxiosResponse> {
    return axios.get(`${backURLs.devices}/list/${objectGuid}`);
  },

  getDeviceSignals(objectGuid: string, deviceGuid: string): Promise<AxiosResponse> {
    return axios.get(`${backURLs.devices}/indicators/${objectGuid}/${deviceGuid}`);
  },

  getSignalValues(params: GetDeviceIndicationsDto): Promise<AxiosResponse> {
    return axios.get(
      `${backURLs.devices}/indications?indicationId=${params.indicationId}&datePeriod.start=${moment(
        params.datePeriod.start
      ).format("YYYY-MM-DD")}&datePeriod.end=${moment(params.datePeriod.end).format("YYYY-MM-DD")}`
    );
  },
};
