import React, { useEffect } from "react";
import { useState } from "react";
import "./ModalWindowReport.scss";
import "../../../uiComponents/Button/CustomButton";
import CustomButton from "../../../uiComponents/Button/CustomButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DownloadFile, GetTypeReports, typeReports } from "../../../../pages/balance/BalansApis";
import Animation from "../../../uiComponents/Animation/Animation";
function ModalWindowReport() {
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [load, setLoad] = useState(false)
  const [type, setType] = useState("Analytics1")
  const [reports, setReports] = useState<typeReports[]>([])
  async function getTypeReports() {
    try {
      const response = await GetTypeReports()
      setReports(response)
    }
    catch (error) {
      console.log(error);
    }


  }
  async function downloadFile() {
    try {
      setLoad(true)
      const response = await DownloadFile(type);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "report.xlsx");
      document.body.appendChild(link);
      link.click();
      setLoad(false)
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getTypeReports()
  }, [])
  return (
    <div className="conteiner">
      <div className="titleReport">
        <span className="headerH1">Фильтр отчета</span>
      </div>
      <div className="bodyReport">
        {/* <div className="dateReport">
          <span className="headerH6">С</span>
          <TextField
            id="date1"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            sx={{ width: 272 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="dateReport">
          <span className="headerH6">По</span>
          <TextField
            id="date1"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            sx={{ width: 272 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div> */}

        <FormControl fullWidth size="small">
          {
            load ? <Animation></Animation> :
              <>
                <InputLabel id="demo-simple-select-label">Тип отчета</InputLabel>
                <Select
                  // sx={{ height: "40px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Тип отчета"
                  onChange={(e) => setType(e.target.value)}
                >
                  {reports.map((item) => {
                    return (
                      <MenuItem value={item.value}>{item.displayName}</MenuItem>
                    )
                  })}

                </Select>
              </>
          }

        </FormControl>

        {load ? <div style={{ width: "100%" }}>
          <div className="chartScales" style={{ color: "red", width: "100%", textAlign: "center" }}>Происходит скачивание файла</div>
          <CustomButton style={{ width: "100%", height: "40px" }} disabled={true} onClick={() => {
            downloadFile();
          }}>СКАЧАТЬ ДОКУМЕНТ</CustomButton>
        </div> : <CustomButton style={{ width: "100%", height: "40px" }} onClick={() => {
          downloadFile();
        }}>СКАЧАТЬ ДОКУМЕНТ</CustomButton>}


        <CustomButton style={{ width: "100%", height: "40px" }}>ОТМЕНА</CustomButton>
      </div>
    </div>
  );
}

export default ModalWindowReport;
