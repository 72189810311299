import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { RouterProvider } from "react-router-dom";
import "./assets/fonts/textStyles.css";
import theme from "./assets/themes/theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/ru";
import { router } from "./routes/router";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
        <SnackbarProvider maxSnack={3}>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
