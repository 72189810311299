import AuthAxios, { backURLs } from "../../../../core/APIs";

export const zoneDistributionApi = {
  getConsumptionVolumeValues: (statisticsSubject: string, period: string, zone: string, isRubles: string) =>
    AuthAxios.get(`${backURLs.consumptionZone}/get-consumption-statistics`, {
      params: {
        statisticsSubject: statisticsSubject,
        Period: period,
        ZoneNumber: zone,
        isRubles: isRubles,
      },
    }),
};
